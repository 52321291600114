import React, { useContext, useRef, useState } from 'react'
import { Layout } from 'antd';
import {
    CoverAppDev, Family, Quote, Itinerary, DressCode, TableGifts,
    Notices, Gallery, GreetingDev, FamilyDev, Greeting, CoverApp
} from '../modules/invitation';

import { darker, lighter, load } from '../helpers';
import { appContext } from '../context';




export const InvitationTest = ({ invitation, size }) => {

    const { MainColor, theme, colorPalette } = useContext(appContext)
    const [loader, setLoader] = useState(false)
    const containerRef = useRef(null);

    const coverRef = useRef(null)
    const greetingRef = useRef(null)
    const familyRef = useRef(null)
    const quoteRef = useRef(null)
    const itineraryRef = useRef(null)
    const dresscodeRef = useRef(null)
    const giftsRef = useRef(null)
    const noticesRef = useRef(null)
    const galleryRef = useRef(null)

    const handlePosition = (id) => {
        switch (id) {
            case 1: return <Greeting id="greeting" greetingRef={greetingRef} content={invitation.greeting} dev={true} />
            case 2: return <Family id="family" familyRef={familyRef} content={invitation.family} dev={true} />
            case 3: return <Quote id="quote" quoteRef={quoteRef} content={invitation.quote} dev={true} />
            case 4: return <Itinerary id="itinerary" itineraryRef={itineraryRef} content={invitation.itinerary} dev={true} />
            case 5: return <DressCode id="dresscode" dresscodeRef={dresscodeRef} content={invitation.dresscode} dev={true} />
            case 6: return <TableGifts id="gifts" giftsRef={giftsRef} content={invitation.gifts} dev={true} />
            case 7: return <Notices id="notices" noticesRef={noticesRef} content={invitation.notices} dev={true} />
            case 8: return <Gallery id="gallery" galleryRef={galleryRef} content={invitation.gallery} dev={true} />

            default:
                break;
        }
    }

    return (

        <>
            {
                !loader && invitation ?
                    <Layout style={{
                        display: 'flex', transition: 'all 0.3s ease',
                        // marginTop: '-30px'
                    }}>

                        <div
                            ref={containerRef}
                            id='draft-content'
                            style={{
                                marginTop: '0vh',
                                height: 'auto',
                                alignItems: 'center', justifyContent: 'flex-start',
                                flexDirection: 'column',
                                backgroundColor: colorPalette ? colorPalette.primary : '#F5F5F7',
                                position: 'relative', transition: 'all 0.3s ease'

                            }}
                        >


                            <CoverApp coverRef={coverRef} content={invitation.cover} inv={invitation} dev={true} height={size.height} />

                            {
                                invitation.generals.positions.map((position) => (
                                    handlePosition(position)
                                ))
                            }

                        </div>
                    </Layout>
                    : <div style={{
                        height: '100vh', display: 'flex', alignItems: 'center',
                        justifyContent: 'center',
                        // backgroundColor: lighter(MainColor, 0.9)
                    }}>
                        <img src={load} style={{
                            width: '10%'
                        }} />
                    </div>


            }
        </>

    )
}
