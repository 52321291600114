import { Layout, Modal } from 'antd'
import React, { useState } from 'react'
import { HeaderBuild } from '../layouts/Build'
import { Login } from '../components/land-page/Login'
import justWeb from '../assets/just_web.svg'
import { PricingContent } from '../components/pricing/PricingContent'

export const HelpPage = () => {

    const [openLogin, setOpenLogin] = useState(false)

    return (
        <>

            <Layout
                className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center'
                }}>
                <HeaderBuild position={'help'} setOpenLogin={setOpenLogin} />
                {/* <PricingContent /> */}

                <Modal
                    open={openLogin}
                    onClose={() => setOpenLogin(false)}
                    onCancel={() => setOpenLogin(false)}
                    footer={null} // Opcional: Elimina el footer del modal si no es necesario
                    style={{
                        top: 40,
                        display: 'flex',
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <Login position={'land-page'} setOpenLogin={setOpenLogin} />

                </Modal>

            </Layout >


            <Layout
                className='responsive-mobile'
            >

                <img src={justWeb} style={{
                    width: '100%', objectFit: 'cover', height: '100%'
                }} />

            </Layout >
        </>
    )
}
