

import loader from '../assets/loader.gif'
import howToSpotify from '../assets/gifs/spotify-howto.gif'
import howToMaps from '../assets/gifs/maps-howto.gif'
import looking from '../assets/watching-img.svg'
import preview from '../assets/preview-design.svg'
import different from '../assets/different-devices.svg'
import logo from '../assets/logo.svg'
import logo2 from '../assets/logo2.svg'
import uno from '../assets/decoration-images/logo2.svg'
import yellow from '../assets/decoration-images/decoration-yellow.svg'
import admin from '../assets/decoration-images/decoration-admin.svg'
import create from '../assets/decoration-images/decoration-create.svg'
import start from '../assets/decoration-images/decoration-start.svg'
import route from '../assets/ROUTE.svg'
import eyes from '../assets/EYES.svg'
import Nu from '../assets/nu.png'

import module1 from '../assets/example-images/example-1.svg'
import module2 from '../assets/example-images/example-2.svg'
import module3 from '../assets/example-images/example-3.svg'
import module4 from '../assets/example-images/example-4.svg'
import gallery from '../assets/example-images/gallery-example.png'
import settings from '../assets/example-images/settings-example.png'
import itinerary from '../assets/example-images/itinerary-example.png'
import admin_guests from '../assets/example-images/admin-guests.png'
import new_guests from '../assets/example-images/new-guests.svg'
import privacy_guests from '../assets/example-images/privacy-guests.svg'
import share_guests from '../assets/example-images/share-guests.svg'
import login from '../assets/login-asset.svg'

export const load = loader


export const MainColor = '#C26462'

export const images = {
    looking,
    preview,
    different,
    logo,
    logo2,
    gallery,
    settings,
    itinerary,
    admin_guests,
    new_guests,
    privacy_guests,
    share_guests,
    login,
    route,
    eyes,
    Nu
}

export const gifs = {
    howToSpotify,
    howToMaps
}

export const decoration = {
    uno,
    yellow,
    admin,
    create,
    start
}

export const images_modules = [

    module4,
    module1,
    module2,
    module3,
]


