import React, { useContext, useEffect, useRef, useState } from 'react'
import { Card } from '../../components/invitation/Card'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos';
import '../../styles/modules/itinerary.css'


export const Itinerary = ({ content, itineraryRef, dev }) => {
    const { MainColor, theme, font, colorPalette } = useContext(appContext);
    // const itineraryRef = useRef(null)


    return (
        <>
            {
                content.active && colorPalette ?
                    <>
                        <div className='module-main-container'

                            style={{
                                backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                            }}
                        >
                            <div
                                ref={itineraryRef}
                                id="itinerary"
                                className="itinerary-container"
                            >

                                <h2
                                    className={dev ? "itinerary-title-dev" : "itinerary-title"}
                                    style={{
                                        color: colorPalette.accent,
                                        fontFamily: font,
                                    }}
                                >
                                    ITINERARIO
                                </h2>
                                <div className='itinerary-cards-container'>
                                    <Card background={content.background} dev={dev} steps={content.object} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                                </div>




                            </div>

                        </div>
                        {content.separator ? (
                            <Separador MainColor={colorPalette.accent} theme={theme} />
                        ) : (
                            <div className="itinerary-separator" />
                        )}
                    </>

                    : <></>
            }
        </>
    );
};

