import { BsEnvelopeOpenHeart, GiLargeDress, LuBaby, BsCalendar4Event, BiParty, BiChurch } from '../helpers'

export const invitationsTypes = [
    {
        name: 'Boda',
        type: 'wedding',
        id: 1,
        icon: BsEnvelopeOpenHeart
    },
    {
        name: 'XV años',
        type: 'xv',
        id: 2,
        icon: GiLargeDress
    },
    {
        name: 'Bautizo',
        type: 'bap',
        id: 3,
        icon: BiChurch
    },
    {
        name: 'Infantiles',
        type: 'kids',
        id: 4,
        icon: LuBaby
    },
    {
        name: 'Eventos',
        type: 'event',
        id: 5,
        icon: BsCalendar4Event,
    },
    {
        name: 'Fiestas',
        type: 'party',
        id: 6,
        icon: BiParty
    }
]


export const devModules = [

    {
        name: 'Portada',
        type: 'cover',
        index: 0
    },
    {
        name: 'Itinerario',
        type: 'itinerary',
        index: 1
    },
    {
        name: 'Dress Code',
        type: 'dresscode',
        index: 2
    },
    {
        name: 'Colores',
        type: 'generals',
        index: 3
    },
    // {
    //     name: 'Regalos',
    //     type: 'gifts',
    //     index: 4
    // },

]

