import React, { useContext, useState } from 'react'
import { Footer } from 'antd/es/layout/layout';
import { darker, lighter } from '../helpers/functions';
import { appContext } from '../context/AuthContext';
import { Button } from 'antd';
import { ColorLogo, ManualLogo } from '../components/Logo';
import { Link } from 'react-router-dom';



export const FooterInvitation = ({

}) => {

    const { theme, colorPalette } = useContext(appContext)

    return (
        <Footer style={{
            // position: 'fixed', zIndex: 1, 
            width: '100%', bottom: 0,
            backgroundColor: `${darker(colorPalette.primary, 0.5)}`,

            display: 'flex', alignItems: 'center', justifyContent: 'center',
            flexDirection: 'column',
            paddingTop: '50px'

        }}>

            <h1 className='footer-main-text' style={{
                color: lighter(colorPalette.primary, 0.8)
            }}>¿Te gusta esta invitación? </h1>
            <h2 className='footer-secondary-text' style={{
                color: `${lighter(colorPalette.primary, 0.7)}99`
            }}>Crea la tuya hoy mismo de manera rápida y fácil</h2>
            <Link to="/">
                <Button id="footer-cta-button" style={{
                    color: lighter(colorPalette.primary, 0.8),
                    backgroundColor: `${darker(colorPalette.primary, 0.4)}99`,
                    border: `1px solid ${lighter(colorPalette.primary, 0.8)}`
                }}>¡Empieza a crear!</Button>
            </Link>


            {/* <div style={{
                width: '90%', height: '1px', backgroundColor: `${lighter(colorPalette.primary, 0.8)}80`,
                margin: '50px 0', maxWidth: '1450px'
            }} />

            <span className='i-attend-text-logo' style={{
                color: lighter(colorPalette.primary, 0.8)
            }}>I attend</span> */}

            {/* <ColorLogo date={new Date()} dark={`${darker(colorPalette.primary, 0.3)}99`} light={lighter(colorPalette.primary, 0.8)} /> */}

            <div style={{ marginBottom: '100px' }} />

        </Footer>

    )
}

