import { Button, Col, ColorPicker, Drawer, Empty, Input, Row, Switch, Upload, message } from 'antd'
import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';
import {
    BiSolidColorFill, FaMinus, HiOutlinePlus, IoMdAdd, LuImagePlus, LuSeparatorHorizontal,
    FaLink, IoClose, MdEdit, colorFactoryToHex, LuBadgeHelp, generateImagesName, TbEyeClosed,
    IoDocumentTextOutline, BsChatSquareText, load, dresscodeAI, BsStars
} from '../../helpers';

import { useContext, useEffect, useState } from 'react';
import axios from 'axios';
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { appContext } from '../../context';
import { CustomButton } from '../../components/CustomButton';


export const BuildDressCode = ({ invitation, setInvitation, invitationID, setSaved, onDesign, onTry }) => {

    const [onLinkMode, setOnLinkMode] = useState(false)
    const [onImageMode, setOnImageMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState(null)
    const [location, setLocation] = useState('info')
    // const { onDesign } = useContext(appContext)

    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)

    const handleGenerating = () => {

        let local_description = dresscodeAI[Math.floor(Math.random() * 9)]

        setDescriptionValue('Generando ...');

        setTimeout(() => {
            setDescriptionValue(local_description)
        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                dresscode: {
                    ...prevInvitation.dresscode,
                    description: local_description,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                active: e,
            },
        }));
        setSaved(false)
    }

    const handleOnImages = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                onImages: e,
            },
        }));
        setSaved(false)
    }

    const handleOnLinks = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                onLinks: e,
            },
        }));
        setSaved(false)
    }

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                title: e ? e.target.value : prevInvitation.dresscode.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setDescriptionValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                description: e ? e.target.value : prevInvitation.dresscode.description,
            },
        }));
        setSaved(false)
    }

    const changeNameLinkbyIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            name: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeLinkbyIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            URL: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const deleteLinkByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: prevInvitation.dresscode.links.filter((card, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const addNewLink = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                links: [
                    ...prevInvitation.dresscode.links,
                    {
                        name: null,
                        URL: null,
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                separator: e,
            },
        }));
        setSaved(false)
    }

    const switchSettings = (type, value) => {
        if (type === 'image') {
            if (value) {
                setOnImageMode(true);
                setOnLinkMode(false);
            } else if (!value && !onLinkMode) {
                setOnImageMode(false);
            }
        } else if (type === 'link') {
            if (value) {
                setOnLinkMode(true);
                setOnImageMode(false);
            } else if (!value && !onImageMode) {
                setOnLinkMode(false);
            }
        }
    };


    const handleURL = (url) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,  // Asegúrate de copiar correctamente dresscode
                images_dev: [...prevInvitation.dresscode.images_dev, url],  // Agregar la nueva URL al array de imágenes
            },
        }));
        setSaved(false)
    };

    const handleCustomRequest = ({ file }) => {
        if (!file) return;

        const isLt3M = file.size / 1024 / 1024 < 5;
        if (!isLt3M) {
            message.error('El archivo debe ser menor a 5MB.');
            return; // Salir de la función si el archivo es mayor a 3MB
        }

        if (invitation.dresscode.images_dev.length < 5) {
            const storageRef = ref(storage, `invitations/${invitationID}/dresscode/${generateImagesName()}`);
            const uploadTask = uploadBytesResumable(storageRef, file);

            uploadTask.on(
                'state_changed',
                snapshot => {
                    const progress = Math.round(
                        (snapshot.bytesTransferred / snapshot.totalBytes) * 100
                    );
                    // setProgress(progress);
                },
                error => {
                    console.error('Upload error: ', error);
                },

                () => {
                    getDownloadURL(uploadTask.snapshot.ref).then(downloadURL => {
                        handleURL(downloadURL)
                    });
                }
            );
        } else {
            message.error('No puedes tener más de 5 imágenes')
        }

    };

    const removeImageDB = (name) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                images_dev: prevInvitation.dresscode.images_dev.filter(imageName => imageName !== name),
            },
        }));
        setSaved(false)
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)
        console.log(filePath)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
            removeImageDB(url)
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };


    const addNewColor = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: [
                    ...prevInvitation.dresscode.colors,
                    invitation.generals.palette.secondary
                ]
            }
        }));
        setSaved(false)
    }

    const cancelColors = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: []
            }
        }));
        setSaved(false)
    }

    const onChangeColorByIndex = (e, index) => {
        const color = colorFactoryToHex(e);

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: prevInvitation.dresscode.colors.map((card, i) => {
                    if (i === index) {
                        return color; // Reemplazar el color existente en el índice dado
                    }
                    return card;
                })
            },
        }));
        setSaved(false)
    }

    const onDeleteColorByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            dresscode: {
                ...prevInvitation.dresscode,
                colors: prevInvitation.dresscode.colors.filter((_, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
    }

    const handleTitle = (location) => {
        switch (location) {
            case 'info': return 'Información'
            case 'links': return 'Links'
            case 'images': return 'Imágenes'
            default:
                break;
        }
    }

    useEffect(() => {
        setDescriptionValue(invitation.dresscode.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])



    return (
        <>
            {
                invitation ?


                    <div className='generals-main-container'>

                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Dress code</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Comunica el código de vestimenta de tu evento con claridad. Puedes incluir descripciones, paletas de colores, imágenes de referencia y enlaces a ejemplos de vestimenta adecuada. Este módulo es esencial para asegurar que tus invitados se sientan cómodos y adecuadamente vestidos para la ocasión.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }


                        <Row className='build-componente-action-container--end'>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
                                marginBottom: '20px'
                            }}>


                                {
                                    invitation.dresscode.active && (
                                        <>
                                            <Button
                                                onMouseEnter={() => setOnButton(true)}
                                                onMouseLeave={() => setOnButton(false)}
                                                type='ghost'
                                                onClick={handleGenerating}
                                                id={`gc-cta-magic-btn`}
                                                style={{
                                                    width: onButton ? '110px' : '32px',
                                                    transition: 'all 0.4s ease',
                                                    boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                    borderRadius: '50px',
                                                    height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                    background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                    color: onButton ? '#FFF' : '#000',


                                                }}
                                                icon={<BsStars size={onButton ? 14 : 22} />}>
                                                <span style={{
                                                    opacity: textAppear ? 1 : 0,
                                                    display: onButton ? 'flex' : 'none',
                                                    transition: 'all 0.4s ease',
                                                    color: '#FFF'
                                                }}>Generar</span>
                                            </Button>
                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.dresscode.separator)}
                                                id={`gc-cta-buttons${invitation.dresscode.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.dresscode.background)}
                                                id={`gc-cta-buttons${invitation.dresscode.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />
                                        </>
                                    )
                                }



                                < Switch
                                    value={invitation.dresscode.active}
                                    onChange={handleActive} />
                            </Row>

                        </Row>

                        {
                            invitation.dresscode.active ?
                                <Row className='generals-module-container'>
                                    <div className='generals-card-container'>

                                        <Row className='gc-title-cta-buttons-container'>

                                            <Row style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                            }}>
                                                {
                                                    location === 'links' && (
                                                        <LuBadgeHelp className={`gc--title${invitation.dresscode.onLinks ? '' : '--disabled'} help--icon`}
                                                            onClick={() => handleDrawer('links-dresscode')}
                                                            style={{
                                                                marginRight: '5px'
                                                            }}
                                                        />
                                                    )
                                                }

                                                <p style={{
                                                    marginRight: '5px'
                                                }} className='gc--title'>{handleTitle(location)}</p>

                                                {
                                                    location === 'links' && (
                                                        <Switch
                                                            size='small'
                                                            checked={invitation.dresscode.onLinks}
                                                            onChange={(e) => handleOnLinks(e)}
                                                        />
                                                    )
                                                }

                                                {
                                                    location === 'images' && (
                                                        <Switch
                                                            size='small'
                                                            checked={invitation.dresscode.onImages}
                                                            onChange={(e) => handleOnImages(e)}
                                                        />
                                                    )
                                                }
                                            </Row>


                                            <Row className='gc-cta-buttons-container'>




                                                <Button
                                                    type='ghost'
                                                    onClick={() => setLocation('info')}
                                                    id={`gc-cta-buttons${location === 'info' ? '--selected' : ''}`}
                                                    style={{
                                                        // marginRight: '5px'
                                                    }}
                                                    icon={<BsChatSquareText size={20} />} />


                                                <Button
                                                    type='ghost'
                                                    onClick={() => setLocation('links')}
                                                    // onClick={() => switchSettings('link', !onLinkMode)}
                                                    id={`gc-cta-buttons${location === 'links' ? '--selected' : ''}`}
                                                    style={{
                                                        margin: '0px 5px'
                                                    }}
                                                    icon={<FaLink size={20} />} />

                                                <Button
                                                    type='ghost'
                                                    onClick={() => setLocation('images')}
                                                    // onClick={() => switchSettings('image', !onImageMode)}
                                                    id={`gc-cta-buttons${location === 'images' ? '--selected' : ''}`}
                                                    icon={<LuImagePlus size={20} />} />

                                            </Row>
                                        </Row>

                                        {
                                            location === 'info' && (
                                                <Col style={{
                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                    flexDirection: 'column',
                                                    width: '100%'
                                                }}>
                                                    <Col style={{
                                                        display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                                        width: '100%'
                                                    }}>

                                                        <p className='gc-content-label'>Título</p>

                                                        <Input
                                                            placeholder='Título'
                                                            className='gc-input-text'
                                                            onChange={onChangeTitle}
                                                            value={invitation.dresscode.title} />

                                                        <p className='gc-content-label'>Descripción</p>

                                                        <Input.TextArea
                                                            placeholder={'Descripción'}
                                                            value={descriptionValue}
                                                            onChange={onChangeDescription}
                                                            autoSize={{ minRows: 5, maxRows: 10 }}
                                                            className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                                            style={{
                                                                borderRadius: '12px'
                                                            }} />

                                                        <Row style={{
                                                            width: '100%', display: 'flex', alignItems: 'center',
                                                            justifyContent: 'space-between', flexDirection: 'row',
                                                            marginTop: '15px',
                                                        }}>
                                                            <p className='gc-content-label'>Paleta de colores</p>


                                                            <Row style={{
                                                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                            }}>

                                                                <Switch size='small'
                                                                    checked={invitation.dresscode.colors.length == 0 ? false : true}
                                                                    onChange={cancelColors}
                                                                />
                                                            </Row>

                                                        </Row>

                                                        <Row style={{
                                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                            flexDirection: 'row', marginTop: '5px'
                                                        }}>

                                                            {
                                                                invitation.dresscode.colors ?
                                                                    invitation.dresscode.colors.map((item, index) => (


                                                                        <div style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                                            flexDirection: 'column', marginRight: index === 5 ? '' : '5px',
                                                                            position: 'relative'
                                                                        }}>
                                                                            <ColorPicker
                                                                                key={index}
                                                                                disabledAlpha={false}
                                                                                value={item}
                                                                                onChange={(e) => onChangeColorByIndex(e, index)}
                                                                                style={{
                                                                                    height: '50px', aspectRatio: '1', borderRadius: '8px 8px 0 0',
                                                                                    border: '1px solid #d9d9d9',
                                                                                    display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                    backgroundColor: item
                                                                                }} >

                                                                            </ColorPicker>

                                                                            <p
                                                                                onClick={() => onDeleteColorByIndex(index)}
                                                                                style={{
                                                                                    width: '100%', height: '15px', backgroundColor: '#F5F5F7',
                                                                                    textAlign: 'center', fontSize: '0.6em', fontFamily: 'Roboto',
                                                                                    fontWeight: 400, cursor: 'pointer',
                                                                                    // position: 'absolute', bottom: -10, 
                                                                                    borderRadius: '0 0 8px 8px'
                                                                                }}>Borrar</p>


                                                                        </div>

                                                                    ))
                                                                    : <></>

                                                            }

                                                            {
                                                                invitation.dresscode.colors.length >= 5 ?
                                                                    <></>
                                                                    :
                                                                    <Button
                                                                        id='gc-button-add-color'
                                                                        onClick={addNewColor}
                                                                        icon={<IoMdAdd size={20} />} />
                                                            }


                                                        </Row>
                                                    </Col>



                                                </Col>
                                            )
                                        }


                                        {
                                            location === 'links' && (
                                                <Col
                                                    className='scroll-invitation'
                                                    style={{
                                                        display: invitation.dresscode.onLinks ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                        flexDirection: 'column', overflowY: 'scroll', height: '300px',
                                                        width: '95%', marginTop: '10px',
                                                        // marginBottom: '10px'
                                                    }}>

                                                    <div style={{
                                                        height: '400px', display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                                        width: '100%', overflow: 'auto'
                                                    }}>
                                                        {
                                                            // invitation.dresscode.links == [] ?
                                                            invitation.dresscode.links.map((item, index) => (
                                                                <div className='dresscode-link-card'>


                                                                    <Button
                                                                        type='ghost'
                                                                        id="gc-cta-buttons"
                                                                        onClick={() => deleteLinkByIndex(index)}
                                                                        icon={<IoClose size={20} />}
                                                                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                                    />


                                                                    <p className='gc-content-label'>Nombre</p>
                                                                    <Input
                                                                        placeholder='Título'
                                                                        className='gc-input-text'
                                                                        onChange={(e) => changeNameLinkbyIndex(index, e.target.value)}
                                                                        value={item.name} />
                                                                    <p className='gc-content-label'>Link</p>
                                                                    <Input
                                                                        placeholder='Link'
                                                                        className='gc-input-text'
                                                                        onChange={(e) => changeLinkbyIndex(index, e.target.value)}
                                                                        value={item.URL} />

                                                                </div>
                                                            ))
                                                            // : <Empty description={false} style={{ marginTop: '30px' }} />
                                                        }

                                                    </div>


                                                    <div className='general-cards-cta-section'>

                                                        <CustomButton
                                                            onClick={addNewLink}
                                                            icon={<IoMdAdd />} text={'Nuevo Link'} styleText={{
                                                                fontSize: '18px', fontWeight: 600
                                                            }} />

                                                    </div>

                                                </Col>
                                            )
                                        }


                                        {
                                            location === 'images' && (
                                                <Col
                                                    className='scroll-invitation'
                                                    style={{
                                                        display: invitation.dresscode.onImages ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                        flexDirection: 'column', height: invitation.dresscode.images_dev.length > 0 ? '400px' : '200px', overflow: 'auto',
                                                        width: '100%', marginTop: '10px', transition: 'all 0.3s ease'
                                                        // marginBottom: '10px'
                                                    }}>

                                                    {
                                                        invitation.dresscode.images_dev.length > 0 ?
                                                            invitation.dresscode.images_dev.map((item, index) => (
                                                                <div style={{
                                                                    width: '100%', borderRadius: '8px', border: '1px solid #D9D9D9',
                                                                    backgroundColor: '#F5F5F7', display: 'flex', alignItems: 'flex-start',
                                                                    justifyContent: 'flex-start', flexDirection: 'column',
                                                                    position: 'relative', margin: '10px 0 5px 0',
                                                                    height: '100%', overflow: 'hidden', transition: 'all 0.3s ease'
                                                                }}>


                                                                    <img src={item} style={{
                                                                        width: '100%', height: '100%', objectFit: 'cover'
                                                                    }} />

                                                                    {
                                                                        onTry ?
                                                                            <Button
                                                                                type='ghost'
                                                                                id="gc-cta-buttons-static"
                                                                                onClick={() => message.warning('No puedes eliminar imágenes en simulador')}
                                                                                icon={<IoClose size={20} />}
                                                                                style={{ position: 'absolute', top: '5px', right: '5px', }}
                                                                            />
                                                                            : <Button
                                                                                type='ghost'
                                                                                id="gc-cta-buttons-static"
                                                                                onClick={() => deleteImageFB(item)}
                                                                                icon={<IoClose size={20} />}
                                                                                style={{ position: 'absolute', top: '5px', right: '5px', }}
                                                                            />
                                                                    }



                                                                </div>
                                                            ))
                                                            : <Empty description={false} style={{
                                                                marginTop: '30px'
                                                            }} />

                                                    }

                                                    <div className='general-cards-cta-section'>

                                                        {
                                                            onTry ?
                                                                <CustomButton
                                                                    onClick={() => message.warning('No puedes subir imágenes en simulador')}
                                                                    icon={<IoMdAdd />} text={'Nueva Imagen'} styleText={{
                                                                        fontSize: '18px', fontWeight: 600
                                                                    }} />

                                                                :
                                                                <Upload
                                                                    style={{
                                                                        width: '100%',
                                                                        border: '1px solid red'
                                                                        // marginTop: '10px'
                                                                    }}

                                                                    onChange={handleCustomRequest}
                                                                    showUploadList={false} // Oculta la lista de archivos subidos
                                                                    beforeUpload={() => false} // Evita la carga automática de archivos
                                                                >

                                                                    <CustomButton
                                                                        // onClick={() => addNewSubitem(item)}
                                                                        icon={<IoMdAdd />} text={'Nueva Imagen'} styleText={{
                                                                            fontSize: '18px', fontWeight: 600
                                                                        }} />


                                                                </Upload>

                                                        }

                                                    </div>

                                                </Col>
                                            )
                                        }




                                    </div>

                                    {
                                        onLinkMode ?
                                            <div

                                                className={`generals-card-container${invitation.dresscode.onLinks ? '' : '--disabled'}`}
                                            >

                                                <Row className='gc-title-cta-buttons-container'>

                                                    <Row style={{
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        flexDirection: 'row'
                                                    }}>

                                                        <p
                                                            className={`gc--title${invitation.dresscode.onLinks ? '' : '--disabled'}`}
                                                        >Links</p>
                                                        <LuBadgeHelp className={`gc--title${invitation.dresscode.onLinks ? '' : '--disabled'} help--icon`}
                                                            onClick={() => handleDrawer('links-dresscode')} />
                                                    </Row>



                                                    <Row className='gc-cta-buttons-container'>

                                                        <Row style={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                        }}>

                                                            <Button
                                                                type='ghost'
                                                                onClick={addNewLink}
                                                                id={`gc-cta-buttons`}
                                                                style={{
                                                                    marginRight: '10px'
                                                                }}
                                                                icon={<IoMdAdd size={20} />} />



                                                            <Switch
                                                                size='small'
                                                                checked={invitation.dresscode.onLinks}
                                                                onChange={(e) => handleOnLinks(e)}
                                                            />
                                                        </Row>
                                                    </Row>

                                                </Row>

                                                <Col
                                                    className='scroll-invitation'
                                                    style={{
                                                        display: invitation.dresscode.onLinks ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                        flexDirection: 'column', overflowY: 'scroll', height: '300px',
                                                        width: '95%', marginTop: '10px',
                                                        // marginBottom: '10px'
                                                    }}>

                                                    {
                                                        // invitation.dresscode.links == [] ?
                                                        invitation.dresscode.links.map((item, index) => (
                                                            <div className='dresscode-link-card'>


                                                                <Button
                                                                    type='ghost'
                                                                    id="gc-cta-buttons"
                                                                    onClick={() => deleteLinkByIndex(index)}
                                                                    icon={<IoClose size={20} />}
                                                                    style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                                />


                                                                <p className='gc-content-label'>Nombre</p>
                                                                <Input
                                                                    placeholder='Título'
                                                                    className='gc-input-text'
                                                                    onChange={(e) => changeNameLinkbyIndex(index, e.target.value)}
                                                                    value={item.name} />
                                                                <p className='gc-content-label'>Link</p>
                                                                <Input
                                                                    placeholder='Link'
                                                                    className='gc-input-text'
                                                                    onChange={(e) => changeLinkbyIndex(index, e.target.value)}
                                                                    value={item.URL} />

                                                            </div>
                                                        ))
                                                        // : <Empty description={false} style={{ marginTop: '30px' }} />
                                                    }

                                                </Col>


                                            </div>
                                            : <></>
                                    }

                                    {
                                        onImageMode ?
                                            <div
                                                className={`generals-card-container${invitation.dresscode.onImages ? '' : "--disabled"}`}
                                            >

                                                <Row className='gc-title-cta-buttons-container'>
                                                    <p
                                                        className={`gc--title${invitation.dresscode.onImages ? '' : '--disabled'}`}
                                                    >Imágenes</p>

                                                    <Row className='gc-cta-buttons-container'>

                                                        <Row style={{
                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                        }}>

                                                            <Upload
                                                                style={{
                                                                    width: '100%',
                                                                    border: '1px solid red'
                                                                    // marginTop: '10px'
                                                                }}

                                                                onChange={handleCustomRequest}
                                                                showUploadList={false} // Oculta la lista de archivos subidos
                                                                beforeUpload={() => false} // Evita la carga automática de archivos
                                                            >

                                                                <Button
                                                                    type='ghost'
                                                                    // onClick={() => switchSettings('link', !onLinkMode)}
                                                                    id={`gc-cta-buttons`}
                                                                    style={{
                                                                        marginRight: '10px'
                                                                    }}
                                                                    icon={<IoMdAdd size={20} />} />


                                                            </Upload>

                                                            <Switch
                                                                size='small'
                                                                checked={invitation.dresscode.onImages}
                                                                onChange={(e) => handleOnImages(e)}
                                                            />
                                                        </Row>
                                                    </Row>

                                                </Row>

                                                <Col
                                                    className='scroll-invitation'
                                                    style={{
                                                        display: invitation.dresscode.onImages ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                        flexDirection: 'column', height: invitation.dresscode.images_dev.length > 0 ? '400px' : '200px', overflow: 'auto',
                                                        width: '100%', marginTop: '10px', transition: 'all 0.3s ease'
                                                        // marginBottom: '10px'
                                                    }}>

                                                    {
                                                        invitation.dresscode.images_dev.length > 0 ?
                                                            invitation.dresscode.images_dev.map((item, index) => (
                                                                <div style={{
                                                                    width: '100%', borderRadius: '8px', border: '1px solid #D9D9D9',
                                                                    backgroundColor: '#F5F5F7', display: 'flex', alignItems: 'flex-start',
                                                                    justifyContent: 'flex-start', flexDirection: 'column',
                                                                    position: 'relative', margin: '10px 0 5px 0',
                                                                    height: '100%', overflow: 'hidden', transition: 'all 0.3s ease'
                                                                }}>


                                                                    <img src={item} style={{
                                                                        width: '100%', height: '100%', objectFit: 'cover'
                                                                    }} />

                                                                    <Button
                                                                        type='ghost'
                                                                        id="gc-cta-buttons-static"
                                                                        onClick={() => deleteImageFB(item)}
                                                                        icon={<IoClose size={20} />}
                                                                        style={{ position: 'absolute', top: '5px', right: '5px', }}
                                                                    />

                                                                </div>
                                                            ))
                                                            : <Empty description={false} style={{
                                                                marginTop: '30px'
                                                            }} />

                                                    }

                                                </Col>


                                            </div>
                                            : <></>
                                    }

                                </Row>
                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }







                    </div >

                    : <></>
            }

            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />
        </>
    )
}


{/* <Row style={{
                            width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row'
                        }}>

                            <Col style={{
                                width: '40%', display: 'flex', alignItems: 'flex-start',
                                justifyContent: 'center', flexDirection: 'column',
                                margin: '7px 0'
                            }}>
                                <p
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px'
                                    }}>Descripción</p>
                                <Input.TextArea
                                    autoSize={{ minRows: 5, maxRows: 10 }}
                                    style={{
                                        width: '100%', borderRadius: '15px'
                                    }}
                                    value={invitation.dresscode.description}
                                    onChange={onChangeDescription} />

                                <p
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px', marginTop: '10px'
                                    }}>Username</p>
                                <Input
                                    style={{
                                        width: '100%', borderRadius: '30px'
                                    }}
                                    onChange={onChnageUsername}
                                    value={invitation.dresscode.username} />

                                <Button
                                    onClick={addItemToBoards}
                                    icon={<IoMdAdd style={{
                                        color: '#FFF'
                                    }} />}
                                    type='primary' style={{
                                        borderRadius: '30px', color: '#FFF',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        marginTop: '20px'
                                    }}>
                                    Nuevo Tablero
                                </Button>

                                {
                                    invitation.dresscode.boards ?
                                        invitation.dresscode.boards.map((board, index) => (
                                            <div
                                                key={index}
                                                style={{
                                                    width: '100%', borderRadius: '30px',
                                                    border: '1px solid #D9D9D9', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                    flexDirection: 'row', position: 'relative',
                                                    marginTop: '15px'
                                                }}>
                                                <Input
                                                    value={board}
                                                    onChange={(e) => editBoardItemByIndex(index, e.target.value)}
                                                    style={{
                                                        width: '90%', borderRadius: '30px',
                                                        border: 'none'
                                                    }} />

                                                <Button
                                                    onClick={() => deleteItemFromBoardsByIndex(index)}
                                                    style={{
                                                        height: '28px', width: '28px', borderRadius: '50%',
                                                        backgroundColor: '#F5F5F7', border: 'none',
                                                        // color: '#878787', 
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                        position: 'absolute', right: '2px'
                                                    }}
                                                    icon={<IoClose size={12} />}
                                                />
                                            </div>
                                        ))
                                        : <></>
                                }

                            </Col>

                            <Col style={{
                                width: '57%', display: 'flex', alignItems: 'flex-start',
                                justifyContent: 'center', flexDirection: 'column',
                                marginTop: '-5px'
                                // margin: '7px 0'
                            }}>
                                <p
                                    className='roboto-regular'
                                    style={{
                                        width: 'auto', fontSize: '1em', fontFamily: '',
                                        marginLeft: '5px', marginTop: '10px'
                                    }}>¿Cómo funcionan los tableros de pinterest?</p>

                                <div style={{
                                    width: '100%', border: '1px solid #e3e3e3',
                                    height: '380px', borderRadius: '15px'
                                }}>

                                </div>

                            </Col>



                        </Row> */}