import React, { useContext, useEffect, useRef } from 'react'
import { GiftCards } from '../../components/invitation/GiftCards'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/gifts.css'



export const TableGifts = ({ content, giftsRef, dev }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const giftsRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='module-main-container'
                        style={{
                            backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                        }}
                    >
                        <div ref={giftsRef} id='gifts' className="gifts-container">

                            <h2 className={dev ? "gift-title-dev" : "gifts-title"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.title}
                            </h2>

                            <p className={dev ? "gifts-description-dev" : "gifts-description"} style={{ color: colorPalette.accent, fontFamily: font }}>
                                {content.description}
                            </p>

                            <div className='gifts-scroll-invitation'>
                                <GiftCards dev={dev} cards={content.cards} MainColor={MainColor} theme={theme} font={font} colorPalette={colorPalette} />
                            </div>
                        </div>



                    </div>
                    {content.separator ? (
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    ) : (
                        <div className="gifts-separator" />
                    )}
                </>

            ) : null}
        </>
    )
}
