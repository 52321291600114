import { message } from "antd";

export const renew_jwt = async (logout, operation) => {

    const expired = (localStorage.getItem('token-expires'))
    const token = localStorage.getItem("token");

    const now = new Date();
    const expiredDate = new Date(expired);
    const finalDate = new Date(expired)

    expiredDate.setMinutes(expiredDate.getMinutes() - 30);


    if (now > finalDate) {
        logout()
        message.info('Tu sesión ha expirado')
    }

    else if (now > expiredDate && now < finalDate) {
        try {
            await operation({
                method: "GET",
                url: "/auth/renew",
                headers: { token: `${token}` }
            })
        } catch (error) {
            console.log(error)
        }
    }

    else if (now < expiredDate) {
        console.log('nothing')
    }

}


export async function onLogin(operation, Email, Password) {
    // const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: "/auth/login",
            headers: { accept: "*/*" },
            data: {
                Email: Email,
                Password: Password,
            },
        })

    } catch (error) {
        console.error(error)
    }
}


export async function onNewUser(operation, Name, Email, Password) {

    const newUser = {
        Name: Name,
        Email: Email,
        Password: Password,
        Invitations: [],
        Role: 'Owner'
    };

    try {
        await operation({
            method: "POST",
            url: "/auth/new-user",
            headers: {
                "Content-Type": "application/json"
            },
            data: newUser // Aquí usamos newUser dinámicamente
        });

    } catch (error) {
        message.error('Es probable que este usuario ya exista')
    }
}

export async function getUSers(operation) {


    try {
        await operation({
            method: "GET",
            url: "/auth/",
        });

    } catch (error) {
        message.error('Es probable que este usuario ya exista')
    }
}

export async function editUser(operation, uid, name_enterprise, logo_enterpise) {

    const token = localStorage.getItem("token");
    const data = {
        Enterprise: {
            name: name_enterprise,
            logo: logo_enterpise,
        }
    }

    try {
        await operation({
            method: "PUT",
            url: `/auth/edit/${uid}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: data
        });

    } catch (error) {
        message.error('Es probable que este usuario ya exista')
    }
}




