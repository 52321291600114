import React, { useContext, useEffect, useRef } from 'react';
import { darker, lighter } from '../../helpers/functions';
import { appContext } from '../../context/AuthContext';
import { Separador } from '../../components/invitation/Logos';
import '../../styles/modules/greeting.css'

export const Greeting = ({ content, greetingRef, dev }) => {
    const { MainColor, theme, font, colorPalette } = useContext(appContext);

    // const greetingRef = useRef(null)

    useEffect(() => {
        if (colorPalette) {
            console.log('colorPalette', colorPalette)
        }
    }, [])



    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='module-main-container'>
                        <div
                            ref={greetingRef}
                            id="greeting"
                            className="greeting-container"
                        >
                            <div
                                className="greeting-inner-container"
                                style={{
                                    backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                                }}
                            >
                                <h2
                                    className={dev ? "greeting-title-dev" : "greeting-title"}
                                    style={{ color: colorPalette.accent, fontFamily: font }}
                                >
                                    {content.title}
                                </h2>
                                <p
                                    className={dev ? "greeting-description-dev" : "greeting-description"}
                                    style={{ color: colorPalette.accent, fontFamily: font }}
                                >
                                    {content.description}
                                </p>
                            </div>

                        </div>
                    </div>

                    {
                        content.separator ? (
                            <Separador MainColor={colorPalette.accent} theme={theme} />
                        ) : (
                            <div className="greeting-separator" />
                        )}
                </>
            ) : null}
        </>
    );
};