import React, { useEffect, useState } from 'react';
import { AmazonLogo, SearsLogo } from './Logos';
import { darker, lighter } from '../../helpers/functions';
import { Link } from 'react-router-dom';


export const GiftCards = ({ dev, cards, MainColor, theme, font, colorPalette }) => {

    const [card, setCard] = useState(cards)

    const handleLogos = (type) => {
        switch (type.toUpperCase()) {
            case 'AMAZON':
                return <AmazonLogo width={'50%'} height={'50%'} MainColor={colorPalette.accent} theme={theme} />
            case 'SEARS':
                return <SearsLogo width={'50%'} height={'50%'} MainColor={colorPalette.accent} theme={theme} />

            default: return <h1 style={{
                width: '90%', fontSize: '3em', color: colorPalette.accent,
                fontFamily: font
            }}>{type}</h1>;

        }
    }

    useEffect(() => {
        setCard(cards)
    }, [cards])


    return (

        <>
            {card ?
                card.map((item, index) => (
                    <div
                        key={index}
                        className="gift-card-container"
                        style={{
                            background: `${colorPalette.secondary}`
                        }}
                    >
                        <a
                            href={item.url}
                            target='_blank'
                            rel='noopener noreferrer'
                            className={dev ? "gift-card-link-dev" : "gift-card-link"}
                            style={{
                                color: colorPalette.accent,
                                cursor: item.link ? 'pointer' : 'default',
                            }}
                        >
                            {item.link ? (
                                handleLogos(item.type)
                            ) : (
                                <>
                                    <h1
                                        className={dev ? "gift-card-bank-dev" : "gift-card-bank"}
                                        style={{
                                            fontFamily: font,
                                            color: colorPalette.accent,
                                        }}
                                    >
                                        {item.bank}
                                    </h1>
                                    <p
                                        className={dev ? 'gift-card-name-dev' : "gift-card-name"}
                                        style={{
                                            fontFamily: font,
                                            color: colorPalette.accent,
                                        }}
                                    >
                                        {item.name}
                                    </p>
                                    <p
                                        className={dev ? "gift-card-number-dev" : "gift-card-number"}
                                        style={{
                                            fontFamily: font,
                                            color: colorPalette.accent,
                                        }}
                                    >
                                        {item.number}
                                    </p>
                                </>
                            )}
                        </a>
                    </div>
                ))
                : <></>
            }
        </>

    );
};
