import { Button, Col, ColorPicker, Drawer, Input, Row, Select, Switch, } from 'antd'
import React, { useContext, useEffect, useState } from 'react'

import { colorFactoryToHex, darker, lighter, moveDown, moveUp, fonts, LuBadgeHelp, FaArrowsAltV, buttonsColorText } from '../../helpers'
import { appContext } from '../../context'

import {
    IoMdMoon, IoMdArrowRoundUp, IoMdArrowRoundDown, VscDebugRestart, IoMdColorFill, PiWall, TbClick,
    FaSun, LuSeparatorHorizontal, CgReorder, TbArrowsDownUp, HiSwitchVertical, PiTextT, PiSelectionBackground,
    IoShapesOutline, IoSettingsOutline, MdVerticalAlignTop, MdVerticalAlignBottom
} from "../../helpers";
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { BiMoon } from 'react-icons/bi';


const { Option } = Select;



export const BuildGenerals = ({ invitation, setInvitation, setSaved, onDesign }) => {

    const { setMainColor, setTheme, setFont, MainColor, theme, setColorPalette, colorPalette, font } = useContext(appContext)
    const [currentPosition, setCurrentPosition] = useState(null)
    const [currentItem, setCurrentItem] = useState(null)
    const [orderMode, setOrderMode] = useState(false)
    const [separatorMode, setSeparatorMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [type, setType] = useState(null)
    const [showInfo, setShowInfo] = useState(false)
    const [currentColor, setCurrentColor] = useState(null)
    const [onPalette, setOnPalette] = useState(false)
    const [location, setLocation] = useState('config')

    const handleClick = (item, index) => {
        setCurrentItem(item)
        setCurrentPosition(index)
    }

    useEffect(() => {
        if (invitation) {
            setMainColor(invitation.generals.color)
            setCurrentColor(invitation.generals.palette.base)
            setColorPalette(invitation.generals.palette)
        }
    }, [invitation])


    const onChangeColor = (e) => {
        console.log(colorFactoryToHex(e))
        setCurrentColor(colorFactoryToHex(e))
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                // color: e ? colorFactoryToHex(e) : prevInvitation.generals.color,
                palette: e ? theme ? {
                    base: colorFactoryToHex(e),
                    primary: lighter(colorFactoryToHex(e), 0.9),
                    secondary: lighter(colorFactoryToHex(e), 0.8),
                    accent: darker(colorFactoryToHex(e), 0.4),
                    buttons: lighter(colorFactoryToHex(e), 0.4)
                } :
                    {
                        base: colorFactoryToHex(e),
                        primary: darker(colorFactoryToHex(e), 0.6),
                        secondary: darker(colorFactoryToHex(e), 0.5),
                        accent: lighter(colorFactoryToHex(e), 0.5),
                        buttons: darker(colorFactoryToHex(e), 0.9)
                    } :
                    prevInvitation.generals.color
            },
        }));
        setSaved(false)
    }

    const onChangeTheme = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                theme: e,
                palette: e ? {
                    base: currentColor,
                    primary: lighter(currentColor, 0.9),
                    secondary: lighter(currentColor, 0.8),
                    accent: darker(currentColor, 0.4),
                    buttons: lighter(currentColor, 0.4)

                } : {
                    base: currentColor,
                    primary: darker(currentColor, 0.6),
                    secondary: darker(currentColor, 0.5),
                    accent: lighter(currentColor, 0.5),
                    buttons: darker(currentColor, 0.9)
                }
            },
        }));
        setSaved(false)
        setTheme(e)
    }

    const onChangePrimary = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    primary: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeSecondary = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    secondary: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeAccent = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    accent: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const onChangeButtons = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                palette: {
                    ...prevInvitation.generals.palette,
                    buttons: colorFactoryToHex(e)
                }
            },
        }));
        setSaved(false);
    };

    const handleFont = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                font: e,
            },
        }));
        setSaved(false)
        setFont(e)
    }


    const handleNamePosition = (position) => {
        switch (position) {
            case 1: return 'Saludo'
            case 2: return 'Personas'
            case 3: return 'Cita'
            case 4: return 'Itinerario'
            case 5: return 'Dresscode'
            case 6: return 'Regalos'
            case 7: return 'Avisos'
            case 8: return 'Galería'

            default:
                break;
        }
    }

    const restartPositions = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: [1, 2, 3, 4, 5, 6, 7, 8]
            }
        }));
        setCurrentItem(null)
        setCurrentPosition(null)
        setSaved(false)

    }

    const moveUpPosition = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: moveUp(prevInvitation.generals.positions, index)
            }
        }));
        // setCurrentItem(null)
        setSaved(false)
        setCurrentPosition(currentPosition - 1)

    }

    const moveDownPosition = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            generals: {
                ...prevInvitation.generals,
                positions: moveDown(prevInvitation.generals.positions, index)
            }
        }));
        // setCurrentItem(null)
        setSaved(false)
        setCurrentPosition(currentPosition + 1)
    }

    const switchSettings = (type, value) => {
        if (type === 'order') {
            if (value) {
                setOrderMode(true);
                setSeparatorMode(false);
            } else if (!value && orderMode) {
                setOrderMode(false);
            }
        } else if (type === 'separator') {
            if (value) {
                setSeparatorMode(true);
                setOrderMode(false);
            } else if (!value && separatorMode) {
                setSeparatorMode(false);
            }
        }
    };

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
    }

    const handleTitle = (location) => {
        switch (location) {
            case 'config': return 'Configuraciones'
            case 'order': return 'Acomodo'

            default:
                break;
        }
    }



    return (
        <>
            {
                invitation ?
                    <div
                        className='scroll-invitation generals-main-container' >
                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Ajustes Generales</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Personaliza los aspectos fundamentales de tu invitación. Aquí puedes seleccionar el tipo de letra, los colores y el orden de los elementos. Personaliza estos detalles para reflejar el estilo y la atmósfera de tu evento.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }


                        <Row className='generals-module-container '>


                            <div className='generals-card-container' >

                                <Row className='gc-title-cta-buttons-container' style={{
                                    margin: 0
                                }}>
                                    <p className='gc--title'>{handleTitle(location)}</p>

                                    <Row className='gc-cta-buttons-container' >


                                        <Button
                                            type='ghost'
                                            onClick={() => setLocation('config')}
                                            // onClick={() => switchSettings('order', !orderMode)}
                                            id={`gc-cta-buttons${location === 'config' ? '--selected' : ''}`}
                                            icon={<IoSettingsOutline size={20} />} />
                                        <Button
                                            type='ghost'
                                            onClick={() => setLocation('order')}
                                            // onClick={() => switchSettings('order', !orderMode)}
                                            id={`gc-cta-buttons${location === 'order' ? '--selected' : ''}`}
                                            icon={<HiSwitchVertical size={20} />} />

                                        {/* <Button
                                            onClick={() => switchSettings('separator', !separatorMode)}
                                            id={`gc-cta-buttons${separatorMode ? '--selected' : ''}`}
                                            style={{ marginLeft: '5px' }}
                                            icon={<LuSeparatorHorizontal size={15} />} /> */}
                                    </Row>

                                </Row>


                                {
                                    location === 'config' && (
                                        <Col className='gc-content-container'>

                                            <p className='gc-content-label'>Tipo de letra</p>

                                            <Select

                                                value={invitation.generals.font}
                                                onChange={(e) => handleFont(e)}
                                                id='gc-content-select'
                                                style={{ width: '100%', fontFamily: invitation.generals.font, }}>
                                                {fonts.map((font, index) => (
                                                    <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                                ))}

                                            </Select>


                                            <Row style={{
                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',
                                                marginTop: '10px',
                                            }}>
                                                <p className='gc-content-label'>Colores</p>

                                                <Row style={{
                                                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                }}>
                                                    <Button onClick={() => setOnPalette(false)} id='palette-colors-button'
                                                        className={!onPalette ? 'palette-color-selected' : 'palette-color-not-selected'}
                                                    >
                                                        Automático
                                                    </Button>

                                                    <Button onClick={() => setOnPalette(true)} id='palette-colors-button'
                                                        className={onPalette ? 'palette-color-selected' : 'palette-color-not-selected'}
                                                    >
                                                        Manual
                                                    </Button>



                                                    {/* {
                                                invitation.generals.theme
                                                    ? <FaSun size={16} style={{ color: '#FCBA00' }} />
                                                    : <IoMdMoon size={16} style={{ color: '#878787' }} />
                                            }
                                            <Switch
                                                checked={invitation.generals.theme ? true : false}
                                                onChange={() => onChangeTheme(!invitation.generals.theme)}
                                                size='small'
                                                style={{ marginLeft: '5px' }}
                                            /> */}
                                                </Row>

                                            </Row>

                                            <div className='select-color-container'
                                                style={{
                                                    backgroundColor: invitation.generals.palette.primary
                                                }}
                                            >
                                                <div className='select-color-forms'
                                                    style={{
                                                        backgroundColor: invitation.generals.palette.secondary,
                                                    }}
                                                >
                                                    <Row style={{
                                                        display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                    }}>


                                                        <>
                                                            <div
                                                                onClick={() => onChangeTheme(!invitation.generals.theme)}
                                                                className='theme-button'
                                                                style={{

                                                                    opacity: !onPalette ? 1 : 0,
                                                                    border: `1px solid ${buttonsColorText(invitation.generals.palette.secondary.toString())}`
                                                                }}>
                                                                {
                                                                    theme ?
                                                                        <>
                                                                            <span style={{
                                                                                transition: 'all 0.3s ease',
                                                                            }}>Claro</span>
                                                                            <FaSun size={16} style={{ transition: 'all 0.3s ease', }} />
                                                                        </>
                                                                        : <>
                                                                            <span style={{
                                                                                transition: 'all 0.3s ease',
                                                                            }}>Oscuro</span>
                                                                            <IoMdMoon size={16} style={{ transition: 'all 0.3s ease', }} />
                                                                        </>
                                                                }

                                                            </div>

                                                            <ColorPicker
                                                                disabledAlpha={false}
                                                                value={invitation.generals.palette.base}
                                                                onChange={!onPalette ? onChangeColor : onChangeSecondary}
                                                                className='gc-color-picker'
                                                                style={{ backgroundColor: invitation.generals.palette.base }}>
                                                                <Button
                                                                    icon={!onPalette ? <IoMdColorFill size={18} /> : <IoShapesOutline size={18} />}
                                                                    style={{ border: `1px solid ${buttonsColorText(invitation.generals.palette.secondary.toString())}` }}
                                                                    id='pick-color-button' />
                                                            </ColorPicker>

                                                        </>






                                                    </Row>



                                                </div>

                                                <Row style={{
                                                    display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row',
                                                    width: '100%', marginTop: '15px', padding: '0px 8px', position: 'relative'
                                                }}>
                                                    <Row style={{
                                                        display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                                        flexDirection: 'row'
                                                    }}>
                                                        <ColorPicker
                                                            disabledAlpha={false}
                                                            value={invitation.generals.palette.base}
                                                            onChange={onChangeAccent}
                                                            className='gc-color-picker'
                                                            style={{ backgroundColor: invitation.generals.palette.base }}>
                                                            <Button
                                                                icon={<PiTextT size={18} />}
                                                                style={{
                                                                    marginRight: '5px',
                                                                    opacity: onPalette ? 1 : 0,
                                                                    border: `1px solid ${buttonsColorText(invitation.generals.palette.primary.toString())}`
                                                                }}
                                                                id='pick-color-button' />
                                                        </ColorPicker>
                                                        <div className='select-color-text-container'>
                                                            <span className='select-color--title' style={{
                                                                fontFamily: font, color: invitation.generals.palette.accent
                                                            }}>Título</span>
                                                            <span className='select-color--description' style={{
                                                                fontFamily: font, color: invitation.generals.palette.accent
                                                            }}>Esta es una descripción de muesrra</span>
                                                        </div>
                                                    </Row>


                                                    <ColorPicker
                                                        disabledAlpha={false}
                                                        value={invitation.generals.palette.base}
                                                        onChange={onChangePrimary}
                                                        className='gc-color-picker'
                                                        style={{ backgroundColor: invitation.generals.palette.base }}>
                                                        <Button
                                                            icon={<PiSelectionBackground size={18} />}
                                                            style={{
                                                                position: 'absolute', right: '8px',
                                                                opacity: onPalette ? 1 : 0,
                                                                border: `1px solid ${buttonsColorText(invitation.generals.palette.primary.toString())}`
                                                            }}
                                                            id='pick-color-button' />
                                                    </ColorPicker>
                                                </Row>



                                                <Button id='select-color-example-button' style={{
                                                    backgroundColor: darker(invitation.generals.palette.buttons, 0.9),
                                                    color: buttonsColorText(invitation.generals.palette.buttons.toString()),
                                                    position: 'relative'
                                                }}>Botones

                                                    <ColorPicker
                                                        disabledAlpha={false}
                                                        value={invitation.generals.palette.base}
                                                        onChange={onChangeButtons}
                                                        className='gc-color-picker'
                                                        style={{ backgroundColor: invitation.generals.palette.base }}>
                                                        <Button
                                                            icon={<TbClick size={18} />}
                                                            style={{
                                                                position: 'absolute', right: '8px',
                                                                opacity: onPalette ? 1 : 0,
                                                                border: `1px solid ${buttonsColorText(invitation.generals.palette.buttons.toString())}`
                                                            }}
                                                            id='pick-color-button' />
                                                    </ColorPicker>
                                                </Button>




                                                {/* <div className='select-color-item'
                                        // onClick={() => setOnPalette(false)}
                                        >
                                            <span>Monocromático</span>

                                            <ColorPicker
                                                disabledAlpha={false}
                                                value={invitation.generals.palette.base}
                                                onChange={onChangeColor}
                                                className='gc-color-picker'
                                                style={{ backgroundColor: invitation.generals.palette.base }}>

                                            </ColorPicker>
                                            <div
                                                onClick={() => onChangeTheme(!invitation.generals.theme)}
                                                className='theme-button'
                                                style={{
                                                    backgroundColor: colorPalette.base,
                                                    color: lighter(colorPalette.base, 0.8)
                                                }}>
                                                {
                                                    theme ?
                                                        <>
                                                            <span>Claro</span>
                                                            <FaSun size={16} style={{ color: lighter(colorPalette.base, 0.8) }} />
                                                        </>
                                                        : <>
                                                            <span>Obscuro</span>
                                                            <FaSun size={16} style={{ color: lighter(colorPalette.base, 0.8) }} />
                                                        </>
                                                }

                                            </div>


                                        </div>

                                        <div className='select-color-item'
                                        // onClick={() => setOnPalette(true)}
                                        >
                                            <span>Paleta de colores</span>
                                            <ColorPicker
                                                disabledAlpha={false}
                                                value={invitation.generals.palette.base}
                                                onChange={onChangePrimary}
                                                className='gc-color-picker'
                                                style={{ backgroundColor: invitation.generals.palette.base }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: colorPalette.primary
                                                    }}
                                                    id='palette-buttons'>Fondos</Button>
                                            </ColorPicker>
                                            <ColorPicker
                                                disabledAlpha={false}
                                                value={invitation.generals.palette.base}
                                                onChange={onChangeSecondary}
                                                className='gc-color-picker'
                                                style={{ backgroundColor: invitation.generals.palette.base }}>
                                                <Button
                                                    style={{
                                                        backgroundColor: colorPalette.secondary
                                                    }}
                                                    id='palette-buttons'>Formas</Button>
                                            </ColorPicker>
                                            <ColorPicker
                                                disabledAlpha={false}
                                                value={invitation.generals.palette.base}
                                                onChange={onChangeAccent}
                                                className='gc-color-picker'
                                                style={{ backgroundColor: invitation.generals.palette.base }}>
                                                <Button
                                                    style={{
                                                        background: colorPalette.accent
                                                    }}
                                                    id='palette-buttons'>Textos</Button>
                                            </ColorPicker>

                                        </div> */}

                                            </div>






                                        </Col>
                                    )
                                }

                                {
                                    location === 'order' && (
                                        <Col className='gc-order-container' style={{
                                            margin: 0
                                        }}>

                                            <Row className='gc-cta-buttons-container edit-position-controller' style={{
                                                margin: '0px 0px 10px 0',
                                            }}>
                                                <Button
                                                    id='gc-cta-secondary-button'
                                                    onClick={restartPositions}
                                                    icon={<VscDebugRestart />}

                                                />

                                                <Button
                                                    onClick={() => moveUpPosition(currentPosition)}
                                                    icon={<IoMdArrowRoundUp />}
                                                    id='gc-cta-secondary-button'
                                                    style={{ margin: '0px 5px' }}
                                                />

                                                <Button
                                                    onClick={() => moveDownPosition(currentPosition)}
                                                    icon={<IoMdArrowRoundDown />}
                                                    id='gc-cta-secondary-button' />

                                                {/* <Button
                                                    onClick={() => moveUpPosition(1)}
                                                    icon={<MdVerticalAlignTop />}
                                                    id='gc-cta-secondary-button'
                                                    style={{ margin: '0px 5px' }} />

                                                <Button
                                                    onClick={() => moveDownPosition(7)}
                                                    icon={<MdVerticalAlignBottom />}
                                                    id='gc-cta-secondary-button' /> */}


                                            </Row>


                                            {
                                                invitation.generals.positions.map((item, index) => (
                                                    <div
                                                        onClick={() => handleClick(item, index)}
                                                        className={`gc-order-item${item === currentItem ? '--selected' : ''}`}>{handleNamePosition(item)}</div>
                                                ))
                                            }
                                        </Col>
                                    )
                                }


                            </div>

                            {/* {
                                orderMode ?
                                    <div className='generals-card-container'>

                                        <Row className='gc-title-cta-buttons-container'>

                                            <Row style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                flexDirection: 'row'
                                            }}>
                                                <p className='gc--title'>Acomodo</p>
                                                <LuBadgeHelp className='help--icon' onClick={() => handleDrawer('orden')} />
                                            </Row>


                                            <Row className='gc-cta-buttons-container'>
                                                <Button
                                                    id='gc-cta-secondary-button'
                                                    onClick={restartPositions}
                                                    icon={<VscDebugRestart />}

                                                />

                                                <Button
                                                    onClick={() => moveUpPosition(currentPosition)}
                                                    icon={<IoMdArrowRoundUp />}
                                                    id='gc-cta-secondary-button'
                                                    style={{ margin: '0px 5px' }}
                                                />

                                                <Button
                                                    onClick={() => moveDownPosition(currentPosition)}
                                                    icon={<IoMdArrowRoundDown />}
                                                    id='gc-cta-secondary-button' />


                                            </Row>

                                        </Row>



                                    </div>

                                    : <></>

                            } */}

                            {/* {
                                separatorMode ?
                                    <div className='generals-card-container'>

                                        <Row className='gc-title-cta-buttons-container'>
                                            <p className='gc--title'>Separadores</p>



                                        </Row>






                                    </div>

                                    : <></>

                            } */}









                        </Row>

                        {/* <hr style={{
                            width: '100%', border: '0px solid #d9d9d980', margin: '40px 0'
                        }} />

                        <Col className='generals-template-main-container'>

                            <h2 className='module--title'>Menu de Templates</h2>

                            <p className='module--description'>Explora nuestra extensa colección de templates y descubre la manera perfecta de iniciar la creación de tu invitación. Con cientos de opciones disponibles, estamos seguros de que encontrarás el diseño que se ajuste perfectamente a cualquier tipo de evento que estés planeando. </p>


                            <Row
                                className='generals-template-container'>
                                {
                                    [1, 1, 1, 1, 1, 1, 1].map((item) => (
                                        <div className='generals-template-item'>

                                        </div>
                                    ))
                                }

                            </Row>
                        </Col> */}

                    </div >
                    : <></>
            }


            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />

        </>
    )
}


{/* <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column', marginRight: '15px'
                            }}>

                                <p style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Tipo de letra</p>
                                <Select

                                    value={invitation.generals.font}
                                    onChange={(e) => handleFont(e)}
                                    style={{ width: '300px', fontFamily: invitation.generals.font, }}>
                                    {fonts.map((font, index) => (
                                        <Option style={{ fontFamily: font }} key={index} value={font}>{font}</Option>
                                    ))}

                                </Select>

                            </Col>



                            <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column',
                                // marginTop: '15px'
                            }}>

                                <p style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Color de la invitación</p>

                                <ColorPicker
                                    disabledAlpha={false}
                                    value={invitation.generals.color}
                                    onChange={onChangeColor}
                                    style={{
                                        width: '35px',
                                        height: '35px', borderRadius: '50px',
                                        backgroundColor: invitation.generals.color
                                    }} />

                            </Col>

                            <Col style={{
                                width: 'auto', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                flexDirection: 'column', margin: '0px 20px'
                            }}>
                                <p style={{
                                    width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                    marginBottom: '5px'
                                }}>Tema de la invitación</p>


                                <Button
                                    onClick={() => onChangeTheme(!invitation.generals.theme)}
                                    icon={invitation.generals.theme ? <FiSun size={15} style={{
                                        color: darker(MainColor, 0.4)
                                    }} /> : <IoMdMoon size={15} style={{
                                        color: lighter(MainColor, 0.5)
                                    }} />}
                                    style={{
                                        borderRadius: '50%',
                                        display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        backgroundColor: invitation.generals.theme ? lighter(MainColor, 0.9) : `${darker(MainColor, 0.6)}`,

                                    }} />

                            </Col>

                            <Col style={{
                            width: '30%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                            flexDirection: 'column', marginTop: '15px'
                        }}>

                            <p style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>URL de la invitación</p>

                            <Input
                                value={invitation.generals.eventName}
                                placeholder={''}
                                style={{
                                    width: '100%',
                                    borderRadius: '15px',
                                }} />

                        </Col>

                        <Col style={{
                            width: '30%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                            flexDirection: 'column', marginTop: '15px'
                        }}>

                            <p style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Templates</p>

                            <p style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Separadores</p>

                            <p style={{
                                width: 'auto', textAlign: 'left', fontFamily: 'Roboto', fontSize: '1em',
                                marginBottom: '5px'
                            }}>Orden</p>



                        </Col> */}
