import { Button, Col, Input, Row, Switch } from 'antd'

import { BiSolidColorFill, IoMdAdd, IoClose, LuSeparatorHorizontal, TbEyeClosed } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext } from 'react';
import { appContext } from '../../context';


export const BuildFamily = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                title: e ? e.target.value : prevInvitation.family.title,
            },
        }));
        setSaved(false)
    }

    const changeTitlePersona = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.map((persona, i) => {
                    if (i === index) {
                        return {
                            ...persona,
                            title: newType
                        };
                    }
                    return persona;
                })
            }
        }));
        setSaved(false)
    }

    const changeNamePersona = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.map((persona, i) => {
                    if (i === index) {
                        return {
                            ...persona,
                            name: newType
                        };
                    }
                    return persona;
                })
            }
        }));
        setSaved(false)
    }

    const addNewPersona = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: [
                    ...prevInvitation.family.personas,
                    {
                        title: null,
                        name: null
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const deletePersonaByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                personas: prevInvitation.family.personas.filter((persona, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            family: {
                ...prevInvitation.family,
                separator: e,
            },
        }));
        setSaved(false)
    }

    return (
        <>
            {
                invitation ?
                    <div className='build-cover-main-container'>


                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Personas</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Agrega a las personas más importantes para tu evento, como familiares o tus amigos más cercanos, sin límite de número. Este módulo te permite destacar a aquellos que juegan un papel especial o cuya presencia es fundamental.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }


                        <Row className='build-componente-action-container '>

                            <Col className='title-input-col-container'>

                                {
                                    invitation.family.active && (
                                        <>
                                            <p className='simple-content-label'>Título</p>

                                            <Input
                                                onChange={onChangeTitle}
                                                value={invitation.family.title}
                                                className='gc-input-text' />

                                        </>
                                    )
                                }

                            </Col>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                            }}>

                                {
                                    invitation.family.active && (<>
                                        <Button
                                            type='ghost'
                                            onClick={() => onChangeSeparator(!invitation.family.separator)}
                                            id={`gc-cta-buttons${invitation.family.separator ? '--selected' : ''}`}
                                            icon={<LuSeparatorHorizontal size={25} />} />

                                        <Button
                                            type='ghost'
                                            onClick={() => onChangeBackground(!invitation.family.background)}
                                            id={`gc-cta-buttons${invitation.family.background ? '--selected' : ''}`}
                                            style={{
                                                marginRight: '5px'
                                            }}
                                            icon={<BiSolidColorFill size={25} />} />
                                    </>)
                                }



                                <Switch
                                    value={invitation.family.active}
                                    onChange={handleActive} />

                            </Row>




                        </Row>


                        {
                            invitation.family.active ?
                                <>
                                    <CustomButton
                                        onClick={addNewPersona}
                                        icon={<IoMdAdd />} text={'Nueva Persona'} style={{
                                            margin: '30px 0'
                                        }} />


                                    <Row className='build-gallery-scrol build-family-cards-container'>
                                        {
                                            invitation.family.personas.map((persona, index) => (
                                                <Col
                                                    key={index}
                                                    className='interactive--card regular-card'
                                                    style={{
                                                        width: !onDesign && '100%'
                                                    }}>

                                                    <Button
                                                        // id='gc-cta-secondary-button'
                                                        type='ghost'
                                                        id="gc-cta-buttons"
                                                        onClick={() => deletePersonaByIndex(index)}
                                                        icon={<IoClose size={20} />}
                                                        style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                    />

                                                    <p className='simple-content-label'>Título</p>

                                                    <Input
                                                        onChange={(e) => changeTitlePersona(index, e.target.value)}
                                                        value={persona.title}
                                                        placeholder='Título de la persona'
                                                        className='gc-input-text' />

                                                    <p className='simple-content-label'>Nombre</p>

                                                    <Input
                                                        // onChange={onChangeTitle}
                                                        onChange={(e) => changeNamePersona(index, e.target.value)}
                                                        value={persona.name}
                                                        placeholder='Nombre de la persona'
                                                        className='gc-input-text' />
                                                </Col>
                                            ))
                                        }

                                    </Row>

                                </>
                                :
                                <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }




                    </div >
                    : <></>
            }
        </>
    )
}


