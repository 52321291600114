
import { Layout, Popconfirm } from 'antd';

import {
    BuildCover, BuildGreeting, BuildFamily, BuildQuote, BuildItinerary,
    BuildDressCode, BuildGifts, BuildNotices, BuildGallery, BuildGenerals
} from '../../modules/build';
import { useEffect } from 'react';

const { Content } = Layout;



export const SiderTry = ({ buttons, invitation, setInvitation, currentSection, coverUpdated, setCoverUpdated, invitationID, setSaved,
    saved, mode, setMode, onTry
}) => {


    const handleEditor = (type) => {
        switch (type) {
            case 'generals': return <BuildGenerals onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'cover': return <BuildCover onTry={onTry} onDesign={false} invitationID={invitationID} invitation={invitation} setInvitation={setInvitation} coverUpdated={coverUpdated} setCoverUpdated={setCoverUpdated} setSaved={setSaved} />
            case 'greeting': return <BuildGreeting onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'family': return <BuildFamily onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'quote': return <BuildQuote onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'itinerary': return <BuildItinerary onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'dresscode': return <BuildDressCode onTry={onTry} onDesign={false} invitation={invitation} setInvitation={setInvitation} invitationID={invitationID} setSaved={setSaved} />
            case 'gifts': return <BuildGifts onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'notices': return <BuildNotices onDesign={false} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            case 'gallery': return <BuildGallery onTry={onTry} onDesign={false} invitationID={invitationID} invitation={invitation} setInvitation={setInvitation} setSaved={setSaved} />
            default:
                break;
        }
    }


    return (

        <Content className='build-content-modules-main-container scrollable-div' style={{ position: 'relative', backgroundColor: 'transparent' }} >

            <div className='build-content-modules-container'>
                {
                    handleEditor(buttons[currentSection - 1].type)
                }

            </div>

            {/* <div className="nav-items-container" style={{
                backgroundColor: '#FFF',
                position: 'absolute', left: '50%', transform: 'translateX(-50%)',
                bottom: '-50px',
            }} >
                <p
                    onClick={() => setMode('on-edit')}
                    className={`header-item ${mode === 'on-edit' ? 'hi--selected' : ''}`}
                    style={{
                    }}>
                    Diseña
                </p>

                {

                    !saved
                        ?
                        <Popconfirm
                            title="Salir sin Guardar"
                            description="Tienes cambios sin guardar. Asegurate de guardar tus cambios antes de moverte a Invitados"
                            // onConfirm={moodeAndDelete}
                            placement="bottomLeft"
                            okText="Cerrar"
                            cancelButtonProps={{ style: { display: 'none' } }}
                            id="popup-confirm"
                        >
                            <p
                                // onClick={() => setMode('on-guests')}
                                className={`header-item ${mode === 'on-guests' ? 'hi--selected' : ''}`}
                            >
                                Invita
                            </p>
                        </Popconfirm>
                        :
                        <p
                            onClick={() => setMode('on-guests')}
                            className={`header-item ${mode === 'on-guests' ? 'hi--selected' : ''}`}
                        >
                            Invita
                        </p>

                }



            </div> */}


        </Content>

    )
}
