import React from 'react'
import { Login } from '../components/land-page/Login'
import { Button } from 'antd'
import { Link } from 'react-router-dom'
import { IoArrowUndoSharp } from '../helpers'

export const LoginPage = () => {
    return (
        <div className='login-general-container-page'>
            <Link
                to={-1}
                style={{
                    position: 'absolute', left: '50px', top: '50px'
                }}>
                <Button type='ghost' icon={<IoArrowUndoSharp size={35} />} />
            </Link>
            <Login />
        </div>
    )
}
