import { Button, Col, Drawer, Empty, Input, InputNumber, Row, Switch, TimePicker } from 'antd'
import React, { useState, useRef, useEffect, useContext } from 'react'
import dayjs from 'dayjs';
import { convert12HrTo24Hr, formatTimeTo12Hours, iconsItinerary, load, TbEyeClosed, } from '../../helpers';
import { IconsModal } from '../../components/build'

import {
    IoMdAdd, LuSeparatorHorizontal, FaArrowUp, BiSolidColorFill, LuBadgeHelp,
    IoReturnDownBack, GrLocation, LiaSpotify, MdEdit, PiSpotifyLogoBold, FaRegTrashAlt,
    BsWindow, IoAddCircleOutline
} from '../../helpers';
import { HelpDrawer } from '../../components/build/HelpDrawer';
import { HowToDrawer } from '../../components/build/HowToDrawer';
import { CustomButton } from '../../components/CustomButton';
import { appContext } from '../../context';

const playlistmsg = "Por el momento, solo es posible compartir álbumes y playlists. Si intentas agregar una canción individual, aparecerá un error. ¡Gracias por tu comprensión!"
const addressmsg = "Para mostrar el mapa y el clima de un lugar, es necesario que completes todos los campos de la dirección. Esto nos permitirá ubicar con precisión la ubicación."


export const BuildItinerary = ({ invitation, setInvitation, setSaved, onDesign }) => {


    const instanciasContainer = useRef(null);
    // const { onDesign } = useContext(appContext)
    const [currentItem, setCurrentItem] = useState(null)
    const [onInstances, setOnInstances] = useState(false)
    const [onSpotify, setOnSpotify] = useState(false)
    const [onAdress, setOnAdress] = useState(false)
    const [onSaveClose, setOnSaveClose] = useState(false)
    const [onDelete, setOnDelete] = useState(false)
    const [onWheather, setOnWheather] = useState(false)
    const [onNewInstance, setOnNewInstance] = useState(false)
    const [currentIcon, setCurrentIcon] = useState(1)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const [playlistValue, setPlaylistValue] = useState()

    const [onAddressMode, setOnAdressMode] = useState(false)
    const [onSubitemsMode, setOnSubitemsMode] = useState(false)
    const [onPlaylistMode, setOnPlaylistMode] = useState(false)
    const [visible, setVisible] = useState(false)
    const [howToVisible, setHowToVisible] = useState(false)

    const [type, setType] = useState(null)
    const [location, setLocation] = useState('generals')



    useEffect(() => {
        setLocation('generals')
    }, [currentItem])



    const handleNewItem = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: [
                    ...prevInvitation.itinerary.object,
                    {
                        name: "Nuevo momento", // Nombre del nuevo ítem
                        time: "00:00 am", // Horario del nuevo ítem
                        subname: " ",
                        address: null,
                        subitems: null,
                        playlist: null,
                        active: false,
                        image: LuBadgeHelp,
                        id: Math.random().toString(36).substr(2, 9) // ID único generado aleatoriamente
                    }
                ],
            },
        }));
        setSaved(false)
    }

    const onNameChange = (objectId, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            name: newName // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };
    const onTimeChange = (objectId, time) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            time: time // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };
    const onSubnameChange = (objectId, subname) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subname: subname // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    //Adress
    const onCalleChange = (objectId, calle) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                calle: calle
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onNumeroChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                numero: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onColoniaChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                colonia: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onCPChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                CP: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onCiudadChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                ciudad: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onEstadoChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                estado: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onUrlChange = (objectId, new_) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            address: {
                                ...obj.address,
                                url: new_
                            }
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }


    //subtimes

    const onSubNameChange = (objectId, index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        name: newName
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onSubTimeChange = (objectId, index, newValue) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        time: newValue
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const onSubDescriptionChange = (objectId, index, newValue) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.map((subitem, subIndex) => {
                                // Si el índice coincide, actualizamos el nombre
                                if (subIndex === index) {
                                    return {
                                        ...subitem,
                                        description: newValue
                                    };
                                }
                                // Si no coincide, devolvemos el subitem sin cambios
                                return subitem;
                            })
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const addNewSubitem = (item) => {
        setOnNewInstance(false)
        instanciasToBottom(item)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === item.id) {
                        return {
                            ...obj,
                            subitems: [
                                ...obj.subitems,
                                {
                                    name: "",
                                    time: "00:00 am",
                                    description: ""
                                }
                            ]
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const removeSubitem = (objectId, index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            subitems: obj.subitems.filter((subitem, i) => i !== index)
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }

    const uploadPlyalist = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    // Si el ID del objeto coincide con el ID que estamos buscando, lo editamos
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            playlist: [playlistValue] // Cambiamos el nombre del objeto
                        };
                    }
                    // Si el ID no coincide, devolvemos el objeto sin cambios
                    return obj;
                })
            }
        }));
        setSaved(false)
    }



    // Adding itrems


    const handleImage = (objectId, value) => {
        // setCurrentIcon(null)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        return {
                            ...obj,
                            image: value
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleTime = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            time: obj.time ? null : "00:00 am"
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleSubname = (objectId) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            subname: obj.subname ? null : "Descripción"
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleAdress = (objectId) => {
        setOnAdress(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            address: obj.address ? null : {
                                calle: null,
                                numero: null,
                                colonia: null,
                                CP: null,
                                ciudad: null,
                                estado: null,
                                url: null
                            }
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handleSubitems = (objectId) => {
        setOnInstances(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            subitems: obj.subitems ? null : [
                                {
                                    name: null,
                                    time: null,
                                    description: null
                                }
                            ]
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const handlePlaylist = (objectId) => {
        setOnSpotify(false)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.map(obj => {
                    if (obj.id === objectId) {
                        // Si ya hay una playlist, la eliminamos (ponemos null), si no, agregamos el enlace de la playlist
                        return {
                            ...obj,
                            playlist: obj.playlist ? null : []
                        };
                    }
                    return obj;
                })
            }
        }));
        setSaved(false)
    };

    const removeObjectById = (objectId) => {
        setOnDelete(false)
        setCurrentItem(null)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                object: prevInvitation.itinerary.object.filter(obj => obj.id !== objectId)
            }
        }));
        setSaved(false)
    };

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                active: e,
            },
        }));
        setSaved(false)
    }

    const onSaveCloseItem = () => {
        setOnSaveClose(false)
        setCurrentItem(null)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            itinerary: {
                ...prevInvitation.itinerary,
                separator: e,
            },
        }));
        setSaved(false)
    }

    const switchSettings = (type, value) => {
        if (type === 'address') {
            if (value) {
                setOnAdressMode(true);
                setOnSubitemsMode(false);
                setOnPlaylistMode(false)
            } else if (!value && !onSubitemsMode && !onPlaylistMode) {
                setOnAdressMode(false);
            }

        } else if (type === 'subitmes') {
            if (value) {
                setOnAdressMode(false);
                setOnSubitemsMode(true);
                setOnPlaylistMode(false)
            } else if (!value && !onAddressMode && !onPlaylistMode) {
                setOnSubitemsMode(false);
            }
        } else if (type === 'playlist') {
            if (value) {
                setOnAdressMode(false);
                setOnSubitemsMode(false);
                setOnPlaylistMode(true)
            } else if (!value && !onAddressMode && !onSubitemsMode) {
                setOnPlaylistMode(false);
            }
        }

    };

    const instanciasToBottom = (item) => {
        if (instanciasContainer.current) {
            instanciasContainer.current.scrollTo({
                top: (323 * (item.subitems.length + 1)),
                behavior: 'smooth'
            });
        }
    };

    const handleDrawer = (type) => {
        setType(type)
        setVisible(true)
        // console.log(type)
    }

    const handleHowTo = (type) => {
        setType(type)
        setHowToVisible(true)
        // console.log(type)
    }

    const renderIcon = (index, size) => {
        console.log(index)
        const icon = iconsItinerary.find(icon => icon.index === index);
        if (icon) {
            const IconComponent = icon.value;
            return <IconComponent size={size} />;
        }
        return <LuBadgeHelp size={size} />;
    };

    const handleTitle = (location) => {
        switch (location) {
            case 'generals': return 'Generales'
            case 'address': return 'Dirección'
            case 'items': return 'Instancias'
            case 'playlist': return 'Música'

                break;

            default:
                break;
        }
    }


    return (
        <>
            {
                invitation ?
                    <div className='build-cover-main-container'>

                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Itinerario</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Detalla cada aspecto de tu evento en este módulo. Desde la secuencia de actividades, ubicaciones relevantes, menús planeados, listas de música hasta momentos destacados; este módulo ayuda a tus invitados a saber qué esperar y cómo prepararse para cada fase del evento.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }



                        <Row
                            className='itinerary-action-buttons-row-container'
                            style={{
                                alignItems: currentItem && 'flex-end'
                            }}
                        // className={`build-componente-action-container${currentItem !== null ? '--end' : ''}`}
                        >

                            {
                                currentItem !== null
                                    ?

                                    <div style={{
                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row',

                                    }}>
                                        {
                                            location === 'address' ?
                                                <div className='tag-info' >
                                                    {addressmsg}
                                                </div>
                                                : location === 'playlist' ?
                                                    <div className='tag-info' >
                                                        {playlistmsg}
                                                    </div> :

                                                    <div />
                                        }

                                        <Button
                                            onClick={onSaveCloseItem}
                                            icon={<IoReturnDownBack />}
                                            id='cta-primary-button'
                                        >
                                            Volver
                                        </Button>

                                    </div>



                                    :
                                    <>

                                        <Row style={{
                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                            width: '100%', marginBottom: '10px', minWidth: '290px'
                                        }}>

                                            {
                                                invitation.itinerary.active && (
                                                    <>
                                                        <Button
                                                            type='ghost'
                                                            onClick={() => onChangeSeparator(!invitation.itinerary.separator)}
                                                            id={`gc-cta-buttons${invitation.itinerary.separator ? '--selected' : ''}`}
                                                            icon={<LuSeparatorHorizontal size={25} />} />

                                                        <Button
                                                            type='ghost'
                                                            onClick={() => onChangeBackground(!invitation.itinerary.background)}
                                                            id={`gc-cta-buttons${invitation.itinerary.background ? '--selected' : ''}`}
                                                            style={{
                                                                marginRight: '5px'
                                                            }}
                                                            icon={<BiSolidColorFill size={25} />} />
                                                    </>
                                                )
                                            }




                                            <Switch
                                                value={invitation.itinerary.active}
                                                onChange={handleActive} />

                                        </Row>

                                        {
                                            invitation.itinerary.active ?
                                                <CustomButton
                                                    onClick={handleNewItem}
                                                    icon={<IoMdAdd />} text={'Nuevo Momento'} style={{

                                                    }} />

                                                :
                                                <div />


                                        }




                                    </>

                            }

                        </Row>

                        {
                            invitation.itinerary.active ?
                                <div style={{
                                    width: '100%', display: 'flex',
                                    marginTop: '10px', alignItems: 'flex-start', flexWrap: 'wrap'
                                }}>
                                    {
                                        currentItem !== null ?

                                            invitation.itinerary.object.map((item, index) => (
                                                currentItem === item.id ?
                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: '100%', display: 'flex', alignItems: 'flex-start',

                                                            justifyContent: 'center', flexDirection: 'row', flexWrap: 'wrap',
                                                        }}>
                                                        <div className='generals-card-container' style={{ margin: '10px', padding: '10px' }}>

                                                            <div style={{
                                                                display: onDelete ? '' : 'none',
                                                                position: 'absolute', width: 'auto', height: 'auto', borderRadius: '20px 0px 20px 20px',
                                                                backgroundColor: '#E46969', zIndex: '2',
                                                                top: '50px', right: '8%',
                                                                boxShadow: '0px 0px 8px rgba(0,0,0,0.5)', transition: 'all 0.35s', padding: '5%'
                                                            }}>
                                                                <p style={{
                                                                    width: '100%', textAlign: 'left', fontFamily: 'Roboto', color: '#FFF',
                                                                    fontSize: '1.1em', lineHeight: '1.3', fontWeight: '600'
                                                                }}>
                                                                    Elimina tu momento
                                                                </p>
                                                            </div>


                                                            <div style={{
                                                                display: onInstances ? 'flex' : 'none',
                                                                position: 'absolute', width: '200px', height: '150px', borderRadius: '20px 0px 20px 20px',
                                                                backgroundColor: '#1777FF', top: '50px', left: '10%',
                                                                boxShadow: '0px 0px 8px rgba(0,0,0,0.5)', transition: 'all 0.35s', padding: '5%',
                                                                zIndex: '2'
                                                                // alignItems: 'center'

                                                            }}>
                                                                <p style={{
                                                                    width: '100%', textAlign: 'left', fontFamily: 'Roboto', color: '#FFF',
                                                                    fontSize: '1.1em', lineHeight: '1.3', fontWeight: '600'
                                                                }}>
                                                                    Las instancias son pequeñas actividades que suceden dentro de un momento. ¿Quieres agregar una? ¡Hazlo aquí!
                                                                </p>
                                                            </div>

                                                            <div style={{
                                                                display: onSpotify ? '' : 'none',
                                                                position: 'absolute', width: '200px', height: '150px', borderRadius: '20px 00px 20px 20px',
                                                                backgroundColor: '#1777FF', top: '50px', right: '70px',
                                                                boxShadow: '0px 0px 8px rgba(0,0,0,0.5)', transition: 'all 0.35s', padding: '5%',
                                                                zIndex: '2'
                                                            }}>
                                                                <p style={{
                                                                    width: '100%', textAlign: 'left', fontFamily: 'Roboto', color: '#FFF',
                                                                    fontSize: '1.1em', lineHeight: '1.3', fontWeight: '600'
                                                                }}>
                                                                    ¿Tienes una playlist en Spotify que capture perfectamente tu momento? ¡Comparte su magia con tus invitados aquí mismo!
                                                                </p>
                                                            </div>


                                                            <div className='gc-title-cta-buttons-container'>


                                                                <Row style={{
                                                                    display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                                }}>
                                                                    {
                                                                        location === 'address' && (
                                                                            <LuBadgeHelp className={`gc--title${item.address ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('address')} />
                                                                        )
                                                                    }

                                                                    {
                                                                        location === 'items' && (
                                                                            <LuBadgeHelp className={`gc--title${item.subitems ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('instancias')} />
                                                                        )
                                                                    }

                                                                    {
                                                                        location === 'playlist' && (
                                                                            <LuBadgeHelp className={`gc--title${item.playlist ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('playlist')} />
                                                                        )
                                                                    }
                                                                    <p className='gc--title' style={{
                                                                        margin: '0px 5px'
                                                                    }}>{handleTitle(location)}</p>

                                                                    {
                                                                        location === 'address' && (
                                                                            <Switch
                                                                                size='small'
                                                                                checked={item.address ? true : false}
                                                                                onChange={(e) => handleAdress(item.id)} />
                                                                        )
                                                                    }

                                                                    {
                                                                        location === 'items' && (
                                                                            <Switch
                                                                                size='small'
                                                                                checked={item.subitems ? true : false}
                                                                                onChange={() => handleSubitems(item.id)}
                                                                            />
                                                                        )
                                                                    }

                                                                    {
                                                                        location === 'playlist' && (
                                                                            <Switch
                                                                                checked={item.playlist ? true : false}
                                                                                size='small'
                                                                                onChange={(e) => handlePlaylist(item.id)}
                                                                            />
                                                                        )
                                                                    }

                                                                </Row>



                                                                <Row className='gc-cta-buttons-container'>

                                                                    <Button
                                                                        type='ghost'
                                                                        onClick={() => setLocation('generals')}
                                                                        // onClick={() => switchSettings('address', !onAddressMode)}
                                                                        id={`gc-cta-buttons${location === 'generals' ? '--selected' : ''}`}
                                                                        icon={<BsWindow size={20} />}
                                                                        style={{
                                                                            margin: '0px 5px'
                                                                        }} />


                                                                    <Button
                                                                        type='ghost'
                                                                        onClick={() => setLocation('address')}
                                                                        // onClick={() => switchSettings('address', !onAddressMode)}
                                                                        id={`gc-cta-buttons${location === 'address' ? '--selected' : ''}`}
                                                                        icon={<GrLocation size={20} />}
                                                                        style={{
                                                                            margin: '0px 0px'
                                                                        }} />

                                                                    <Button
                                                                        type='ghost'
                                                                        onClick={() => setLocation('items')}
                                                                        // onClick={() => switchSettings('subitmes', !onSubitemsMode)}
                                                                        id={`gc-cta-buttons${location === 'items' ? '--selected' : ''}`}
                                                                        icon={<IoAddCircleOutline size={22} />}
                                                                        style={{
                                                                            margin: '0px 5px'
                                                                        }} />

                                                                    <Button
                                                                        type='ghost'
                                                                        onClick={() => setLocation('playlist')}
                                                                        // onClick={() => switchSettings('playlist', !onPlaylistMode)}
                                                                        id={`gc-cta-buttons${location === 'playlist' ? '--selected' : ''}`}
                                                                        style={{
                                                                            margin: '0px 0px'
                                                                        }}
                                                                        icon={<LiaSpotify size={23} />} />

                                                                    {/* <Button
                                                                        type='ghost'
                                                                        onClick={() => removeObjectById(item.id)}
                                                                        id={`gc-cta-delete-button${onDelete ? '--selected' : ''}`}
                                                                        icon={<FaRegTrashAlt size={18} />} /> */}

                                                                </Row>
                                                            </div>

                                                            {
                                                                location === 'generals' && (
                                                                    <Col style={{
                                                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                                        flexDirection: 'column'
                                                                    }}>
                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'center',
                                                                            justifyContent: 'center', flexDirection: 'column',
                                                                            margin: '3px 0'
                                                                        }}>
                                                                            <IconsModal
                                                                                handleImage={handleImage} id={item.id}
                                                                                isModalOpen={isModalOpen} setIsModalOpen={setIsModalOpen} currentIcon={currentIcon} setCurrentIcon={setCurrentIcon} />

                                                                            <div className='card-image-container'>
                                                                                {
                                                                                    item.image ?
                                                                                        renderIcon(item.image, 50)
                                                                                        : <LuBadgeHelp size={50} />
                                                                                }
                                                                                {/* <LuBadgeHelp size={50} /> */}


                                                                                <Button
                                                                                    id='gc-cta-edit-button'
                                                                                    onClick={() => setIsModalOpen(true)}
                                                                                    icon={<MdEdit size={15} />}
                                                                                />

                                                                            </div>

                                                                        </Col>

                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                            justifyContent: 'center', flexDirection: 'column',
                                                                            margin: '10px 0 5px 0'
                                                                        }}>
                                                                            <p className='gc-content-label'>Nombre</p>
                                                                            <Input className='gc-input-text'
                                                                                onChange={(e) => onNameChange(item.id, e.target.value)}
                                                                                value={item.name} />
                                                                        </Col>

                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                            justifyContent: 'center', flexDirection: 'column',
                                                                            margin: '7px 0'
                                                                        }}>

                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                                                flexDirection: 'row'
                                                                            }}>
                                                                                <p className='gc-content-label'>Hora</p>

                                                                                <Switch
                                                                                    onChange={() => handleTime(item.id)}

                                                                                    checked={item.time ? true : false}
                                                                                    size='small'
                                                                                />
                                                                            </Row>

                                                                            <TimePicker
                                                                                disabled={item.time ? false : true}
                                                                                className='gc-input-text'
                                                                                value={dayjs(convert12HrTo24Hr(item.time), 'HH:mm')}
                                                                                format={'HH:mm'}
                                                                                onChange={(e) => onTimeChange(item.id, formatTimeTo12Hours(e))}

                                                                            />

                                                                        </Col>

                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                            justifyContent: 'center', flexDirection: 'column',
                                                                            margin: '5px 0'
                                                                        }}>
                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                                                flexDirection: 'row'
                                                                            }}>
                                                                                <p className='gc-content-label'>Nombre del lugar</p>

                                                                                <Switch
                                                                                    onChange={() => handleSubname(item.id)}

                                                                                    checked={item.subname ? true : false}
                                                                                    size='small' style={{
                                                                                        // height: '28px', width: '28px', borderRadius: '50%',
                                                                                        backgroundColor: item.subname ? '#1777FF' : '#AAA', border: 'none',
                                                                                        // color: '#878787', 
                                                                                        // display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                                        // position: 'absolute', left: '2px'
                                                                                    }}
                                                                                />
                                                                            </Row>

                                                                            <Input

                                                                                disabled={item.subname ? false : true}
                                                                                className='gc-input-text'
                                                                                onChange={(e) => onSubnameChange(item.id, e.target.value)}
                                                                                value={item.subname} />
                                                                        </Col>
                                                                    </Col>
                                                                )
                                                            }


                                                            {
                                                                location === 'address' && (
                                                                    <Col style={{
                                                                        width: '100%', display: item.address ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                                        flexDirection: 'column'
                                                                    }}>

                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '7px 0'
                                                                            }}>
                                                                                <p className='gc-content-label'>Calle</p>
                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onCalleChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.calle : ''} />
                                                                            </Col>



                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center',
                                                                                justifyContent: 'space-between', flexDirection: 'row', margin: '7px 0'
                                                                            }}>
                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Número</p>
                                                                                    <InputNumber
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onNumeroChange(item.id, e)}
                                                                                        value={item.address ? item.address.numero : ''} />

                                                                                </Col>

                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Colonia</p>
                                                                                    <Input
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onColoniaChange(item.id, e.target.value)}
                                                                                        value={item.address ? item.address.colonia : ''} />

                                                                                </Col>

                                                                            </Row>

                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center',
                                                                                justifyContent: 'space-between', flexDirection: 'row', margin: '7px 0'
                                                                            }}>
                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Código Postal</p>
                                                                                    <InputNumber
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onCPChange(item.id, e)}
                                                                                        value={item.address ? item.address.CP : ''} />

                                                                                </Col>

                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Estado</p>
                                                                                    <Input
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onEstadoChange(item.id, e.target.value)}
                                                                                        value={item.address ? item.address.estado : ''} />

                                                                                </Col>

                                                                            </Row>

                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '7px 0'
                                                                            }}>
                                                                                <p className='gc-content-label'>Ciudad</p>
                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onCiudadChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.ciudad : ''} />
                                                                            </Col>

                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '20px 0 10px 0'
                                                                            }}>



                                                                                <Row style={{
                                                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <p className='gc-content-label'>URL Google Maps</p>

                                                                                    <LuBadgeHelp size={15}
                                                                                        onClick={() => handleHowTo('maps')}
                                                                                        style={{
                                                                                            color: '#FFA700', cursor: 'pointer',
                                                                                            marginLeft: '5px'
                                                                                        }} />
                                                                                </Row>

                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onUrlChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.url : ''} />
                                                                            </Col>


                                                                        </Col>

                                                                    </Col>
                                                                )
                                                            }

                                                            {
                                                                location === 'items' && (
                                                                    <Col style={{
                                                                        width: '100%', display: item.subitems ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                                        flexDirection: 'column'
                                                                    }}>
                                                                        {
                                                                            item.subitems && (
                                                                                <div
                                                                                    className='scroll-invitation'
                                                                                    ref={instanciasContainer}
                                                                                    style={{
                                                                                        width: '100%', display: 'flex', alignItems: item.subitems.length > 0 ? 'flex-start' : 'center', justifyContent: 'flex-start',
                                                                                        flexDirection: 'column', overflowY: 'scroll', marginTop: '0px',
                                                                                        height: '370px'
                                                                                    }}>

                                                                                    {

                                                                                        item.subitems.length > 0 ?
                                                                                            item.subitems.map((subitem, index) => (


                                                                                                <div
                                                                                                    key={index}
                                                                                                    id={index}
                                                                                                    className='new-subitem-card '>
                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column', margin: '7px 0'

                                                                                                    }}>

                                                                                                        <p className='gc-content-label'>Nombre</p>
                                                                                                        <Input
                                                                                                            className='gc-input-text'
                                                                                                            placeholder='Instancia'
                                                                                                            value={subitem.name}
                                                                                                            onChange={(e) => onSubNameChange(item.id, index, e.target.value)} />

                                                                                                    </Col>

                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column', margin: '4px 0'

                                                                                                    }}>



                                                                                                        <Row style={{
                                                                                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                                                                            flexDirection: 'row'
                                                                                                        }}>
                                                                                                            <p className='gc-content-label'>Hora</p>

                                                                                                            <Switch
                                                                                                                // onChange={() => handleSubname(item.id)}
                                                                                                                onChange={() => onSubTimeChange(item.id, index, subitem.time ? null : "00:00 am")}
                                                                                                                checked={subitem.time ? true : false}
                                                                                                                size='small'
                                                                                                            />
                                                                                                        </Row>

                                                                                                        <div style={{
                                                                                                            width: '100%', borderRadius: '30px',
                                                                                                            border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                                                                            flexDirection: 'row', position: 'relative'
                                                                                                        }}>

                                                                                                            <TimePicker
                                                                                                                disabled={subitem.time ? false : true}
                                                                                                                placeholder='0:00'
                                                                                                                className='gc-input-text'
                                                                                                                value={dayjs(convert12HrTo24Hr(subitem.time), 'HH:mm')} format={'HH:mm'}
                                                                                                                onChange={(e) => onSubTimeChange(item.id, index, formatTimeTo12Hours(e))} />
                                                                                                        </div>

                                                                                                    </Col>

                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                                                        margin: '7px 0'
                                                                                                    }}>
                                                                                                        <p className='gc-content-label'>Descripción</p>
                                                                                                        <Input.TextArea
                                                                                                            className='gc-input-text'
                                                                                                            placeholder='¿De qué trata tu instancia?'
                                                                                                            autoSize={{ minRows: 4, maxRows: 5 }}
                                                                                                            style={{ borderRadius: '12px' }}
                                                                                                            value={subitem.description}
                                                                                                            onChange={(e) => onSubDescriptionChange(item.id, index, e.target.value)} />
                                                                                                    </Col>

                                                                                                    <Button
                                                                                                        type='ghost'
                                                                                                        style={{
                                                                                                            margin: '10px 0', color: '#FFA700',
                                                                                                            fontWeight: 600
                                                                                                        }}
                                                                                                        onClick={() => removeSubitem(item.id, index)}>Eliminar</Button>

                                                                                                </div>


                                                                                            ))
                                                                                            : <Empty description={false} style={{
                                                                                                marginTop: '30px'
                                                                                            }} />


                                                                                    }

                                                                                </div>
                                                                            )
                                                                        }

                                                                        <div className='general-cards-cta-section'>

                                                                            <CustomButton
                                                                                onClick={() => addNewSubitem(item)}
                                                                                icon={<IoMdAdd />} text={'Nueva Instancia'} styleText={{
                                                                                    fontSize: '18px', fontWeight: 600
                                                                                }} />

                                                                        </div>

                                                                        {/* <LuBadgeHelp className={`gc--title${item.subitems ? '' : '--disabled'} help--icon`}
                                                                            onClick={() => handleDrawer('instancias')} /> */}

                                                                    </Col>
                                                                )
                                                            }


                                                            {
                                                                location === 'playlist' && (
                                                                    <Col style={{
                                                                        width: '100%', display: item.playlist ? 'flex' : 'none', alignItems: 'center',
                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                        margin: '5px 0', transition: 'all 0.35s'
                                                                    }}>
                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                                                            width: '100%'
                                                                        }}>
                                                                            <p

                                                                                className='gc-content-label'>URL Spotify</p>

                                                                            <LuBadgeHelp size={15}
                                                                                onClick={() => handleHowTo('spotify')}
                                                                                style={{
                                                                                    color: '#FFA700', cursor: 'pointer',
                                                                                    marginLeft: '5px'
                                                                                }} />
                                                                        </Row>



                                                                        <Input
                                                                            value={playlistValue}
                                                                            onChange={(e) => setPlaylistValue(e.target.value)}
                                                                            placeholder={item.playlist ? item.playlist[0] : ''}
                                                                            className='gc-input-text' />




                                                                        <CustomButton
                                                                            onClick={() => uploadPlyalist(item.id)}
                                                                            icon={<IoMdAdd />} text={'Subir'} style={{
                                                                                marginTop: '20px'
                                                                            }} />

                                                                        {/* <Button
                                                                    // onClick={onSaveCloseItem}
                                                                    icon={<FaArrowUp />}
                                                                    onClick={() => uploadPlyalist(item.id)}
                                                                    style={{ marginTop: '20px' }}
                                                                    id='cta-primary-button'
                                                                >
                                                                    Subir
                                                                </Button> */}


                                                                    </Col>
                                                                )
                                                            }


                                                            {
                                                                location === 'generals' && (
                                                                    <Button
                                                                        type='ghost'
                                                                        onClick={() => removeObjectById(item.id)}
                                                                        id='general-cards-text-button'
                                                                    // id={`gc-cta-delete-button${onDelete ? '--selected' : ''}`}
                                                                    >Eliminar</Button>
                                                                )
                                                            }







                                                        </div>

                                                        {
                                                            // item.address ?
                                                            onAddressMode ?
                                                                <div
                                                                    className={`generals-card-container${item.address ? '--itinerary' : '--disabled'}`}>


                                                                    <div style={{
                                                                        display: onWheather ? '' : 'none',
                                                                        position: 'absolute', width: '70%', height: 'auto', borderRadius: '20px 0px 20px 20px',
                                                                        backgroundColor: '#1777FF', zIndex: '2',
                                                                        top: '40px', left: '12%',
                                                                        boxShadow: '0px 0px 8px rgba(0,0,0,0.5)', transition: 'all 0.35s', padding: '5%'
                                                                    }}>
                                                                        <p style={{
                                                                            width: '100%', textAlign: 'left', fontFamily: 'Roboto', color: '#FFF',
                                                                            fontSize: '1.1em', lineHeight: '1.3', fontWeight: '600'
                                                                        }}>
                                                                            Cuando agregas una ubicación, se añade automáticamente el clima del lugar
                                                                            {/* ¿Te gustaría añadir el clima de tu ubicación? */}
                                                                        </p>

                                                                    </div>


                                                                    <div className='gc-title-cta-buttons-container '>
                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                            flexDirection: 'row'
                                                                        }}>

                                                                            <p className={`gc--title${item.address ? '' : '--disabled'}`}
                                                                            >Dirección</p>
                                                                            <LuBadgeHelp className={`gc--title${item.address ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('address')} />
                                                                        </Row>


                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                            flexDirection: 'row'
                                                                        }}>

                                                                            {/* <LuBadgeHelp
                                                                        size={20}
                                                                        style={{
                                                                            marginRight: '5px',
                                                                            color: '#FFA700'
                                                                        }} /> */}


                                                                            <Switch
                                                                                size='small'
                                                                                checked={item.address ? true : false}
                                                                                onChange={(e) => handleAdress(item.id)} />
                                                                        </Row>




                                                                    </div>


                                                                    <Col style={{
                                                                        width: '100%', display: item.address ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                                        flexDirection: 'column'
                                                                    }}>

                                                                        <Col style={{
                                                                            width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                                                                            flexDirection: 'column'
                                                                        }}>
                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '7px 0'
                                                                            }}>
                                                                                <p className='gc-content-label'>Calle</p>
                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onCalleChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.calle : ''} />
                                                                            </Col>



                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center',
                                                                                justifyContent: 'space-between', flexDirection: 'row', margin: '7px 0'
                                                                            }}>
                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Número</p>
                                                                                    <InputNumber
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onNumeroChange(item.id, e)}
                                                                                        value={item.address ? item.address.numero : ''} />

                                                                                </Col>

                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Colonia</p>
                                                                                    <Input
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onColoniaChange(item.id, e.target.value)}
                                                                                        value={item.address ? item.address.colonia : ''} />

                                                                                </Col>

                                                                            </Row>

                                                                            <Row style={{
                                                                                width: '100%', display: 'flex', alignItems: 'center',
                                                                                justifyContent: 'space-between', flexDirection: 'row', margin: '7px 0'
                                                                            }}>
                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Código Postal</p>
                                                                                    <InputNumber
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onCPChange(item.id, e)}
                                                                                        value={item.address ? item.address.CP : ''} />

                                                                                </Col>

                                                                                <Col style={{
                                                                                    width: '48%', display: 'flex', alignItems: 'flex-start',
                                                                                    justifyContent: 'center', flexDirection: 'column',

                                                                                }}>

                                                                                    <p className='gc-content-label'>Estado</p>
                                                                                    <Input
                                                                                        disabled={item.address ? false : true}
                                                                                        className='gc-input-text'
                                                                                        onChange={(e) => onEstadoChange(item.id, e.target.value)}
                                                                                        value={item.address ? item.address.estado : ''} />

                                                                                </Col>

                                                                            </Row>

                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '7px 0'
                                                                            }}>
                                                                                <p className='gc-content-label'>Ciudad</p>
                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onCiudadChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.ciudad : ''} />
                                                                            </Col>

                                                                            <Col style={{
                                                                                width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                justifyContent: 'center', flexDirection: 'column',
                                                                                margin: '20px 0 25px 0'
                                                                            }}>



                                                                                <Row style={{
                                                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                                                                    width: '100%'
                                                                                }}>
                                                                                    <p className='gc-content-label'>URL Google Maps</p>

                                                                                    <LuBadgeHelp size={15}
                                                                                        onClick={() => handleHowTo('maps')}
                                                                                        style={{
                                                                                            color: '#FFA700', cursor: 'pointer',
                                                                                            marginLeft: '5px'
                                                                                        }} />
                                                                                </Row>

                                                                                <Input
                                                                                    disabled={item.address ? false : true}
                                                                                    className='gc-input-text'
                                                                                    onChange={(e) => onUrlChange(item.id, e.target.value)}
                                                                                    value={item.address ? item.address.url : ''} />
                                                                            </Col>


                                                                        </Col>

                                                                    </Col>


                                                                </div>
                                                                : <></>
                                                        }

                                                        {
                                                            // item.subitems ?
                                                            onSubitemsMode ?
                                                                <div className={`generals-card-container${item.subitems ? '--itinerary' : '--disabled'}`}>

                                                                    {/* <div style={{
                                                                display: onNewInstance ? '' : 'none',
                                                                position: 'absolute', width: '70%', height: 'auto', borderRadius: '20px 0px 20px 20px',
                                                                backgroundColor: '#1777FF', zIndex: '2',
                                                                top: '40px', left: '12%',
                                                                boxShadow: '0px 0px 8px rgba(0,0,0,0.5)', transition: 'all 0.35s', padding: '5%'
                                                            }}>
                                                                <p style={{
                                                                    width: '100%', textAlign: 'left', fontFamily: 'Roboto', color: '#FFF',
                                                                    fontSize: '1.1em', lineHeight: '1.3', fontWeight: '600'
                                                                }}>
                                                                    Agrega una nueva instancia
                                                                </p></div> */}




                                                                    <div className='gc-title-cta-buttons-container '>

                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                            flexDirection: 'row'
                                                                        }}>

                                                                            <p className={`gc--title${item.subitems ? '' : '--disabled'}`}
                                                                            >Instancias</p>
                                                                            <LuBadgeHelp className={`gc--title${item.subitems ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('instancias')} />
                                                                        </Row>







                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
                                                                        }}>

                                                                            <Button
                                                                                type='ghost'

                                                                                onClick={() => addNewSubitem(item)}
                                                                                id={`gc-cta-buttons`}
                                                                                style={{
                                                                                    marginRight: '5px',
                                                                                    opacity: item.subitems ? 1 : 0
                                                                                }}
                                                                                icon={<IoMdAdd size={20} />} />

                                                                            {/*  */}

                                                                            <Switch
                                                                                size='small'
                                                                                checked={item.subitems ? true : false}
                                                                                onChange={() => handleSubitems(item.id)}
                                                                            />
                                                                        </Row>


                                                                    </div>






                                                                    <Col style={{
                                                                        width: '100%', display: item.subitems ? 'flex' : 'none', alignItems: 'center', justifyContent: 'flex-start',
                                                                        flexDirection: 'column'
                                                                    }}>
                                                                        {
                                                                            item.subitems && (
                                                                                <div
                                                                                    className='scroll-invitation'
                                                                                    ref={instanciasContainer}
                                                                                    style={{
                                                                                        width: '100%', display: 'flex', alignItems: item.subitems.length > 0 ? 'flex-start' : 'center', justifyContent: 'flex-start',
                                                                                        flexDirection: 'column', overflowY: 'scroll', marginTop: '0px',
                                                                                        height: '370px'
                                                                                    }}>

                                                                                    {

                                                                                        item.subitems.length > 0 ?
                                                                                            item.subitems.map((subitem, index) => (


                                                                                                <div
                                                                                                    key={index}
                                                                                                    id={index}
                                                                                                    className='new-subitem-card '>
                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column', margin: '7px 0'

                                                                                                    }}>

                                                                                                        <p className='gc-content-label'>Nombre</p>
                                                                                                        <Input
                                                                                                            className='gc-input-text'
                                                                                                            placeholder='Instancia'
                                                                                                            value={subitem.name}
                                                                                                            onChange={(e) => onSubNameChange(item.id, index, e.target.value)} />

                                                                                                    </Col>

                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column', margin: '4px 0'

                                                                                                    }}>



                                                                                                        <Row style={{
                                                                                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                                                                            flexDirection: 'row'
                                                                                                        }}>
                                                                                                            <p className='gc-content-label'>Hora</p>

                                                                                                            <Switch
                                                                                                                // onChange={() => handleSubname(item.id)}
                                                                                                                onChange={() => onSubTimeChange(item.id, index, subitem.time ? null : "00:00 am")}
                                                                                                                checked={subitem.time ? true : false}
                                                                                                                size='small'
                                                                                                            />
                                                                                                        </Row>

                                                                                                        <div style={{
                                                                                                            width: '100%', borderRadius: '30px',
                                                                                                            border: 'none', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                                                                            flexDirection: 'row', position: 'relative'
                                                                                                        }}>

                                                                                                            <TimePicker
                                                                                                                disabled={subitem.time ? false : true}
                                                                                                                placeholder='0:00'
                                                                                                                className='gc-input-text'
                                                                                                                value={dayjs(convert12HrTo24Hr(subitem.time), 'HH:mm')} format={'HH:mm'}
                                                                                                                onChange={(e) => onSubTimeChange(item.id, index, formatTimeTo12Hours(e))} />
                                                                                                        </div>

                                                                                                    </Col>

                                                                                                    <Col style={{
                                                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                                                        margin: '7px 0'
                                                                                                    }}>
                                                                                                        <p className='gc-content-label'>Descripción</p>
                                                                                                        <Input.TextArea
                                                                                                            className='gc-input-text'
                                                                                                            placeholder='¿De qué trata tu instancia?'
                                                                                                            autoSize={{ minRows: 4, maxRows: 5 }}
                                                                                                            style={{ borderRadius: '12px' }}
                                                                                                            value={subitem.description}
                                                                                                            onChange={(e) => onSubDescriptionChange(item.id, index, e.target.value)} />
                                                                                                    </Col>

                                                                                                    <Button
                                                                                                        type='ghost'
                                                                                                        style={{
                                                                                                            margin: '10px 0', color: '#FFA700',
                                                                                                            fontWeight: 600
                                                                                                        }}
                                                                                                        onClick={() => removeSubitem(item.id, index)}>Eliminar</Button>

                                                                                                </div>


                                                                                            ))
                                                                                            : <Empty description={false} style={{
                                                                                                marginTop: '30px'
                                                                                            }} />


                                                                                    }

                                                                                </div>
                                                                            )
                                                                        }



                                                                    </Col>


                                                                </div>
                                                                : <></>
                                                        }

                                                        {
                                                            // item.address ?
                                                            onPlaylistMode ?
                                                                <div className={`generals-card-container${item.playlist ? '--itinerary' : '--disabled'}`}>

                                                                    <div className='gc-title-cta-buttons-container '>

                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                                            flexDirection: 'row'
                                                                        }}>

                                                                            <p className={`gc--title${item.playlist ? '' : '--disabled'}`}

                                                                            >Playlist</p>
                                                                            <LuBadgeHelp className={`gc--title${item.playlist ? '' : '--disabled'} help--icon`}
                                                                                onClick={() => handleDrawer('playlist')} />
                                                                        </Row>



                                                                        <Switch
                                                                            checked={item.playlist ? true : false}
                                                                            size='small'
                                                                            onChange={(e) => handlePlaylist(item.id)}
                                                                        />


                                                                    </div>

                                                                    <Col style={{
                                                                        width: '100%', display: item.playlist ? 'flex' : 'none', alignItems: 'center',
                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                        margin: '5px 0', transition: 'all 0.35s'
                                                                    }}>
                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row',
                                                                            width: '100%'
                                                                        }}>
                                                                            <p

                                                                                className='gc-content-label'>URL Spotify</p>

                                                                            <LuBadgeHelp size={15}
                                                                                onClick={() => handleHowTo('spotify')}
                                                                                style={{
                                                                                    color: '#FFA700', cursor: 'pointer',
                                                                                    marginLeft: '5px'
                                                                                }} />
                                                                        </Row>



                                                                        <Input
                                                                            value={playlistValue}
                                                                            onChange={(e) => setPlaylistValue(e.target.value)}
                                                                            placeholder={item.playlist ? item.playlist[0] : ''}
                                                                            className='gc-input-text' />




                                                                        <CustomButton
                                                                            onClick={() => uploadPlyalist(item.id)}
                                                                            icon={<IoMdAdd />} text={'Subir'} style={{
                                                                                marginTop: '20px'
                                                                            }} />

                                                                        {/* <Button
                                                                    // onClick={onSaveCloseItem}
                                                                    icon={<FaArrowUp />}
                                                                    onClick={() => uploadPlyalist(item.id)}
                                                                    style={{ marginTop: '20px' }}
                                                                    id='cta-primary-button'
                                                                >
                                                                    Subir
                                                                </Button> */}


                                                                    </Col>





                                                                </div>
                                                                : <></>
                                                        }



                                                    </div>

                                                    : <></>
                                            ))

                                            : <Row className='build-gallery-scrol' style={{
                                                width: '100%',
                                                display: 'flex', alignItems: 'center', justifyContent: onDesign ? 'flex-start' : 'space-evenly',
                                                flexDirection: 'row',
                                            }}>
                                                {

                                                    invitation.itinerary.object.map((item, index) => (
                                                        <div
                                                            key={index}
                                                            onClick={() => setCurrentItem(item.id)}
                                                            // style={{
                                                            //     width: !onDesign && '100%', minWidth: !onDesign && '95%',
                                                            //     height: !onDesign && '150px'
                                                            // }}
                                                            className='interactive--card single-card'>


                                                            {
                                                                item.image ?
                                                                    renderIcon(item.image, 30)
                                                                    : <LuBadgeHelp size={30} />
                                                            }



                                                            <p style={{
                                                                marginBottom: item.time ? '' : '10px'
                                                            }} className='single-card-name '>{item.name}</p>
                                                            <p className='single-card-time'>{item.time}</p>

                                                        </div>
                                                    ))


                                                }


                                            </Row >

                                    }

                                </div >
                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }





                    </div >
                    : <></>
            }
            <HelpDrawer visible={visible} setVisible={setVisible} type={type} setType={setType} />
            <HowToDrawer visible={howToVisible} setVisible={setHowToVisible} type={type} setType={setType} />

        </>
    )
}
