import React, { useContext, useState } from 'react'
import { Footer } from 'antd/es/layout/layout';




export const FooterApp = ({

}) => {

    return (
        <Footer className='footer-app'>


        </Footer>

    )
}
