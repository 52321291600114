import { appContext } from "./AuthContext"
import { authReducer } from "./authReducer"
import { types } from "./types"
import { useReducer } from "react"

const init = () => {

    const isLogged = localStorage.getItem("logged")

    return {
        logged: isLogged ? true : false,
        user: JSON.parse(localStorage.getItem('user')),
        date: new Date(),
        // position: 'home'
        // user: (localStorage.getItem('user')),
    }
}

export const AppProvider = ({ children }) => {

    const [authState, dispatch] = useReducer(authReducer, {}, init)
    // const navigate = useNavigate()

    const login = (newUser = {}) => {

        const action = {
            type: types.LOGIN,
            payload: newUser
        }

        dispatch(action)
        localStorage.setItem("logged", true)
        localStorage.setItem('user', JSON.stringify(newUser))

    }

    const logout = () => {

        const action = {
            type: types.LOGOUT,

        }
        localStorage.removeItem('token')
        localStorage.removeItem("logged")
        dispatch(action)
    }

    const setMainColor = (color = '#000000') => {

        const action = {
            type: types.COLOR,
            payload: color
        }
        dispatch(action)
    }

    const setColorPalette = (color = {
        primary: "#000000",
        secondary: "#000000",
        accent: "#000000"
    }) => {

        const action = {
            type: types.PALETTE,
            payload: color
        }
        dispatch(action)
    }

    const setTheme = (theme = true) => {

        const action = {
            type: types.THEME,
            payload: theme
        }
        dispatch(action)
    }

    const setFont = (font = 'Georgia') => {

        const action = {
            type: types.FONT,
            payload: font
        }
        dispatch(action)
    }

    const setCover = (cover = false) => {

        const action = {
            type: types.COVER,
            payload: cover
        }
        dispatch(action)
    }

    const setOnDate = (date = new Date()) => {

        const action = {
            type: types.DATE,
            payload: date
        }

        dispatch(action)
    }

    const setOnDesigning = (onDesign = false) => {

        const action = {
            type: types.DESIGN,
            payload: onDesign
        }

        dispatch(action)
    }





    return (
        <appContext.Provider value={{
            ...authState,
            login,
            logout,
            setMainColor,
            setTheme,
            setFont,
            setCover,
            setOnDate,
            setOnDesigning,
            setColorPalette

        }}>
            {children}
        </appContext.Provider>

    )
}
