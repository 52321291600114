
const key = "AIzaSyBZ8NLpvAl4DiTeE2gYekBqhmSZFx43R0M"

export function simpleaddress(direccion, numero, colonia, codigoPostal, ciudad, estado) {
    const direccionCompleta = `${direccion} ${numero}, ${colonia}, ${codigoPostal}, ${ciudad}, ${estado}, Mexico`;
    const direccionCodificada = encodeURIComponent(direccionCompleta);

    const urlMapaGenerado = `https://maps.googleapis.com/maps/api/staticmap?center=${direccionCodificada}&zoom=17&size=800x1000&sensor=false&markers=${direccionCodificada}&scale=2&key=${key}`;

    console.log('Generated URL:', urlMapaGenerado);  // Agrega este console.log para verificar la URL
    return urlMapaGenerado;
}



// export function simpleaddress(direccion, numero, colonia, codigoPostal, ciudad, estado, size) {
//     const direccionCompleta = `${direccion} ${numero}, ${colonia}, ${codigoPostal}, ${ciudad}, ${estado}, Mexico`;
//     const direccionCodificada = encodeURIComponent(direccionCompleta);
//     const urlMapaGenerado = `https://maps.googleapis.com/maps/api/staticmap?center=${direccionCodificada}&zoom=15&size=${size}x${size}&markers=${direccionCodificada}&key=AIzaSyBZ8NLpvAl4DiTeE2gYekBqhmSZFx43R0M`;
//     return urlMapaGenerado;
// }


export const dynamicMap = ({ calle, numero, colonia, CP, ciudad, estado }) => {
    // Construir la dirección completa
    const apiKey = "AIzaSyBZ8NLpvAl4DiTeE2gYekBqhmSZFx43R0M"
    const direccionCompleta = `${calle} ${numero}, ${colonia}, ${CP}, ${ciudad}, ${estado}, Mexico`;

    // Construir la URL del iframe
    return `https://www.google.com/maps/embed/v1/place?key=${apiKey}&q=${encodeURIComponent(direccionCompleta)}`;


};




export function fulladdress(direccionCompleta) {
    const direccionCodificada = encodeURIComponent(direccionCompleta);
    const urlMapaGenerado = `https://maps.googleapis.com/maps/api/staticmap?center=${direccionCodificada}&zoom=15&size=400x400&markers=${direccionCodificada}&key={MI_API_KEY}`;
    return urlMapaGenerado;
}




