import { Button, Col, Input, Row, Switch } from 'antd'


import { BiSolidColorFill, IoMdAdd, IoClose, LuSeparatorHorizontal, TbEyeClosed } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext } from 'react';
import { appContext } from '../../context';


export const BuildNotices = ({ invitation, setInvitation, setSaved, onDesign }) => {

    // const { onDesign } = useContext(appContext)


    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                active: e,
            },
        }));
        setSaved(false)
    }



    const addNewNotice = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: [
                    ...prevInvitation.notices.notices,
                    null
                ]
            }
        }));
        setSaved(false)
    }

    const deleteNoticeByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: prevInvitation.notices.notices.filter((notice, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const editNoticeByIndex = (index, updatedNotice) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                notices: prevInvitation.notices.notices.map((notice, i) => {
                    if (i === index) {
                        return updatedNotice;
                    }
                    return notice;
                })
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            notices: {
                ...prevInvitation.notices,
                separator: e,
            },
        }));
        setSaved(false)
    }




    return (
        <>
            {
                invitation ?

                    <div className='generals-main-container'>

                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Avisos</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Podrás comunicar cualquier información importante o de última hora sobre tu evento. Puedes agregar tantos avisos como necesites, asegurando que todos los detalles relevantes sean comunicados claramente a todos los asistentes.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }

                        <Row className='build-notices-action-buttons-container'>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                width: '100%', marginBottom: '10px'
                            }}>

                                {
                                    invitation.notices.active && (
                                        <>
                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.notices.separator)}
                                                id={`gc-cta-buttons${invitation.notices.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.notices.background)}
                                                id={`gc-cta-buttons${invitation.notices.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />
                                        </>
                                    )
                                }



                                < Switch
                                    value={invitation.notices.active}
                                    onChange={handleActive} />

                            </Row>

                            {
                                invitation.notices.active ?
                                    <CustomButton
                                        onClick={addNewNotice}
                                        icon={<IoMdAdd />} text={'Nuevo Aviso'} style={{
                                            // marginRight: '10px'
                                        }} />
                                    : <div />
                            }








                        </Row>

                        {
                            invitation.notices.active ?
                                <div style={{
                                    width: '100%', display: 'flex', alignItems: 'flex-start',
                                    marginTop: '20px'
                                }}>
                                    <Col style={{
                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                        justifyContent: 'flex-start', flexDirection: 'column'
                                    }}>
                                        {
                                            invitation.notices.notices ?
                                                invitation.notices.notices.map((notice, index) => (

                                                    <div
                                                        key={index}
                                                        style={{
                                                            width: '100%', position: 'relative', margin: '10px',
                                                            paddingLeft: '7px'
                                                            // borderRadius: '15px'
                                                        }}>

                                                        <Input.TextArea
                                                            className='gc-input-text'
                                                            style={{ borderRadius: '8px', minWidth: '290px' }}
                                                            autoSize={{ minRows: 5, maxRows: 6 }}
                                                            onChange={(e) => editNoticeByIndex(index, e.target.value)}
                                                            placeholder='Nuevo aviso'
                                                            value={notice} />

                                                        <Button
                                                            type='ghost'
                                                            id="gc-cta-buttons"
                                                            onClick={() => deleteNoticeByIndex(index)}
                                                            icon={<IoClose size={20} />}
                                                            style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                        />


                                                    </div>
                                                ))
                                                : <></>
                                        }
                                    </Col>
                                </div>
                                : <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }



                    </div>


                    // <div style={{
                    //     width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    //     flexDirection: 'column'
                    // }}>
                    //     <Switch
                    //         value={invitation.notices.active}
                    //         onChange={handleActive} />

                    //     <Button onClick={addNewNotice}>Add notice</Button>

                    //     {
                    //         invitation.notices.notices ?
                    //             invitation.notices.notices.map((notice, index) => (

                    //                 <>
                    //                     <Button key={index} onClick={() => deleteNoticeByIndex(index)}>Remove</Button>
                    //                     <Input
                    //                         onChange={(e) => editNoticeByIndex(index, e.target.value)}
                    //                         placeholder={notice} />
                    //                 </>
                    //             ))
                    //             : <></>
                    //     }

                    // </div>
                    : <></>
            }
        </>
    )
}


