import { Button, Col, Input, Row, Switch } from 'antd'

import { IoMdAdd, LuSeparatorHorizontal, IoClose, BiSolidColorFill, TbEyeClosed, giftsAI, BsStars } from '../../helpers';
import { CustomButton } from '../../components/CustomButton';
import { useContext, useEffect, useState } from 'react';
import { appContext } from '../../context';


export const BuildGifts = ({ invitation, setInvitation, setSaved, onDesign }) => {

    const [onButton, setOnButton] = useState(false)
    const [onGeneration, setOnGeneration] = useState(false)
    const [descriptionValue, setDescriptionValue] = useState(null)
    const [textAppear, setTextAppear] = useState(false)

    const handleGenerating = () => {

        let local_description = giftsAI[Math.floor(Math.random() * 9)]

        setDescriptionValue('Generando ...');

        setTimeout(() => {
            setDescriptionValue(local_description)
        }, 4500);

        setOnGeneration(true);

        setTimeout(() => {
            setInvitation(prevInvitation => ({
                ...prevInvitation,
                gifts: {
                    ...prevInvitation.gifts,
                    description: local_description,
                },
            }));
            setSaved(false);
            setOnGeneration(false);
        }, 5000);
    };

    const onChangeTitle = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                title: e ? e.target.value : prevInvitation.gifts.title,
            },
        }));
        setSaved(false)
    }

    const onChangeDescription = (e) => {
        setDescriptionValue(e.target.value)
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                description: e ? e.target.value : prevInvitation.gifts.description,
            },
        }));
        setSaved(false)
    }

    const handleActive = (e) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                active: e,
            },
        }));
        setSaved(false)
    }

    const deleteCardByIndex = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.filter((card, i) => i !== index)
            }
        }));
        setSaved(false)
    }

    const addNewCard = () => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: [
                    ...prevInvitation.gifts.cards,
                    {
                        link: false,
                        type: null,
                        url: null,
                        bank: "Banco",
                        name: "Nombre",
                        number: "Número de cuenta"
                    }
                ]
            }
        }));
        setSaved(false)
    }

    const toggleCardState = (index) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        if (!card.link) {
                            return {
                                ...card,
                                link: true,
                                type: "Nombre de la página",
                                url: "URL",
                                bank: null,
                                name: null,
                                number: null
                            };
                        } else {
                            return {
                                ...card,
                                link: false,
                                type: null,
                                url: null,
                                bank: "Banco",
                                name: "Nombre",
                                number: "Número de cuenta"
                            };
                        }
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardTypeByIndex = (index, newType) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            type: newType
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardUrlByIndex = (index, newUrl) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            url: newUrl
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardBankByIndex = (index, newBank) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            bank: newBank
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardNameByIndex = (index, newName) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            name: newName
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const changeCardNumberByIndex = (index, newNumber) => {
        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                cards: prevInvitation.gifts.cards.map((card, i) => {
                    if (i === index) {
                        return {
                            ...card,
                            number: newNumber
                        };
                    }
                    return card;
                })
            }
        }));
        setSaved(false)
    }

    const onChangeBackground = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                background: e,
            },
        }));
        setSaved(false)
    }

    const onChangeSeparator = (e) => {

        setInvitation(prevInvitation => ({
            ...prevInvitation,
            gifts: {
                ...prevInvitation.gifts,
                separator: e,
            },
        }));
        setSaved(false)
    }

    useEffect(() => {
        setDescriptionValue(invitation.gifts.description)
    }, [])

    useEffect(() => {

        if (onButton) {
            setTimeout(() => {
                setTextAppear(onButton)
            }, 350);

        } else {
            setTextAppear(onButton)
        }

    }, [onButton])

    return (
        <>
            {
                invitation ?

                    <div className='generals-main-container' style={{
                        alignItems: 'flex-start'
                    }}>

                        <h2 className={!onDesign ? 'module--title--onDesign' : 'module--title'}
                            style={{
                                width: '100%',
                            }}
                        >Mesa de regalos</h2>

                        {
                            onDesign ?
                                <p className='module--description' >
                                    Si tu evento incluye la recepción de regalos, este módulo te permite agregar opciones como tarjetas de regalo o tarjetas de débito prepagadas. Personaliza este espacio con tantas opciones como consideres necesarias, facilitando a tus invitados la elección de un regalo perfecto.</p>
                                : <div className='module--description' style={{ marginBottom: '30px' }} />
                        }


                        <Row className='build-componente-action-container' style={{ flexDirection: 'column' }}>

                            <Row style={{
                                display: 'flex', alignItems: 'center', justifyContent: 'flex-end', flexDirection: 'row',
                                width: '100%'
                            }}>

                                {
                                    invitation.gifts.active && (
                                        <>
                                            <Button
                                                onMouseEnter={() => setOnButton(true)}
                                                onMouseLeave={() => setOnButton(false)}
                                                type='ghost'
                                                onClick={handleGenerating}
                                                id={`gc-cta-magic-btn`}
                                                style={{
                                                    width: onButton ? '110px' : '32px',
                                                    transition: 'all 0.4s ease',
                                                    boxShadow: onButton ? '0 0 15px #FFB32D' : '',
                                                    borderRadius: '50px',
                                                    height: '32px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                                                    background: onButton ? 'linear-gradient(to bottom, #FFB32D, #fdc564)' : 'transparent',
                                                    color: onButton ? '#FFF' : '#000',


                                                }}
                                                icon={<BsStars size={onButton ? 14 : 22} />}>
                                                <span style={{
                                                    opacity: textAppear ? 1 : 0,
                                                    display: onButton ? 'flex' : 'none',
                                                    transition: 'all 0.4s ease',
                                                    color: '#FFF'
                                                }}>Generar</span>
                                            </Button>
                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeSeparator(!invitation.gifts.separator)}
                                                id={`gc-cta-buttons${invitation.gifts.separator ? '--selected' : ''}`}
                                                icon={<LuSeparatorHorizontal size={25} />} />

                                            <Button
                                                type='ghost'
                                                onClick={() => onChangeBackground(!invitation.gifts.background)}
                                                id={`gc-cta-buttons${invitation.gifts.background ? '--selected' : ''}`}
                                                style={{
                                                    marginRight: '5px'
                                                }}
                                                icon={<BiSolidColorFill size={25} />} />
                                        </>
                                    )
                                }



                                < Switch
                                    value={invitation.gifts.active}
                                    onChange={handleActive} />
                            </Row>

                            <Col className='title-input-col-container' style={{
                                width: '100%', flexDirection: 'flex-start'
                            }}>
                                {
                                    invitation.gifts.active && (
                                        <>
                                            <p className='simple-content-label'>Título</p>
                                            <Input className='gc-input-text'
                                                onChange={onChangeTitle}
                                                value={invitation.gifts.title} />
                                        </>
                                    )

                                }

                            </Col>



                        </Row>

                        {
                            invitation.gifts.active ?
                                <>
                                    <Col style={{
                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                        justifyContent: 'flex-start', flexDirection: 'column',
                                        margin: '10px 0'
                                    }}>
                                        <p className='gc-content-label'>Descripción</p>
                                        <Input.TextArea className={`gc-input-text ${onGeneration ? 'magic-effect' : ''}`}
                                            style={{ borderRadius: '12px', transition: 'none', minWidth: '290px' }}
                                            value={descriptionValue}
                                            onChange={onChangeDescription}
                                            autoSize={{ minRows: 3, maxRows: 5 }} />
                                    </Col>

                                    <CustomButton
                                        onClick={addNewCard}
                                        icon={<IoMdAdd />} text={'Nueva Tarjeta'} style={{
                                            margin: '50px 0 10px 0'
                                        }} />


                                    <div style={{
                                        width: '100%', display: 'flex', alignItems: 'flex-start', marginTop: '20px',

                                    }}>

                                        <Row
                                            className='build-gallery-scrol'
                                            style={{
                                                width: '100%',
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
                                            }}>
                                            {
                                                invitation.gifts.cards ?
                                                    invitation.gifts.cards.map((card, index) => (

                                                        <div
                                                            className='interactive--card regular-card'
                                                            style={{ padding: '2%', width: !onDesign && '100%' }}
                                                            key={index}
                                                        >


                                                            <Button
                                                                type='ghost'
                                                                id="gc-cta-buttons"
                                                                onClick={() => deleteCardByIndex(index)}
                                                                icon={<IoClose size={20} />}
                                                                style={{ position: 'absolute', top: '5px', right: '5px' }}
                                                            />



                                                            <Row style={{
                                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
                                                                flexDirection: 'row',
                                                                // marginTop: '15px',
                                                                marginBottom: '10px'
                                                                // border: '1px solid red'
                                                            }}>
                                                                <Switch
                                                                    style={{
                                                                        marginRight: '5px'
                                                                    }}
                                                                    size='small'
                                                                    onChange={() => toggleCardState(index)}
                                                                    checked={card.link}
                                                                // checkedChildren="Tarjeta de regalo" unCheckedChildren="Tarjeta de débito" 
                                                                />

                                                                <p className='gc-content-label'>
                                                                    {
                                                                        card.link ?
                                                                            "Mesa de regalos online"
                                                                            : "Tarjeta de débito"
                                                                    }
                                                                </p>


                                                            </Row>



                                                            {
                                                                card.link ?

                                                                    <Col style={{
                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                        margin: '3px 0 15px 0'
                                                                    }}>
                                                                        {/* <p style={{
                                                                width: 'auto', fontSize: '1em', fontFamily: '',
                                                                marginLeft: '5px'
                                                            }}>Tienda</p> */}
                                                                        <Input
                                                                            className='gc-input-text'
                                                                            style={{
                                                                                marginBottom: '10px'
                                                                            }}
                                                                            onChange={(e) => changeCardTypeByIndex(index, e.target.value)}
                                                                            value={card.type} />

                                                                        {/* <p style={{
                                                                width: 'auto', fontSize: '1em', fontFamily: '',
                                                                marginLeft: '5px'
                                                            }}>URL</p> */}
                                                                        <Input
                                                                            className='gc-input-text'
                                                                            onChange={(e) => changeCardUrlByIndex(index, e.target.value)}
                                                                            value={card.url} />
                                                                    </Col>


                                                                    :
                                                                    <Col style={{
                                                                        width: '100%', display: 'flex', alignItems: 'flex-start',
                                                                        justifyContent: 'center', flexDirection: 'column',
                                                                        margin: '3px 0 15px 0'
                                                                    }}>
                                                                        <Row style={{
                                                                            display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'
                                                                        }}>
                                                                            <Input
                                                                                className='gc-input-text'
                                                                                style={{
                                                                                    width: '48%', marginBottom: '10px'
                                                                                }}
                                                                                onChange={(e) => changeCardBankByIndex(index, e.target.value)}
                                                                                value={card.bank} />
                                                                            <Input
                                                                                className='gc-input-text'
                                                                                style={{
                                                                                    width: '48%', marginBottom: '10px'
                                                                                }}
                                                                                onChange={(e) => changeCardNameByIndex(index, e.target.value)}
                                                                                value={card.name} />
                                                                        </Row>

                                                                        <Input
                                                                            className='gc-input-text'
                                                                            onChange={(e) => changeCardNumberByIndex(index, e.target.value)}
                                                                            value={card.number} />
                                                                    </Col>
                                                            }
                                                        </div>
                                                    ))
                                                    : <></>
                                            }
                                        </Row>



                                    </div>

                                </>
                                :
                                <div style={{
                                    width: '100%', height: '300px',
                                    display: 'flex', alignItems: 'center', justifyContent: 'center'
                                }}><TbEyeClosed size={32} style={{ color: '#717171' }} /></div>
                        }


                    </div>
                    // <div style={{
                    //     width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center',
                    //     flexDirection: 'column'
                    // }}>
                    //     <Switch
                    //         value={invitation.gifts.active}
                    //         onChange={handleActive} />
                    //     <Input
                    //         onChange={onChangeTitle}
                    //         placeholder={invitation.gifts.title}
                    //         style={{
                    //             width: '300px'
                    //         }} />

                    //     <Input.TextArea
                    //         placeholder={invitation.gifts.description}
                    //         onChange={onChangeDescription}
                    //         autoSize={{ minRows: 5, maxRows: 10 }}
                    //         style={{
                    //             width: '300px'
                    //         }} />


                    //     <Button onClick={addNewCard}>Add card</Button>

                    //     {
                    //         invitation.gifts.cards ?
                    //             invitation.gifts.cards.map((card, index) => (

                    //                 <>
                    //                     <Button key={index} onClick={() => deleteCardByIndex(index)}>Remove card</Button>
                    //                     <Switch
                    //                         onChange={() => toggleCardState(index)}
                    //                         checked={card.link}
                    //                         checkedChildren="Web" unCheckedChildren="Debit card" />

                    //                     {
                    //                         card.link ?
                    //                             <>
                    //                                 <Input
                    //                                     onChange={(e) => changeCardTypeByIndex(index, e.target.value)}
                    //                                     placeholder={card.type} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardUrlByIndex(index, e.target.value)}
                    //                                     placeholder={card.url} />
                    //                             </>

                    //                             :
                    //                             <>
                    //                                 <Input
                    //                                     onChange={(e) => changeCardBankByIndex(index, e.target.value)}
                    //                                     placeholder={card.bank} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardNameByIndex(index, e.target.value)}
                    //                                     placeholder={card.name} />
                    //                                 <Input
                    //                                     onChange={(e) => changeCardNumberByIndex(index, e.target.value)}
                    //                                     placeholder={card.number} />
                    //                             </>
                    //                     }
                    //                     {/* <Input key={index}
                    //                         onChange={(e) => editBoardItemByIndex(index, e.target.value)}
                    //                         placeholder={board}
                    //                         style={{
                    //                             width: '300px'
                    //                         }} /> */}
                    //                 </>
                    //             ))
                    //             : <></>
                    //     }

                    // </div>
                    : <></>
            }
        </>
    )
}


// return (
//     <>
//         {
//             invitation ?


//                 <div style={{
//                     width: '92%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
//                     flexDirection: 'column',
//                     border: '1px solid red'
//                 }}>
//                     <h2 style={{
//                         width: '100%', textAlign: 'left',
//                         fontWeight: 600, marginBottom: '20px', letterSpacing: '0',
//                         fontFamily: ''
//                     }}>Dresscode</h2>

//                     <p
//                         style={{
//                             width: '100%', textAlign: 'left', lineHeight: '1.3',
//                             marginBottom: '30px', fontSize: '1.1em', color: '#00000080',
//                             fontFamily: ''
//                         }}
//                     >Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aliquam purus turpis, aliquet id dapibus sed, convallis sit amet tortor. Integer lacinia maximus ex in placerat.</p>


//                     <Row style={{
//                         width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between', flexDirection: 'row'
//                     }}>

//                         <Col style={{
//                             width: '40%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             margin: '7px 0'
//                         }}>
//                             <p style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px'
//                             }}>Título</p>
//                             <Input
//                                 style={{
//                                     width: '100%', borderRadius: '30px'
//                                 }}
//                                 onChange={onChangeTitle}
//                                 placeholder={invitation.dresscode.title} />
//                         </Col>

//                         < Switch
//                             value={invitation.dresscode.active}
//                             onChange={handleActive} />
//                     </Row>

//                     <Row style={{
//                         width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'space-between', flexDirection: 'row'
//                     }}>

//                         <Col style={{
//                             width: '40%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             margin: '7px 0'
//                         }}>
//                             <p style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px'
//                             }}>Descripción</p>
//                             <Input.TextArea
//                                 autoSize={{ minRows: 5, maxRows: 10 }}
//                                 style={{
//                                     width: '100%', borderRadius: '15px'
//                                 }}
//                                 placeholder={invitation.dresscode.description}
//                                 onChange={onChangeDescription} />

//                             <p style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px', marginTop: '10px'
//                             }}>Username</p>
//                             <Input
//                                 style={{
//                                     width: '100%', borderRadius: '30px'
//                                 }}
//                                 onChange={onChnageUsername}
//                                 placeholder={invitation.dresscode.username} />

//                             <Button
//                                 onClick={addItemToBoards}
//                                 icon={<IoMdAdd style={{
//                                     color: '#FFF'
//                                 }} />}
//                                 type='primary' style={{
//                                     borderRadius: '30px', color: '#FFF',
//                                     display: 'flex', alignItems: 'center', justifyContent: 'center',
//                                     marginTop: '20px'
//                                 }}>
//                                 Nuevo Tablero
//                             </Button>

//                             {
//                                 invitation.dresscode.boards ?
//                                     invitation.dresscode.boards.map((board, index) => (
//                                         <div
//                                             key={index}
//                                             style={{
//                                                 width: '100%', borderRadius: '30px',
//                                                 border: '1px solid #D9D9D9', display: 'flex', alignItems: 'center', justifyContent: 'flex-start',
//                                                 flexDirection: 'row', position: 'relative',
//                                                 marginTop: '15px'
//                                             }}>
//                                             <Input
//                                                 placeholder={board}
//                                                 onChange={(e) => editBoardItemByIndex(index, e.target.value)}
//                                                 style={{
//                                                     width: '90%', borderRadius: '30px',
//                                                     border: 'none'
//                                                 }} />

//                                             <Button
//                                                 onClick={() => deleteItemFromBoardsByIndex(index)}
//                                                 style={{
//                                                     height: '28px', width: '28px', borderRadius: '50%',
//                                                     backgroundColor: '#F5F5F7', border: 'none',
//                                                     color: '#878787', 
//                                                     display: 'flex', alignItems: 'center', justifyContent: 'center',
//                                                     position: 'absolute', right: '2px'
//                                                 }}
//                                                 icon={<LuMinusCircle size={12} />}
//                                             />
//                                         </div>
//                                     ))
//                                     : <></>
//                             }

//                         </Col>

//                         <Col style={{
//                             width: '57%', display: 'flex', alignItems: 'flex-start',
//                             justifyContent: 'center', flexDirection: 'column',
//                             marginTop: '-5px'
//                             margin: '7px 0'
//                         }}>
//                             <p style={{
//                                 width: 'auto', fontSize: '1em', fontFamily: '',
//                                 marginLeft: '5px', marginTop: '10px'
//                             }}>¿Cómo funcionan los tableros de pinterest?</p>

//                             <div style={{
//                                 width: '100%', border: '1px solid #e3e3e3',
//                                 height: '380px', borderRadius: '15px'
//                             }}>

//                             </div>

//                         </Col>



//                     </Row>

//                 </div >

//                 : <></>
//         }
//     </>
// )