import { message } from "antd";
import { generateSimpleId } from "../helpers";

export async function getGuestsByInvitationID(operation, invitationID) {

    try {
        await operation({
            method: "GET",
            url: `/guests/${invitationID}`,
            // headers: {
            //     accept: "*/*",
            //     token: token
            // },
        })

    } catch (error) {
        console.error(error)
    }
}


export async function getUpdatesByID(operation, invitationID) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/guests/${invitationID}/updates`,
            headers: {
                accept: "*/*",
                token: token
            },
        })

    } catch (error) {
        console.error(error)
    }
}

export async function AddNewGuest(operation, guests, newGuest) {

    const data = {
        userID: guests.userID,
        invitationID: guests.invitationID,
        tickets: guests.tickets,
        type: guests.type,
        guests: [...guests.guests, newGuest]
    }

    try {
        await operation({
            method: "PATCH",
            url: `/guests/${guests.invitationID}`,
            data: data
        })

    } catch (error) {
        console.error(error)
    }
}



export async function deleteGuestByID(operation, invitationID, guestID) {
    const token = localStorage.getItem("token");

    try {
        await operation({
            method: "DELETE",
            url: `/guests/${invitationID}/guests`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                id: guestID
            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function moveTickets(operation, invitationID, card) {
    const token = localStorage.getItem("token");

    try {
        await operation({
            method: "PATCH",
            url: `/guests/${invitationID}/guests`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                id: card.id,
                guestUpdates: {
                    last_action: "edited",
                    available_cards: card.companions.length,
                    last_update_date: new Date()
                }
            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function editCurrentGuest(operation, invitationID, card, updates) {
    const token = localStorage.getItem("token");

    try {
        await operation({
            method: "PATCH",
            url: `/guests/${invitationID}/guests`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                id: card.id,
                guestUpdates: {
                    name: updates.name,
                    username: updates.username,
                    available_cards: updates.tickets,
                    last_update_date: new Date()
                }
            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function guestLogin(operation, invitationID, guestID) {

    try {
        await operation({
            method: "POST",
            url: `/guests/login`,
            data: {
                invitationID: invitationID,
                guestID: guestID
            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function editGuestsGuest(operation, invitationID, currentGuest, confirmed, tickets, currentGuestName) {

    const token = localStorage.getItem("guest-token");
    const data = {
        id: currentGuest.guestID,
        guestUpdates: {
            name: currentGuest.username,
            state: confirmed,
            last_action: confirmed === 'confirmado' ? 'accepted' : 'rejected',
            available_cards: currentGuest.card,
            companions: confirmed === 'confirmado' ? [currentGuestName, ...tickets] : tickets
        }
    }
    try {
        await operation({
            method: "PATCH",
            url: `/guests/${invitationID}/guests`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: data
        })

    } catch (error) {
        console.error(error)
    }
}

export async function EditTickets(operation, guests, availableTickets, guestsUpdated, typeCard) {
    const token = localStorage.getItem("token");

    const data = {
        userID: guests.userID,
        invitationID: guests.invitationID,
        tickets: availableTickets,
        type: typeCard,
        guests: guestsUpdated
    }

    try {
        await operation({
            method: "PATCH",
            url: `/guests/${guests.invitationID}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: data
        })

    } catch (error) {
        console.error(error)
    }
}

export async function addShare(operation, invitationID, email) {
    const token = localStorage.getItem("token");

    try {
        await operation({
            method: "POST",
            url: `/guests/shared/${invitationID}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                email: email,
                password: generateSimpleId(),
                id: generateSimpleId()

            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function deleteShare(operation, invitationID, id) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "DELETE",
            url: `guests/shared/${invitationID}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                shareId: id
            }
        })

    } catch (error) {
        console.error(error)
    }
}

export async function loginShare(operation, invitationID, password) {
    try {
        await operation({
            method: "POST",
            url: `guests/shared/login/${invitationID}`,
            data: {
                password: password
            }
        })

    } catch (error) {
        console.error(error)
    }
}
