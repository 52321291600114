import React, { useEffect, useState } from 'react'
import { CurrentForecast, CurrentWeather, Forecast } from '../../services/apiWeather'

import {
    TiWeatherCloudy, TiWeatherDownpour, TiWeatherNight, TiWeatherPartlySunny, TiWeatherShower,
    TiWeatherSnow, TiWeatherStormy, TiWeatherSunny, TiWeatherWindy, TiWeatherWindyCloudy
} from "react-icons/ti";
import { Col, Row, theme } from 'antd';
import { darker, lighter, switchdt } from '../../helpers/functions';
import { useWeather } from '../../hooks/customHook';


const currentIcon = (description, MainColor, theme) => {
    switch (description) {
        case 'Clear': return <TiWeatherSunny size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Clouds': return <TiWeatherCloudy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Rain': return <TiWeatherDownpour size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Drizzle': return <TiWeatherShower size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Thunderstorm': return <TiWeatherStormy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Snow': return <TiWeatherSnow size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Mist': return <TiWeatherPartlySunny size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Haze': return <TiWeatherPartlySunny size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Fog': return <TiWeatherWindyCloudy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Smoke': return <TiWeatherWindyCloudy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Sand': return <TiWeatherWindy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Dust': return <TiWeatherWindy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        case 'Tornado': return <TiWeatherWindy size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
        default: return <TiWeatherSunny size={22} style={{ color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5) }} />
    }
}


export const SimpleWeather = ({ cp, MainColor, theme, font }) => {

    const { response, loading, error, operation } = useWeather()
    const [weather, setWeather] = useState('')
    const [description, setDescription] = useState('')
    const [low, setLow] = useState('')
    const [high, setHigh] = useState('')

    useEffect(() => {
        CurrentWeather(operation, cp, 'mx')
    }, [])

    useEffect(() => {
        if (response) {
            if (response.data.main) {
                console.log(response.data.main)
                setWeather(response.data.main.temp)
                setLow(response.data.main.temp_min)
                setHigh(response.data.main.temp_max)
            }

            if (response.data.weather) {
                setDescription(response.data.weather[0].main)
            }
        }
    }, [response])








    return (

        <div style={{
            height: '100%', width: '100%',
            backgroundColor: 'transparent', padding: '5% 8%'
        }}>

            <h1 style={{
                fontSize: '2em', fontFamily: font, width: '100%',
                textAlign: 'left', margin: '5px 0 15px 0', color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5),
                fontWeight: 600
            }}>{Math.round(weather * 10) / 10}°</h1>

            <Row style={{
                width: '100%', display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start',
                flexDirection: 'column',
            }}>

                {
                    currentIcon(description, MainColor, theme)
                }

                <h5 style={{
                    textAlign: 'left', fontSize: '0.8em', color: theme ? darker(MainColor, 0.4) : lighter(MainColor, 0.5),
                    fontFamily: font
                }}>{low}° - {high}°</h5>


            </Row>


        </div >
    )
}

export const ForecastWeather = ({ cp, MainColor, theme, font, colorPalette }) => {

    const { response, loading, error, operation } = useWeather()
    const [weather, setWeather] = useState([])

    useEffect(() => {
        CurrentForecast(operation, cp, 'mx')
    }, [])

    useEffect(() => {
        if (response) {
            if (response.data.list) {
                console.log(response)
                const list = response.data.list;
                // Mapear la lista y actualizar el estado una sola vez al final
                const updatedWeather = list.map((item) => ({
                    weather: item.main.temp,
                    time: switchdt(item.dt),
                    icon: currentIcon(item.weather[0].main, MainColor, theme)
                }));
                setWeather(updatedWeather.slice(0, 5)); // Actualizar el estado con los primeros 5 elementos
            }
        }
    }, [response]);


    return (

        <div style={{
            height: '100%', width: '100%',
            backgroundColor: 'transparent', padding: '5% 8%',
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row'
        }}>
            {
                weather.slice(0, 5).map((item, index) => (
                    <Col
                        key={index}
                        style={{
                            width: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center',
                            flexDirection: 'column', margin: '0 10px',
                        }}>

                        <h5 style={{
                            textAlign: 'center', fontSize: '0.8em', width: '100%', fontFamily: font,
                            fontWeight: 600, color: colorPalette.accent, margin: '0 0 5px 0'
                        }}>{item.time}</h5>

                        {
                            item.icon
                        }


                        <h1 style={{
                            fontSize: '1em', fontFamily: font, width: '100%',
                            textAlign: 'center', margin: '5px 0 0 0', color: colorPalette.accent,
                            fontWeight: 600,
                        }}>{Math.round(item.weather * 10) / 10}°</h1>



                    </Col>
                ))
            }



        </div >
    )
}


// https://api.openweathermap.org/data/2.5/weather?zip=31130,MX&units=metric&appid=082be73deb2bcc79d867d128e39dfa2b