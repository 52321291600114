import React from 'react'
import { AppRouter } from './router/AppRouter'
import { AppProvider } from './context/AuthProvider'

export const IAttend = () => {
    return (
        <AppProvider>
            <AppRouter />
            {/* <p>Hola</p> */}
        </AppProvider>
    )
}


