import { getStorage, ref, listAll, getDownloadURL, uploadBytesResumable } from "firebase/storage";
const storage = getStorage();


export async function getInvitationbyID(operation, invitationID) {
    // const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/inv/${invitationID}`,
            // headers: {
            //     accept: "*/*",
            //     //     token:
            //     //         "QiOiI2NjA2MzI0N2ZjN2U5MGUyMmExMWFmNmEiLCJuYW1lIjoiYWxic2VycmFub2ciLCJpYXQiOjE3MTE5OTY1NTgsImV4cCI6MTcxMjAyNTM1OH0.A2l8qhqBQotXqos1ubC4pQtcb4cyM0PpY7QCA57YeEE"
            // },
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getTagByID(operation, invitationID) {
    // const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/tags/${invitationID}`,
            // headers: {
            //     accept: "*/*",
            //     //     token:
            //     //         "QiOiI2NjA2MzI0N2ZjN2U5MGUyMmExMWFmNmEiLCJuYW1lIjoiYWxic2VycmFub2ciLCJpYXQiOjE3MTE5OTY1NTgsImV4cCI6MTcxMjAyNTM1OH0.A2l8qhqBQotXqos1ubC4pQtcb4cyM0PpY7QCA57YeEE"
            // },
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getAllInvitations(operation) {
    // const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/inv`,
            // headers: {
            //     accept: "*/*",
            //     token: token
            // },
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getAllUserInvitations(operation, userID) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/inv/user/${userID}`,
            headers: {
                accept: "*/*",
                token: token
            },
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getAllUserTags(operation, userID) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "GET",
            url: `/tags/user/${userID}`,
            headers: {
                accept: "*/*",
                token: token
            },
        })

    } catch (error) {
        console.error(error)
    }
}



const downloadImages = async (folderPath) => {
    const folderRef = ref(storage, folderPath);
    const result = await listAll(folderRef);
    const imageUrls = await Promise.all(result.items.map(async (itemRef) => {
        const url = await getDownloadURL(itemRef);
        return url;
    }));
};

//   const uploadImages = async (newFolderPath) => {
//     const uploadedImages = await Promise.all(images.map(async (url, index) => {
//       const response = await fetch(url);
//       const blob = await response.blob();
//       const newFileName = `${newFolderPath}/image_${index}.jpg`; // Cambia la extensión según sea necesario
//       const newImageRef = ref(storage, newFileName);
//       await uploadBytes(newImageRef, blob);
//       return newFileName;
//     }));
//   };



export async function editInvitation(operation, invitation) {

    const token = localStorage.getItem("token");
    // console.log('edit inv: ', invitation)

    try {
        await operation({
            method: "PUT",
            url: `/inv/${invitation._id}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: invitation
        })

    } catch (error) {
        console.error(error)
    }
}

export async function editTag(operation, invitation) {

    const token = localStorage.getItem("token");

    try {
        await operation({
            method: "PUT",
            url: `/tags/${invitation._id}`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: invitation
        })

    } catch (error) {
        console.error(error)
    }
}




export async function newInvitation(operation, invitation, userAdmin) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/inv`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: invitation
        })

    } catch (error) {
        console.error(error)
    }
}

export async function newInvitationTags(operation, invitation) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/tags`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: invitation
        })

    } catch (error) {
        console.error(error)
    }
}


export async function createGuests(operation, guests) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/guests/`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: guests
        })

    } catch (error) {
        console.error(error)
    }
}

export async function getAllDominios(operation, label) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/inv/dominios`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                label: label
            }
        })

    } catch (error) {
        console.error(error)
    }
}


export async function getAllDominiosTags(operation, label) {
    const token = localStorage.getItem("token");
    try {
        await operation({
            method: "POST",
            url: `/tags/dominios`,
            headers: {
                accept: "*/*",
                token: token
            },
            data: {
                label: label
            }
        })

    } catch (error) {
        console.error(error)
    }
}



