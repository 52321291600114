import { Button, Drawer, Input, InputNumber, Row, message } from 'antd'
import { useInvitation } from '../../hooks'
import { useEffect, useState } from 'react'

import { IoMdAdd, LuMinus, generateGuestId, PiPaperPlaneTiltFill, generateSimpleId } from '../../helpers'
import { AddNewGuest, editCurrentGuest } from '../../services/apiGuests'
import './QR-handler.css'


export const NewGuestDrawer = ({ ticketsFree, visible, setDrawerState, available, guests, refreshPage, onEdit, currentGuest, deleteGuest }) => {


    const { response, operation } = useInvitation()
    const [availableTickets, setavailableTickets] = useState(null)
    const [tickets, setTickets] = useState(0)
    const [guestName, setGuestName] = useState(null)
    const [guestContact, setGuestContact] = useState(null)
    const [nextStep, setNextStep] = useState(false)

    useEffect(() => {
        if (visible) {
            console.log('my guests: ', guests)
            setavailableTickets(available)
            setTickets(0)
            setGuestName(null)
            setGuestContact(null)
            setNextStep(false)
            console.log(onEdit, currentGuest)
            if (onEdit) {
                setGuestName(currentGuest.name)
                setGuestContact(currentGuest.username)
                setTickets(currentGuest.available_cards)

            }
        }

    }, [visible])

    const onAddTicket = () => {
        if (availableTickets > 0) {
            setTickets(tickets + 1)
            setavailableTickets(availableTickets - 1)
        }

    }

    const onMinusTicker = () => {
        if (tickets > 0) {
            setTickets(tickets - 1)
            setavailableTickets(availableTickets + 1)
        }

    }


    const handleClose = () => {
        setDrawerState({
            currentGuest: null,
            onEditGuest: null,
            visible: false
        })
    }

    const onAddingNewGuest = () => {

        if (ticketsFree) {
            if (!guestName || !guestContact || !tickets) {
                message.error('Asegurate de llenar bien todos los campos')
            } else {

                const newGuest = {
                    name: guestName,
                    username: guestContact,
                    id: generateSimpleId(),
                    available_cards: tickets,
                    companions: [],
                    state: 'esperando',
                    last_action: 'created',
                    last_update_date: new Date(),
                    creation_date: new Date()
                }

                AddNewGuest(operation, guests, newGuest)
            }
        } else {
            if (!guestName || !guestContact) {
                message.error('Asegurate de llenar bien todos los campos')
            } else {
                const newGuest = {
                    name: guestName,
                    username: guestContact,
                    id: generateSimpleId(),
                    available_cards: 1,
                    companions: [],
                    state: 'esperando',
                    last_action: 'created',
                    last_update_date: new Date(),
                    creation_date: new Date()
                }
                AddNewGuest(operation, guests, newGuest)
            }
        }

    }

    const saveChanges = () => {
        if (!guestName || !guestContact || !tickets) {
            message.error('Asegurate de llenar bien todos los campos')
        } else {
            const updates = {
                name: guestName,
                username: guestContact,
                tickets: tickets
            }
            editCurrentGuest(operation, guests.invitationID, currentGuest, updates)
        }
    }

    const onNewGuest = () => {
        setNextStep(false)
        setGuestContact(null)
        setGuestName(null)
        setTickets(0)
    }

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Guest updated successfully":
                        refreshPage()
                        if (onEdit) {
                            message.success('Cambios realizados con éxito')
                        } else {
                            setNextStep(true)
                            message.success('Se ha agregado un nuevo invitado')
                        }

                        break;

                    default:
                        break;
                }
            }
        }
    }, [response])


    // useEffect(() => {
    //     if (tickets < 0) {
    //         message.error('No puedes tener menos de 0 pases')
    //         setTickets(tickets + 1)
    //     }

    //     if (onEdit) {
    //         if (tickets < currentGuest.companions.length) {
    //             message.error('No puedes tener menos de los pases ya repartidos')
    //             setTickets(tickets + 1)
    //         }
    //     }
    // }, [tickets])



    return (
        <Drawer
            // title="Basic Drawer"
            placement="right"
            className='help-drawer'
            closable={false}
            onClose={handleClose}
            open={visible}
            width={'35%'}
            style={{ zIndex: 99 }}

        >
            <div className='new-guest-container'>
                {
                    onEdit ? <h2 className='new-guest-title'>Edita invitados de tu lista</h2>
                        : <h2 className='new-guest-title'>Agrega invitados a tu lista</h2>
                }

                {
                    ticketsFree && (
                        <div className='available-tickets-container'>
                            <span className='new-guest-label'>Pases disponibles</span>
                            <span className='new-guest-available'>{availableTickets}</span>
                        </div>
                    )
                }




                <div className='new-guest-form-container'>
                    <p className='gc-content-label'>Nombre</p>

                    <Input
                        placeholder={'Nombre'}
                        value={guestName}
                        onChange={(e) => setGuestName(e.target.value)}
                        className='gc-input-text' />


                    <p style={{ marginTop: '20px' }} className='gc-content-label'>Contacto</p>

                    <Input
                        placeholder={'Contacto'}
                        value={guestContact}
                        onChange={(e) => setGuestContact(e.target.value)}
                        className='gc-input-text' />

                    {
                        ticketsFree && (
                            <>
                                <p style={{ marginTop: '20px' }} className='gc-content-label'>Pases disponibles</p>
                                <div className='new-guest-add-tickets-container'>
                                    <Button onClick={onMinusTicker} icon={<LuMinus size={20} />} id="new-guest-less-button" />
                                    <InputNumber
                                        min={0}
                                        // max={availableTickets}
                                        className='gc-input-text'
                                        style={{ borderRadius: '0', width: '180px', height: '40px', textAlign: 'center', fontSize: '18px' }}
                                        value={tickets}
                                        onChange={(e) => setTickets(e)}
                                    />
                                    <Button onClick={onAddTicket} icon={<IoMdAdd size={20} />} id="new-guest-add-button" />
                                </div>
                            </>
                        )
                    }




                    {
                        !nextStep && (
                            <div className='add-guest-button-container'>
                                {
                                    onEdit ?
                                        <Row style={{
                                            width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'column'
                                        }}>
                                            <Button onClick={saveChanges} id='ng-step-button'>Guardar</Button>
                                            <Button style={{ marginTop: '10px' }} onClick={() => deleteGuest(currentGuest.id)} id='ng-step-button-secondary'>Eliminar</Button>

                                        </Row>
                                        : <Button onClick={onAddingNewGuest} id='add-new-guest-button'>Agregar</Button>
                                }

                            </div>
                        )
                    }



                </div>

                {
                    nextStep && (
                        <div className='new-guest-nex-step-container'>
                            <span style={{ marginBottom: '20px', width: '80%' }}> ¡Has agregado exitosamente a tu invitado!</span>

                            <Button onClick={onNewGuest} icon={<IoMdAdd size={20} />} id="ng-step-button-secondary">Nuevo Invitado</Button>
                            {/* <Button icon={<PiPaperPlaneTiltFill />} id="ng-step-button">Enviar Invitación</Button> */}

                        </div>
                    )
                }




            </div>

        </Drawer>
    )
}




