import { BiParty } from "react-icons/bi";
import { AiOutlineCarryOut } from "react-icons/ai";
import { AiOutlineCoffee } from "react-icons/ai";
import { AiOutlineGift } from "react-icons/ai";
import { AiOutlineMail } from "react-icons/ai";
import { BsAlarm } from "react-icons/bs";
import { BsArrowThroughHeart } from "react-icons/bs";
import { BsBagHeartFill } from "react-icons/bs";
import { BsBell } from "react-icons/bs";
import { BsBookmarkHeart } from "react-icons/bs";
import { BsBookmarkStar } from "react-icons/bs";
import { BsBox2Heart } from "react-icons/bs";
import { BsCake } from "react-icons/bs";
import { BsCake2 } from "react-icons/bs";
import { BsCalendar2Check } from "react-icons/bs";
import { BsCalendar2Heart } from "react-icons/bs";
import { BsCalendarHeart } from "react-icons/bs";
import { BsChatDots } from "react-icons/bs";
import { BsChatRightDots } from "react-icons/bs";
import { BsChatRightHeart } from "react-icons/bs";
import { BsChatSquareDots } from "react-icons/bs";
import { BsChatSquareHeart } from "react-icons/bs";
import { BsClipboard2Heart } from "react-icons/bs";
import { BsClipboardCheck } from "react-icons/bs";
import { BsClock } from "react-icons/bs";
import { BsCloudMoon } from "react-icons/bs";
import { BsDice5 } from "react-icons/bs";
import { BsEmojiLaughing } from "react-icons/bs";
import { BsEnvelope } from "react-icons/bs";
import { BsEnvelopePaperHeart } from "react-icons/bs";
import { BsFillBalloonHeartFill } from "react-icons/bs";
import { BsFillBalloonFill } from "react-icons/bs";
import { BsFillBagHeartFill } from "react-icons/bs";
import { BsFillBrightnessHighFill } from "react-icons/bs";
import { BsGift } from "react-icons/bs";
import { BsHeart } from "react-icons/bs";
import { BsHearts } from "react-icons/bs";
import { BsHouse } from "react-icons/bs";
import { BsMegaphone } from "react-icons/bs";
import { BsPalette } from "react-icons/bs";
import { BsPatchExclamation } from "react-icons/bs";
import { BsPostcardHeart } from "react-icons/bs";
import { BsPostcard } from "react-icons/bs";
import { BsSignpost } from "react-icons/bs";
import { BsSignpost2 } from "react-icons/bs";
import { BsSuitHeart } from "react-icons/bs";
import { BsTrainFront } from "react-icons/bs";
import { BsTrash3 } from "react-icons/bs";
import { BsTree } from "react-icons/bs";
import { BsTrophy } from "react-icons/bs";
import { BsUmbrella } from "react-icons/bs";
import { FaFeather } from "react-icons/fa";
import { FaBeer } from "react-icons/fa";
import { BiChurch } from "react-icons/bi";
import { LuChurch } from "react-icons/lu";
import { MdDinnerDining } from "react-icons/md";
import { MdOutlineFoodBank } from "react-icons/md";
import { IoFastFoodOutline } from "react-icons/io5";


export const iconsItinerary = [
    {
        index: 1,
        value: BiParty
    },
    {
        index: 2,
        value: AiOutlineCarryOut
    },
    {
        index: 3,
        value: AiOutlineCoffee
    },
    {
        index: 4,
        value: AiOutlineGift
    },
    {
        index: 5,
        value: AiOutlineMail
    },
    {
        index: 6,
        value: BsAlarm
    },
    {
        index: 7,
        value: BsArrowThroughHeart
    },
    {
        index: 8,
        value: BsBagHeartFill
    },
    {
        index: 9,
        value: BsBell
    },
    {
        index: 10,
        value: BsBookmarkHeart
    },
    {
        index: 11,
        value: BsBookmarkStar
    },
    {
        index: 12,
        value: BsBox2Heart
    },
    {
        index: 13,
        value: BsCake
    },
    {
        index: 14,
        value: BsCake2
    },
    {
        index: 15,
        value: BsCalendar2Check
    },
    {
        index: 16,
        value: BsCalendar2Heart
    },
    {
        index: 17,
        value: BsCalendarHeart
    },
    {
        index: 18,
        value: BsChatDots
    },
    {
        index: 19,
        value: BsChatRightDots
    },
    {
        index: 20,
        value: BsChatRightHeart
    },
    {
        index: 21,
        value: BsChatSquareDots
    },
    {
        index: 22,
        value: BsChatSquareHeart
    },
    {
        index: 23,
        value: BsClipboard2Heart
    },
    {
        index: 24,
        value: BsClipboardCheck
    },
    {
        index: 25,
        value: BsClock
    },
    {
        index: 26,
        value: BsCloudMoon
    },
    {
        index: 27,
        value: BsDice5
    },
    {
        index: 28,
        value: BsEmojiLaughing
    },
    {
        index: 29,
        value: BsEnvelope
    },
    {
        index: 30,
        value: BsEnvelopePaperHeart
    },
    {
        index: 31,
        value: BsFillBalloonHeartFill
    },
    {
        index: 32,
        value: BsFillBalloonFill
    },
    {
        index: 33,
        value: BsFillBagHeartFill
    },
    {
        index: 34,
        value: BsFillBrightnessHighFill
    },
    {
        index: 35,
        value: BsGift
    },
    {
        index: 36,
        value: BsHeart
    },
    {
        index: 37,
        value: BsHearts
    },
    {
        index: 38,
        value: BsHouse
    },
    {
        index: 39,
        value: BsMegaphone
    },
    {
        index: 40,
        value: BsPalette
    },
    {
        index: 41,
        value: BsPatchExclamation
    },
    {
        index: 42,
        value: BsPostcardHeart
    },
    {
        index: 43,
        value: BsPostcard
    },
    {
        index: 44,
        value: BsSignpost
    },
    {
        index: 45,
        value: BsSignpost2
    },
    {
        index: 46,
        value: BsSuitHeart
    },
    {
        index: 47,
        value: BsTrainFront
    },
    {
        index: 48,
        value: BsTrash3
    },
    {
        index: 49,
        value: BsTree
    },
    {
        index: 50,
        value: BsTrophy
    },
    {
        index: 51,
        value: BsUmbrella
    },
    {
        index: 52,
        value: FaFeather
    },
    {
        index: 53,
        value: FaBeer
    },
    {
        index: 54,
        value: BiChurch
    },
    {
        index: 55,
        value: LuChurch
    },
    {
        index: 56,
        value: MdDinnerDining
    },
    {
        index: 57,
        value: MdOutlineFoodBank
    },
    {
        index: 58,
        value: IoFastFoodOutline
    },

]

