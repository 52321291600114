import React, { useContext, useEffect, useRef } from 'react'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador, SmallSeparador } from '../../components/invitation/Logos'
import '../../styles/modules/notices.css'


export const Notices = ({ content, noticesRef, dev }) => {

    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const noticesRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='module-main-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                    }}>
                        <div ref={noticesRef} id='notices' className="notices-container">

                            <h2 className={dev ? "notices-title-dev" : "notices-title"} style={{ fontFamily: font, color: colorPalette.accent }}>
                                AVISOS
                            </h2>

                            {content.notices && content.notices.map((item, index) => (
                                <div key={index} className="notices-item">
                                    <p
                                        className={dev ? "notices-text-dev" : "notices-text"}
                                        style={{
                                            margin: `50px 0 ${index < content.notices.length - 1 ? '50px' : '0'} 0`,
                                            color: colorPalette.accent,
                                            fontFamily: font,
                                        }}
                                    >
                                        {item}
                                    </p>

                                    {index < content.notices.length - 1 && (
                                        <p className={dev ? "notices-separator-dots-dev" : "notices-separator-dots"} style={{ fontFamily: font, color: colorPalette.accent }}>
                                            ...
                                        </p>
                                    )}
                                </div>
                            ))}
                        </div>



                    </div>
                    {content.separator ? (
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    ) : (
                        <div className="notices-separator" />
                    )}
                </>

            ) : null}
        </>
    )
}
