import { Button, Drawer, Row, message, Input, Radio, Switch, Slider } from 'antd'
import html2canvas from 'html2canvas';
import { useEffect, useRef, useState } from 'react';
import { FiCopy, IoArrowDownOutline, IoMdArrowDropdown, MdEdit, PiShareFat, PiShareFatFill, darker, formatDate, lighter, load } from '../../helpers';
import { QRCodeCanvas } from 'qrcode.react';


const baseProd = "https://www.iattend.mx"


export const QRHandler = ({ visible, setVisible, content, generals, label, inv }) => {


    const [loader, setLoader] = useState(false)
    const [backgroundImage, setBackgroundImage] = useState(null)
    const sectionRef = useRef(null);
    const [onEditCard, setOnEditCard] = useState(false)
    const [onShareCard, setOnShareCard] = useState(false)
    const [greetingText, setGreetingText] = useState(true)
    const [bgImage, setBgImage] = useState(false)
    const [QRHeight, setQRHeight] = useState(120)



    const copyToClipboard = async (textToCopy) => {
        try {
            await navigator.clipboard.writeText(textToCopy);
            message.success('Link copiado')
        } catch (err) {
            console.error('Error al copiar el texto: ', err);
        }
    };

    const takeScreenshot = async () => {
        if (sectionRef.current) {
            try {
                const canvas = await html2canvas(sectionRef.current, {
                    useCORS: true, // Habilita CORS
                    allowTaint: false, // Evita problemas con imágenes externas
                    logging: true, // Para depuración
                    scale: 2, // Aumenta la resolución del canvas
                });
                const dataUrl = canvas.toDataURL("image/png");

                // Descargar la imagen como archivo PNG
                const link = document.createElement("a");
                link.href = dataUrl;
                link.download = `${generals.eventName}.png`;
                link.click();
            } catch (error) {
                console.error("Error capturando el screenshot:", error);
            }
        }
    };

    // Función para copiar la imagen al portapapeles
    const copyImageToClipboard = async () => {
        if (sectionRef.current) {
            message.info('Procesando ...')
            try {
                const canvas = await html2canvas(sectionRef.current, {
                    useCORS: true, // Habilita CORS
                    allowTaint: false, // Evita problemas con imágenes externas
                    logging: true, // Para depuración
                    scale: 2, // Aumenta la resolución del canvas
                });
                const blob = await new Promise((resolve) => canvas.toBlob(resolve));
                await navigator.clipboard.write([
                    new ClipboardItem({ "image/png": blob }),
                ]);
                message.success('Imagen copiada')
            } catch (error) {
                console.error("Error copiando al portapapeles:", error);
            }
        }
    };

    const convertToBase64 = async (imageUrl) => {
        return new Promise((resolve, reject) => {
            const img = new Image();
            img.crossOrigin = "anonymous";
            img.src = imageUrl;

            img.onload = () => {
                const canvas = document.createElement("canvas");
                canvas.width = img.width;
                canvas.height = img.height;
                const ctx = canvas.getContext("2d");
                ctx.drawImage(img, 0, 0);
                resolve(canvas.toDataURL("image/png"));
            };

            img.onerror = (error) => reject(error);
        });
    };

    useEffect(() => {
        const loadImage = async (imageUrl) => {

            try {
                setLoader(true)
                const base64 = await convertToBase64(imageUrl);
                setBackgroundImage(base64);
                setLoader(false)
            } catch (error) {
                console.error("Error convirtiendo la imagen a Base64:", error);
            }
        };

        if (content) {
            if (content.featured_prod) {
                loadImage(content.featured_prod);
            }

        }
        setOnEditCard(false)
    }, [visible])

    useEffect(() => {
        if (content) {
            if (content.featured_prod) {
                setBgImage(true)
            }
        }

    }, [content])

    const onShare = () => {
        setOnShareCard(!onShareCard)
        setOnEditCard(false)
    }

    const onEdit = () => {
        setOnEditCard(!onEditCard)
        setOnShareCard(false)

    }



    return (
        <Drawer
            // title="Basic Drawer"
            placement="right"
            className='help-drawer'
            closable={false}
            onClose={() => setVisible(false)}
            open={visible}
            width={'450px'}
            style={{
                zIndex: 99,
                // minWidth: '650px',
                // maxWidth: '650px',
                display: 'flex', alignItems: 'center', justifyContent: 'center',
                // border: '1px solid'
                // backgroundColor: '#fdc564'
                // background: 'linear-gradient(132deg, #62B7F9, #C1A2F7, #EA4B73, #ECAE4D)'
            }}

        >

            {
                !loader ?

                    <div className='qr-drawer-container'>
                        <div style={{
                            // boxShadow: '0px 0px 12px rgba(0,0,0,0.15)',
                            position: 'relative', overflow: 'hidden',
                            borderRadius: '44px'
                        }}
                        // className='glowing-card'
                        >
                            <div className="qr-card-container " style={{
                                height: bgImage ? '750px' : '570px', transition: 'all 0.3s ease'
                            }}>
                                <div className='module-cover-container' style={{
                                    position: 'relative', height: '100%',

                                }}>

                                    <div ref={sectionRef}
                                        className={'cover-container'}
                                        style={{
                                            height: '100%', margin: '0px',
                                            position: 'relative',
                                            borderRadius: '0px',

                                        }}>

                                        {
                                            !content.background && bgImage &&
                                            <div
                                                className="image-card-qr"
                                                style={{
                                                    backgroundImage: `url(${backgroundImage})`,
                                                    backgroundSize: "cover",
                                                    backgroundPosition: "center",
                                                    borderRadius: '0px'
                                                }}
                                            ></div>
                                        }

                                        <div className='qr-background-cover'
                                            style={{
                                                background:
                                                    bgImage ? !content.background
                                                        ? `linear-gradient(to top, ${generals.theme ? darker(generals.palette.primary, 0.2) : darker(generals.palette.primary, 0.2)}, rgba(0,0,0,0))`
                                                        : content.background
                                                        : content.background ? content.background
                                                            : `linear-gradient(to bottom, ${darker(generals.palette.secondary, 0.9)}, ${generals.palette.accent})`,
                                                borderRadius: '0px'

                                            }}>



                                            <div className='cover--title-container' style={{
                                                alignItems: content.align,
                                                marginTop: '20px',
                                                flex: 1,
                                                // maxheight: '50%',
                                                // height: '325px',
                                                // paddingTop: '30px',
                                                width: '90%',



                                            }}>
                                                <h1 style={{
                                                    color: !content.color ? generals.theme ? lighter(generals.palette.primary, 0.6) : lighter(generals.palette.accent, 0.6) : content.color, width: '100%',
                                                    textAlign: content.justify, fontSize: `${content.fontSize}em`, wordBreak: 'break-word',
                                                    opacity: content.opacity,
                                                    fontFamily: content.image,
                                                    fontWeight: content.fontWeight,
                                                    lineHeight: '0.9',

                                                }}>{content.title}</h1>
                                            </div>

                                            {
                                                greetingText && (
                                                    <p style={{
                                                        color: !content.color ? generals.theme ? lighter(generals.palette.primary, 0.6) : lighter(generals.palette.accent, 0.6) : content.color,
                                                        width: '90%',
                                                        textAlign: 'center', fontSize: `26px`, wordBreak: 'break-word',
                                                        opacity: content.opacity, fontFamily: content.image,
                                                        lineHeight: '1.1',
                                                        fontWeight: 600,
                                                        marginTop: '20px',
                                                        opacity: '0.8',


                                                    }}>
                                                        {inv.greeting.title}
                                                    </p>
                                                )
                                            }



                                            <p style={{
                                                color: !content.color ? generals.theme ? lighter(generals.palette.primary, 0.6) : lighter(generals.palette.accent, 0.6) : content.color, width: '100%',
                                                textAlign: 'center', fontSize: `18px`, wordBreak: 'break-word',
                                                opacity: content.opacity, fontFamily: content.image,
                                                lineHeight: '0.8',
                                                margin: '20px 0px',
                                                opacity: '0.8',
                                                marginTop: greetingText ? '10px' : '20px',

                                            }}>
                                                {formatDate(content.date)}
                                            </p>



                                            <div style={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column',
                                                overflow: 'hidden',
                                                // height: '210px', minHeight: '210px',
                                                opacity: '0.8',

                                                marginBottom: '30px'
                                            }}>


                                                <QRCodeCanvas

                                                    bgColor={'transparent'} fgColor={!content.color ? generals.theme ? lighter(generals.palette.primary, 0.6) : lighter(generals.palette.accent, 0.6) : content.color}
                                                    value={`${baseProd}/${label}/${generals.eventName}`}
                                                    size={QRHeight} />


                                            </div>



                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className='qr-bright-shadow'>
                                <Button onClick={onEdit}
                                    id={onEditCard ? "qr-handler-edit-button-active" : "qr-handler-edit-button"}
                                    icon={<MdEdit size={20} />} />

                                {
                                    onEditCard && (
                                        <div className='qr-edit-card'>
                                            {
                                                !content.background && (
                                                    <Row style={{
                                                        marginBottom: '10px',
                                                        width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                                    }}>
                                                        <span className='qr-label' style={{
                                                            textAlign: 'left'
                                                        }}>Imagen de Fondo</span>
                                                        <Switch
                                                            style={{
                                                                marginLeft: '10px'
                                                            }}
                                                            size='small'
                                                            checked={bgImage}
                                                            onChange={(e) => setBgImage(e)}
                                                        />
                                                    </Row>
                                                )
                                            }

                                            <Row style={{

                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between',
                                                marginBottom: '10px',
                                            }}>
                                                <span className='qr-label' style={{
                                                    textAlign: 'left'
                                                }}>Mensaje de Bienvenida</span>
                                                <Switch
                                                    style={{
                                                        marginLeft: '10px'
                                                    }}
                                                    size='small'
                                                    checked={greetingText}
                                                    onChange={(e) => setGreetingText(e)}
                                                />
                                            </Row>

                                            <Row style={{

                                                width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'space-between'
                                            }}>
                                                <span className='qr-label' style={{
                                                    textAlign: 'left'
                                                }}>Tamaño del QR</span>
                                                <Slider
                                                    style={{ width: '50%' }}
                                                    // trackStyle={"#000"}
                                                    // handleStyle={{ borderColor: 'red' }}
                                                    min={50}
                                                    max={200}
                                                    step={10}
                                                    onChange={(e) => setQRHeight(e)}
                                                    value={QRHeight}
                                                />
                                            </Row>


                                        </div>
                                    )
                                }

                                <Button onClick={onShare}
                                    id={onShareCard ? "qr-handler-share-button-active" : "qr-handler-share-button"}
                                    icon={<PiShareFatFill size={20} />}>
                                    Compartir Tarjeta
                                </Button>

                                {
                                    onShareCard && (
                                        <div className='qr-edit-card'>
                                            <Row style={{
                                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                                width: '100%', padding: '5px',
                                            }}>
                                                <span className='qr-label'>Tarjeta de Invitación</span>
                                                <Row style={{
                                                    width: '100%', marginTop: '5px',
                                                    display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'column'
                                                }}>
                                                    <Button
                                                        icon={<FiCopy />}
                                                        style={{ width: '100%', marginBottom: '5px', }}
                                                        id="qr-action-button" onClick={copyImageToClipboard}>Copiar Imagen</Button>
                                                    <Button
                                                        icon={<IoArrowDownOutline />}
                                                        style={{ width: '100%' }}
                                                        id="qr-action-button" onClick={takeScreenshot}>Descargar Imagen</Button>
                                                </Row>

                                            </Row>

                                            <Row style={{
                                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                                width: '100%', padding: '5px'
                                            }}>
                                                <span className='qr-label'>Link de la invitación</span>
                                                <Row
                                                    style={{
                                                        width: '100%', marginTop: '5px'
                                                    }}
                                                >
                                                    <Input
                                                        id="qr-action-button"
                                                        style={{
                                                            flex: 3, marginRight: '5px', backgroundColor: '#FFFFFF50',
                                                        }} value={`${baseProd}/${label}/${generals.eventName}`}></Input>
                                                    <Button
                                                        onClick={() => copyToClipboard(`${baseProd}/${label}/${generals.eventName}`)}
                                                        id="qr-action-button-primary">Copiar Link</Button>
                                                </Row>


                                            </Row>
                                        </div>
                                    )
                                }

                            </div>


                        </div>


                        {/* <div
                            className='qr-action-btns-container'>

                            <Row style={{
                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                width: '100%', padding: '5px',
                            }}>
                                <span className='qr-label'>Tarjeta de Invitación</span>
                                <Row style={{
                                    width: '100%', marginTop: '5px'
                                }}>
                                    <Button
                                        icon={<FiCopy />}
                                        id="qr-action-button" onClick={copyImageToClipboard}>Copiar Imagen</Button>
                                    <Button
                                        icon={<IoArrowDownOutline />}
                                        id="qr-action-button" onClick={takeScreenshot}>Descargar Imagen</Button>
                                </Row>

                            </Row>

                            <Row style={{
                                display: 'flex', alignItems: 'flex-start', justifyContent: 'flex-start', flexDirection: 'column',
                                width: '100%', padding: '5px'
                            }}>
                                <span className='qr-label'>Link de la invitación</span>
                                <Row
                                    style={{
                                        width: '100%', marginTop: '5px'
                                    }}
                                >
                                    <Input
                                        id="qr-action-button"
                                        style={{
                                            flex: 3, marginRight: '5px', backgroundColor: '#FFFFFF50',
                                        }} value={`${baseProd}/${label}/${generals.eventName}`}></Input>
                                    <Button
                                        onClick={() => copyToClipboard(`${baseProd}/${label}/${generals.eventName}`)}
                                        id="qr-action-button-primary">Copiar Link</Button>
                                </Row>


                            </Row>

                        </div> */}
                    </div>

                    : <div style={{
                        height: '100vh', display: 'flex', alignItems: 'center',
                        justifyContent: 'center', flexDirection: 'column',
                        position: 'relative'
                        // backgroundColor: lighter(MainColor, 0.9)
                    }}>
                        <img src={load} style={{
                            // width: '10%'
                        }} />
                        <span style={{

                        }} className='qr-label' >Por favor espera</span>
                    </div>
            }

        </Drawer>
    )
}




