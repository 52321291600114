
import { Footer } from 'antd/es/layout/layout';
import { FaArrowUp, FaArrowLeft, IoArrowUndo } from "../../helpers";
import { Button, Popconfirm } from 'antd';
import { RiMenu5Fill } from "react-icons/ri";
import { Link } from 'react-router-dom';
import { CalendarLogo } from '../../components/Logo';

import { storage } from '../../firebase';
import { ref, uploadBytesResumable, getDownloadURL, deleteObject } from 'firebase/storage';

export const FooterBuild = ({
    invitation, buttons, currentSection, handleClick, setMode, onSaveChanges, saved, onTry, buttonsTry,
    position
}) => {

    const handleActions = (item) => {
        handleClick(item);
    }

    const handleMoode = () => {
        setMode('my-invitations')
    }


    const moodeAndDelete = async () => {
        setMode('my-invitations')

        await deleteIfDifferent(invitation.cover?.featured_prod, invitation.cover?.featured_dev, deleteImageFB);

        if (invitation.gallery?.gallery_dev) {
            await Promise.all(invitation.gallery.gallery_dev.map(dev =>
                deleteIfDifferent(invitation.gallery.gallery_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

        if (invitation.dresscode?.images_dev) {
            await Promise.all(invitation.dresscode.images_dev.map(dev =>
                deleteIfDifferent(invitation.dresscode.images_prod?.includes(dev) ? dev : null, dev, deleteImageFB)
            ));
        }

    }

    const deleteIfDifferent = async (prod, dev, deleteFn) => {
        if (dev && dev !== prod) {
            await deleteFn(dev);
        }
    };

    const deleteImageFB = async (url) => {
        const filePath = extractFilePathFromURL(url)
        console.log(filePath)

        const imageRef = ref(storage, filePath);

        try {
            await deleteObject(imageRef);
        } catch (error) {
            console.error('Error deleting image:', error);
        }
    };

    const extractFilePathFromURL = (url) => {
        const regex = /https:\/\/firebasestorage\.googleapis\.com\/v0\/b\/([^/]+)\/o\/(.+)\?alt=media&token=.+/;
        const match = url.match(regex);
        if (!match) {
            throw new Error('URL does not match Firebase Storage base URL.');
        }
        const filePath = decodeURIComponent(match[2]);
        return filePath;
    };



    return (
        <Footer className={onTry ? 'tools-main-container-onTry' : 'tools-main-container'} style={{
            background: 'transparent',
        }}>

            {/* <CalendarLogo date={new Date()} /> */}

            <div className={onTry ? 'tools-container-onTry' : 'tools-container'}>

                {
                    onTry ?
                        buttonsTry.map((item, index) => (
                            <div className='single-button-tag-container'>
                                <a
                                    className={`single-button${position === index ? '--selected' : ''} tag-button-tools`}
                                    key={index}
                                    onClick={() => handleActions(item)} >
                                    {item.icon}
                                </a>
                                {/* <div className='single-button-name-tag'>{item.name}</div> */}

                            </div>
                        ))
                        :
                        buttons.map((item, index) => (
                            <div className='single-button-tag-container'>
                                <a
                                    className={`single-button${currentSection === index + 1 ? '--selected' : ''} tag-button-tools`}
                                    key={index}
                                    onClick={() => handleActions(item)} >
                                    {item.icon}
                                </a>
                                <div className='single-button-name-tag'>{item.name}</div>

                            </div>
                        ))
                }

            </div>

            {/* 
            {
                !saved
                    ?
                    <Popconfirm
                        title="Salir sin Guardar"
                        description="Tienes cambios sin guardar. Si sales ahora, todos los cambios se perderán. ¿Estás seguro de que deseas continuar?"
                        onConfirm={moodeAndDelete}
                        placement="topLeft"
                        okText="Salir"
                        cancelText="Cancelar"
                        style={{ width: '400px' }}
                        id="popup-confirm"
                    >
                        <Button
                            id='cta-upload-button'
                            className='cta-upload-button-secondary '>
                            <IoArrowUndo size={20} />
                        </Button>
                    </Popconfirm>
                    :
                    <Button
                        onClick={handleMoode}
                        id='cta-upload-button'
                        className='cta-upload-button-secondary '>
                        <IoArrowUndo size={20} />
                    </Button>
            } */}




            {/* <Button
                onClick={onSaveChanges}
                id='cta-upload-button'
                style={{ position: 'relative', }}
                // style={{
                //     height
                // }}
                className='cta-upload-button'>
                <FaArrowUp size={20} />

                {
                    !saved && (
                        <div style={{
                            position: 'absolute', top: 0, right: -4,
                            height: '20px', width: '20px', borderRadius: '50px',
                            backgroundColor: '#FFF', display: 'flex', alignItems: 'center', justifyContent: 'center'
                        }} >
                            <div style={{

                                height: '10px', width: '10px', borderRadius: '50px',
                                backgroundColor: '#FF0033'
                            }}>

                            </div>
                        </div>
                    )
                }

            </Button> */}



        </Footer>

    )
}
