import React, { useContext, useEffect, useRef } from 'react'
import { Col } from 'antd'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/personas.css'


export const Family = ({ content, familyRef, dev }) => {
    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const familyRef = useRef(null)


    return (
        <>
            {content.active && colorPalette ? (

                <>
                    <div className='module-main-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                    }}>
                        <div
                            ref={familyRef}
                            id="family"
                            className="personas-container"
                        >
                            <div
                                className="personas-inner-container"

                            >
                                <h2
                                    className={dev ? "personas-title-dev" : "personas-title"}
                                    style={{ color: colorPalette.accent, fontFamily: font }}
                                >
                                    {content.title}
                                </h2>

                                {content.personas ? (
                                    content.personas.map((persona, index) => (
                                        <Col
                                            key={index}
                                            className="personas-col"
                                        >
                                            <p
                                                className={dev ? "personas-role-dev" : "personas-role"}
                                                style={{
                                                    fontFamily: font,
                                                    color: `${colorPalette.accent}99`
                                                }}
                                            >
                                                {persona.title}
                                            </p>

                                            <p
                                                className={dev ? "personas-name-dev" : "personas-name"}
                                                style={{
                                                    fontFamily: font,
                                                    color: colorPalette.accent
                                                }}
                                            >
                                                {persona.name}
                                            </p>
                                        </Col>
                                    ))
                                ) : null}
                            </div>


                        </div>
                    </div>

                    {content.separator ? (
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    ) : (
                        <div className="personas-separator" />
                    )}
                </>

            ) : null}
        </>
    )
}

