import { Layout, Modal } from 'antd'
import React, { useContext, useEffect, useState } from 'react'
import { HeaderBuild } from '../layouts/Build'
import { ContentLandPage } from '../layouts/land-page/ContentLandPage'
import { appContext } from '../context'
import justWeb from '../assets/just_web.svg'
import { renew_jwt } from '../services/apiLogin'
import { useInvitation } from '../hooks'
import { FooterApp } from '../layouts/FooterApp'
import { Login } from '../components/land-page/Login'

export const LandPage = () => {

    const { response, operation } = useInvitation()
    const { logout, logged } = useContext(appContext)
    const [openLogin, setOpenLogin] = useState(false)

    useEffect(() => {
        if (logged) {
            renew_jwt(logout, operation)
        }

    }, [])

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Token renewed":
                        localStorage.setItem("token", response.data.token)
                        break;
                    default:
                        break;
                }

            }
        }
    }, [response])



    return (
        <>


            <Layout
                className='responsive-web'
                style={{
                    position: 'relative',
                    alignItems: 'center', justifyContent: 'center'
                }}>
                <HeaderBuild position={'land-page'} setOpenLogin={setOpenLogin} />
                <ContentLandPage />
                <FooterApp />

                <Modal
                    open={openLogin}
                    onClose={() => setOpenLogin(false)}
                    onCancel={() => setOpenLogin(false)}
                    footer={null} // Opcional: Elimina el footer del modal si no es necesario
                    style={{
                        top: 40,
                        display: 'flex',
                        alignItems: 'center', justifyContent: 'center'
                    }}>
                    <Login position={'land-page'} setOpenLogin={setOpenLogin} />

                </Modal>

            </Layout >


            <Layout
                className='responsive-mobile'
            >

                <img src={justWeb} style={{
                    width: '100%', objectFit: 'cover', height: '100%'
                }} />

            </Layout >


        </>
    )
}
