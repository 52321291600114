import React, { useContext, useEffect, useRef } from 'react'
import { darker, lighter } from '../../helpers/functions'
import { appContext } from '../../context/AuthContext'
import { Separador } from '../../components/invitation/Logos'
import '../../styles/modules/quote.css'


export const Quote = ({ content, quoteRef, dev }) => {


    const { MainColor, theme, font, colorPalette } = useContext(appContext)
    // const quoteRef = useRef(null)

    const maxLength = 90; // Longitud máxima del borderRadius
    const length = content.description.length; // Longitud actual del texto

    // Calcular el valor del borderRadius en función de la longitud del texto
    const borderRadius = length <= maxLength ? '50%' : `${Math.max(11, maxLength - length)}px`;




    return (
        <>
            {content.active && colorPalette ? (
                <>
                    <div className='module-main-container' style={{
                        backgroundColor: content.background ? colorPalette.secondary : 'transparent',
                    }}>
                        <div
                            ref={quoteRef}
                            id="quote"
                            className="quote-container"
                        >
                            <div
                                className={dev ? "quote-inner-container-dev" : "quote-inner-container"}
                                style={{
                                    width: content.background ? '100%' : '65%',
                                    borderRadius: content.background ? '0px' : borderRadius,
                                    backgroundColor: colorPalette.secondary
                                }}
                            >
                                <p
                                    className={dev ? "quote-description-dev" : "quote-description"}
                                    style={{
                                        color: colorPalette.accent,
                                        fontFamily: font,
                                    }}
                                >
                                    {content.description}
                                </p>
                            </div>


                        </div>

                    </div>

                    {content.separator ? (
                        <Separador MainColor={colorPalette.accent} theme={theme} />
                    ) : (
                        <div className="quote-separator" />
                    )}
                </>
            ) : null}
        </>
    );
}
