
export const types = {
    LOGIN: '[Auth] Login',
    LOGOUT: '[Auth] Logout',
    COLOR: '[App] Color',
    PALETTE: '[App] Palette',
    THEME: '[App] theme',
    FONT: '[App] fotns',
    COVER: '[App] cover',
    DATE: '[App] onDate',
    DESIGN: '[App] onDesign',

}