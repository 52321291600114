export const fonts = [
    "Anton SC",
    "Comfortaa",
    "Dancing Script",
    "DM Serif Display",
    "Georgia",
    "Kaisei Opti",
    "Libre Baskerville",
    "Libre Franklin",
    "Lilita One",
    "Mulish",
    "Noto Sans",
    "Open Sans",
    "Outfit",
    "Platypi",
    "Playfair Display",
    // "Playwrite Cuba",
    // "Playwrite Hrvatska",
    // "Playwrite Magyarország",
    // "Playwrite South Africa",
    "Poppins",
    "Quicksand",
    "Roboto",
    "Sedan",
    "Signika",
    "Work Sans"
]
