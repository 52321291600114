import React, { useContext } from 'react'
import { Navigate } from 'react-router-dom'
import { appContext } from '../context'

export const AdminHOC = ({ children }) => {

    const { user, logged } = useContext(appContext)

    if (logged) {
        if (user) {
            return (user.role === 'Admin')
                ? children
                : <Navigate to="/" />
        } else {
            return <Navigate to="/" />
        }
    } else {
        return <Navigate to="/" />
    }


}