import React from 'react'

export const CustomButton = ({ icon, text, style, onClick, styleText, secondary }) => {

    return (



        <div className='custom-button-container' style={style} onClick={onClick}>
            <div className={`custom-button-icon-container ${secondary ? 'custom-button-secondary' : ''}`}>
                {icon}
            </div>
            <span className={`custom-button-text ${secondary ? 'custom-button-text-secondary' : ''}`} style={styleText}>{text}</span>
        </div>
    )
}
