export const app_features = [
    {
        type: 'Diseño Personalizado',
        bold: 'Cambios ilimitados',
        text: 'Disfruta de cambios ilimitados sin restricciones.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Edita cuando quieras',
        text: 'Realiza ediciones en cualquier momento.',
        img: null
    },

    {
        type: 'Diseño Personalizado',
        bold: 'Diseña tu invitación',
        text: 'Personaliza cada aspecto de tu invitación digital.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Elige tus colores favoritos',
        text: 'Selecciona la paleta de colores que más te guste',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Selecciona tu tipografía',
        text: 'Escoge entre una variedad de tipografías para tu invitación',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Orden de tu preferencia',
        text: 'Organiza los elementos de tu invitación a tu gusto',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Oculta módulos innecesarios',
        text: 'Muestra solo los módulos que te interesan.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Portada personalizada',
        text: 'Diseña una portada única para tu invitación.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Mensaje de bienvenida',
        text: 'Añade un mensaje de bienvenida personalizado.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Personas importantes',
        text: 'Destaca a las personas clave del evento.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Cita especial',
        text: 'Incluye una cita significativa o inspiradora.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Itinerario personalizado',
        text: 'Crea un itinerario detallado para tu evento.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Mapas del lugar',
        text: 'Integra mapas para facilitar la ubicación del evento.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Botones de cómo llegar',
        text: 'Añade botones para obtener direcciones fácilmente.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Clima del lugar',
        text: 'Muestra el pronóstico del clima para el día del evento.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Comparte música',
        text: 'Enlaza playlists o álbumes de Spotify.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Paleta de colores para dress code',
        text: 'Proporciona una guía de colores para el código de vestimenta.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Imágenes de referencia para dress code',
        text: 'Añade hasta 5 imágenes como referencia de vestimenta.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Accesos directos a inspiración',
        text: 'Enlaces directos a Pinterest, Instagram, YouTube y Google para ideas de dress code.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Tarjetas de regalo personalizables',
        text: 'Crea y comparte tarjetas de regalo.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Sección de avisos',
        text: 'Publica avisos importantes relacionados con el evento.',
        img: null
    },
    {
        type: 'Diseño Personalizado',
        bold: 'Galería de imágenes',
        text: 'Comparte una galería de hasta 10 imágenes.',
        img: null
    },

    {
        type: 'Gestión y Control',
        bold: 'Comparte tu invitación',
        text: 'Facilita la distribución de tu invitación digital.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Gestión de invitados',
        text: 'Agrega, edita o elimina invitados fácilmente.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Control de visibilidad',
        text: 'Decide quién puede ver tu invitación.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Confirmaciones en tiempo real',
        text: 'Recibe confirmaciones de asistencia al instante.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Administración de pases',
        text: 'Controla el número de pases e invitados.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Colaboración en equipo',
        text: 'Comparte el panel de invitados con tu equipo.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Privacidad del evento',
        text: 'Protege tu evento con códigos de seguridad.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Invitaciones públicas o privadas',
        text: 'Escoge si tus invitaciones son públicas o privadas.',
        img: null
    },
    {
        type: 'Gestión y Control',
        bold: 'Confirmación de asistencia',
        text: 'Permite que los invitados confirmen su asistencia directamente desde la invitación.',
        img: null
    }
];


export const all_features = [
    {
        type: 'Configuraciones generales',
        bold: 'Cambios ilimitados',
        text: 'Disfruta de cambios ilimitados sin restricciones.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Edita cuando quieras',
        text: 'Realiza ediciones en cualquier momento.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Elige el tipo de letra',
        text: 'Selecciona la tipografía que mejor refleje la esencia de tu evento.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Cambia el color de la invitación',
        text: 'Ajusta los colores de tu invitación para que se adapten perfectamente al tema de tu evento.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Escoge la paleta de colores que se ajuste a tu evento',
        text: 'Selecciona una paleta de colores personalizada para que tu invitación destaque.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Escoge una paleta de colores automáticos',
        text: 'Deja que el algortimo elija una paleta de colores automática para que todo luzca perfecto.',
        img: null
    },
    {
        type: 'Configuraciones generales',
        bold: 'Cambia el orden de la invitación',
        text: 'Reorganiza los módulos de la invitación para que se presenten en el orden que prefieras.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Personaliza el título a tu gusto',
        text: 'Ajusta el título según tu preferencia en cualquier momento.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Organiza y acomoda los elementos',
        text: 'Coloca los elementos donde más te convengan, creando una invitación única y personalizada.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Actualiza la foto de portada fácilmente',
        text: 'Cambia la imagen de portada con solo unos clics, adaptándola a la temática de tu evento.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Elige fondos a tu medida',
        text: 'Opta por colores sólidos o gradientes de fondo que se adapten perfectamente al estilo de tu invitación.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Selecciona la tipografía ideal',
        text: 'Escoge el tipo de letra que mejor se ajuste a la personalidad de tu evento.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Ajusta los detalles del texto',
        text: 'Modifica el tamaño, color, opacidad y grosor de los textos para darle el toque perfecto a tu invitación.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Reubica la fecha según tus necesidades',
        text: 'Cambia la posición de la fecha en la invitación para que se integre de manera armónica en tu diseño.',
        img: null
    },
    {
        type: 'Portada',
        bold: 'Oculta la fecha si lo prefieres',
        text: 'Si lo deseas, tienes la opción de ocultar la fecha para mantener un diseño más limpio o para eventos sin fecha específica.',
        img: null
    },
    {
        type: 'Mensajes',
        bold: 'Escribe un mensaje de bienvenida para tus invitados',
        text: 'Saluda a tus invitados con un mensaje personalizado que refleje la esencia de tu evento.',
        img: null
    },
    {
        type: 'Mensajes',
        bold: 'Destaca a las personas clave del evento',
        text: 'Reconoce a las personas importantes que harán de tu evento algo especial.',
        img: null
    },
    {
        type: 'Mensajes',
        bold: 'Incluye una cita significativa o inspiradora',
        text: 'Añade una cita que resuene con la ocasión y inspire a tus invitados.',
        img: null
    },
    {
        type: 'Mensajes',
        bold: 'Publica avisos importantes relacionados con el evento',
        text: 'Mantén a tus invitados informados con anuncios clave para el evento.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Agrega los momentos especiales del evento',
        text: 'Destaca los momentos más importantes y emocionantes de tu evento.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Agrega nombre, una hora y un lugar',
        text: 'Proporciona detalles clave como el nombre del evento, la hora y la ubicación.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Agrega ubicaciones, mapas y accesos a Google Maps',
        text: 'Facilita a tus invitados la llegada con mapas y enlaces directos a Google Maps.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Comparte el clima del lugar',
        text: 'Mantén a tus invitados informados sobre el clima para que se preparen adecuadamente.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Crea instancias, eventos importantes que ocurren dentro de tu momento',
        text: 'Divide el evento en instancias específicas, resaltando actividades clave.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Comparte el menú',
        text: 'Deleita a tus invitados compartiendo el menú que disfrutarán en el evento.',
        img: null
    },
    {
        type: 'Itinerario',
        bold: 'Comparte un álbum o una playlist desde Spotify',
        text: 'Ambientaliza el evento compartiendo una playlist o álbum especial desde Spotify.',
        img: null
    },
    {
        type: 'Dress code',
        bold: 'Comparte la descripción del código de vestimenta',
        text: 'Informa a tus invitados sobre el código de vestimenta adecuado para el evento.',
        img: null
    },
    {
        type: 'Dress code',
        bold: 'Selecciona una paleta de colores para compartir con tus invitados',
        text: 'Guía a tus invitados con una paleta de colores sugerida para el evento.',
        img: null
    },
    {
        type: 'Dress code',
        bold: 'Comparte fotografías de referencia',
        text: 'Proporciona ejemplos visuales para inspirar a tus invitados en su elección de vestimenta.',
        img: null
    },
    {
        type: 'Dress code',
        bold: 'Comparte accesos directos hacia Pinterest, Instagram, etc.',
        text: 'Ofrece inspiración adicional con enlaces a plataformas como Pinterest o Instagram.',
        img: null
    },
    {
        type: 'Mesa de regalos',
        bold: 'Comparte una descripción sobre los regalos del evento',
        text: 'Informa a tus invitados sobre las opciones de regalos o contribuciones para el evento.',
        img: null
    },
    {
        type: 'Mesa de regalos',
        bold: 'Agrega tarjetas de regalo',
        text: 'Facilita la opción de recibir tarjetas de regalo para que tus invitados contribuyan de manera conveniente.',
        img: null
    },
    {
        type: 'Mesa de regalos',
        bold: 'Agrega tarjetas de débito',
        text: 'Permite a tus invitados hacer aportaciones utilizando tarjetas de débito de manera segura y rápida.',
        img: null
    },
    {
        type: 'Galería',
        bold: 'Comparte hasta 10 imágenes relacionadas',
        text: 'Sube y comparte hasta 10 imágenes que capturen la esencia de tu evento.',
        img: null
    },
    {
        type: 'Galería',
        bold: 'Edítalas cuando quieras',
        text: 'Realiza cambios en las imágenes en cualquier momento para mantener tu galería actualizada.',
        img: null
    },
    {
        type: 'Galería',
        bold: 'Acomódalas como gustes',
        text: 'Organiza las imágenes en el orden que prefieras, creando una presentación visualmente atractiva.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Comparte tu invitación',
        text: 'Facilita la distribución de tu invitación digital con solo un clic.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Gestión de invitados',
        text: 'Agrega, edita o elimina invitados fácilmente desde tu panel de control.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Control de visibilidad',
        text: 'Decide quién puede ver tu invitación, asegurando la privacidad que desees.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Confirmaciones en tiempo real',
        text: 'Recibe confirmaciones de asistencia al instante para mantenerte al día.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Administración de pases',
        text: 'Controla el número de pases e invitados para tu evento de manera sencilla.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Colaboración en equipo',
        text: 'Comparte el panel de invitados con tu equipo para una gestión colaborativa.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Privacidad del evento',
        text: 'Protege tu evento con códigos de seguridad para garantizar que solo los invitados puedan acceder.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Invitaciones públicas o privadas',
        text: 'Escoge si tus invitaciones serán públicas o limitadas solo a una lista específica.',
        img: null
    },
    {
        type: 'Administración de invitados',
        bold: 'Confirmación de asistencia',
        text: 'Permite que los invitados confirmen su asistencia directamente desde la invitación, facilitando la organización del evento.',
        img: null
    }

]