import { Button, Layout, Modal, Popconfirm, Row } from 'antd'
import React, { StrictMode, useContext, useEffect, useState } from 'react'
import { HeaderBuild } from '../layouts/Build'
import { ContentInvitations } from '../layouts/invitations/ContentInvitations'
import { BuildCard } from './BuildCard'
import { appContext } from '../context'
import GuestsPage from './GuestsPage'
import { useInvitation } from '../hooks'
import { renew_jwt } from '../services/apiLogin'
import { FooterInvitation } from '../layouts/FooterInvitation'
import { FooterApp } from '../layouts/FooterApp'
import { IoArrowUndo } from '../helpers'
import { Login } from '../components/land-page/Login'
import { getInvitationbyID } from '../services'
import { QRHandler } from '../components/guests/QRHandler'

export const InvitationsPage = () => {

    const [mode, setMode] = useState('my-invitations')
    const [currentInvitation, setCurrentInvitation] = useState(null)
    const { logged, logout, setOnDesigning } = useContext(appContext)
    const { response, operation } = useInvitation()
    const [saved, setSaved] = useState(true)
    const [openLogin, setOpenLogin] = useState(false)
    const [content, setContent] = useState(null)
    const [generals, setGenerals] = useState(null)
    const [label, setLabel] = useState(null)
    const [onQR, setOnQR] = useState(false)
    const [invitation, setInvitation] = useState(null)
    const [visible, setVisible] = useState(false)


    useEffect(() => {
        if (logged) {
            renew_jwt(logout, operation)
        }
    }, [])

    useEffect(() => {
        if (response) {
            if (response.data.ok) {
                switch (response.data.msg) {
                    case "Token renewed":
                        localStorage.setItem("token", response.data.token)
                        break;

                    case "Get invitation By Id":
                        const inv = response.data.data
                        setContent(inv.cover)
                        setGenerals(inv.generals)
                        setLabel(inv.label)
                        setInvitation(inv)
                        setVisible(true)
                        setOnQR(false)
                        console.log('handle inv: ', inv)

                        break;

                    default:
                        break;
                }

            }
        }
    }, [response])

    useEffect(() => {
        if (mode === 'my-invitations' || mode === 'on-guests') {
            setSaved(true)
        }
    }, [mode])


    const handleQRO = (currentInvitation) => {
        getInvitationbyID(operation, currentInvitation)
    }

    useEffect(() => {
        if (onQR && currentInvitation) {
            getInvitationbyID(operation, currentInvitation)
        }
    }, [onQR])





    const handleModule = (type) => {
        switch (type) {
            case 'my-invitations':

                return <ContentInvitations setCurrentInvitation={setCurrentInvitation} setMode={setMode} mode={mode} saved={saved} setOpenLogin={setOpenLogin} setSaved={setSaved} handleQRO={handleQRO} />

            case 'on-edit':
                return currentInvitation ? <BuildCard mode={mode} setMode={setMode} invitationID={currentInvitation} saved={saved} setSaved={setSaved} setOnQR={setOnQR} /> : <></>

            case 'on-guests':
                return currentInvitation ? <GuestsPage mode={mode} setMode={setMode} invitationID={currentInvitation} shared={false} saved={saved} setOnQR={setOnQR} /> : <></>

            default:
                break;
        }
    }

    return (
        <>


            {
                handleModule(logged ? mode : 'my-invitations')
            }

            <Modal
                open={openLogin}
                onClose={() => setOpenLogin(false)}
                onCancel={() => setOpenLogin(false)}
                footer={null} // Opcional: Elimina el footer del modal si no es necesario
                style={{
                    top: 40,
                    display: 'flex',
                    alignItems: 'center', justifyContent: 'center'
                }}
            >
                <Login position={'land-page'} setOpenLogin={setOpenLogin} />

            </Modal>

            {
                content && (
                    <QRHandler visible={visible} setVisible={setVisible} content={content} generals={generals} label={label} inv={invitation} />
                )
            }







        </>
    )
}
