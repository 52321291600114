import React, { useContext, useEffect, useState, } from 'react'
import { Button, Layout } from 'antd';
import { Login } from '../../components/land-page/Login';
import { slide_images } from '../../helpers';
import { Link } from 'react-router-dom';
import { appContext } from '../../context';

const { Content } = Layout;

export const ContentLandPage = ({ }) => {



    const [current, setCurrent] = useState(0)
    const { login, logged, setOnDate } = useContext(appContext)

    useEffect(() => {
        setOnDate(new Date())
    }, [])


    return (

        <Content className='content-land-page'>

            <div className='land-image-container'>
                {/* <img src={slide_images[current]} /> */}

                <div className='land-background-container'>
                    <div className={'land-info-cta-container'}>
                        <h1 className='land--title '>Fusce volutpat id sapien et vulputate.</h1>
                        <h2 className='land--subtitle '>Vestibulum imperdiet orci non</h2>
                        <Link to="/invitations">
                            <Button id='land-cta-button'>Empieza a crear</Button>
                        </Link>

                    </div>
                    {/* <Login /> */}

                    {/* <div className='land-control-buttons'>

                        {
                            slide_images.map((button, index) => (
                                <Button onClick={() => setCurrent(index)} id={index === current ? "lande-slider-button--selected" : "lande-slider-button"} />
                            ))
                        }



                    </div> */}


                </div>


            </div>

            <div className='land-info-container'>
                <h2 className='land-info-label'>Tendencias en invitaciones</h2>
                <div className='trending-container'>
                    {
                        [1, 1, 1, 1].map((item) => (
                            <div className='trending-card'></div>
                        ))
                    }

                </div>
                <h2 className='land-info-label'>Aprovecha tus invitaciones al máximo</h2>
                <div className='trending-container'>
                    {
                        [1, 1, 1, 1, 1, 1, 1, 1].map((item) => (
                            <div className='featured-card'></div>
                        ))
                    }

                </div>
                <h2 className='land-info-label'>Explora todas las opciones de personalización</h2>
                <div className='trending-container'>
                    {
                        [1, 1, 1].map((item) => (
                            <div className='options-card'></div>
                        ))
                    }

                </div>

            </div>




        </Content>

    )
}
