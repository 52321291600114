export const greetingsAI = {
    weeding: {
        titles: [
            "NOS ENCANTARÍA CONTAR CONTIGO",
            "UN DÍA INOLVIDABLE NOS ESPERA",
            "TE INVITAMOS A CELEBRAR CON NOSOTROS",
            "COMPARTE NUESTRA ALEGRÍA",
            "SERÍA UN HONOR QUE NOS ACOMPAÑES",
            "QUEREMOS QUE FORMES PARTE DE NUESTRO DÍA",
            "HAZ ESTE DÍA AÚN MÁS ESPECIAL CON TU PRESENCIA",
            "LA CELEBRACIÓN NO SERÍA IGUAL SIN TI",
            "TE ESPERAMOS PARA CELEBRAR JUNTOS",
            "COMPARTAMOS ESTE MOMENTO ÚNICO"
        ],
        texts: [
            "Tu presencia hará que nuestra celebración sea aún más especial. Nos encantaría que nos acompañes en este día tan importante para nosotros.",
            "Esperamos que puedas ser parte de este día tan especial para nosotros y juntos celebremos este momento único.",
            "No hay mejor manera de celebrar nuestra unión que con tu compañía. Nos encantaría compartir esta alegría contigo.",
            "Queremos que seas parte de nuestra felicidad en este día tan especial. Tu presencia significaría mucho para nosotros.",
            "Este día no estaría completo sin ti. Esperamos que nos acompañes y seas testigo de nuestro amor.",
            "Tu presencia en este día especial nos llenaría de alegría y haría de nuestra celebración un momento inolvidable.",
            "Nada nos haría más felices que contar con tu compañía en este día tan importante para nosotros.",
            "Tu compañía es lo que hará que nuestro día sea aún más especial. Esperamos verte y celebrar juntos.",
            "Queremos compartir este día único contigo y celebrar juntos la unión de nuestro amor.",
            "Nuestra alegría sería aún mayor si nos acompañas en este día tan especial para nosotros."
        ]
    },
    xv: {
        titles: [
            "CELEBRA CON NOSOTROS ESTE DÍA ESPECIAL",
            "UN SUEÑO HECHO REALIDAD",
            "TUS SONRISAS SON LO QUE HACE ESPECIAL ESTE DÍA",
            "TE ESPERAMOS CON LOS BRAZOS ABIERTOS",
            "UNA FIESTA LLENA DE MAGIA TE ESPERA",
            "COMPARTE CON NOSOTROS ESTE MOMENTO ÚNICO",
            "SERÁ UN DÍA INOLVIDABLE",
            "QUEREMOS QUE FORMES PARTE DE ESTA CELEBRACIÓN",
            "TU PRESENCIA HARÁ QUE ESTE DÍA BRILLE AÚN MÁS",
            "TE INVITAMOS A VIVIR ESTE MOMENTO MÁGICO"
        ],
        texts: [
            "Nos encantaría que nos acompañaras a celebrar los XV años de una joven soñadora, tu presencia hará que este día sea inolvidable.",
            "Hoy es un día de sueños cumplidos y de alegría. Nos encantaría compartir este momento tan especial contigo.",
            "Tu presencia y tus sonrisas son lo que hará que esta celebración sea aún más mágica y especial.",
            "Te esperamos con los brazos abiertos para festejar juntos los XV años de un día lleno de alegría y magia.",
            "Ven y disfruta de una noche mágica en la celebración de los XV años de una joven llena de sueños.",
            "Queremos que estés presente en este día tan único para nosotros, tu presencia hará de esta celebración algo especial.",
            "Un día lleno de alegría y momentos especiales que nos gustaría compartir contigo. ¡No faltes!",
            "Tu compañía hará que esta celebración de XV años sea aún más especial, llena de momentos que quedarán para siempre en el corazón.",
            "Tu presencia iluminará nuestra celebración, y estamos ansiosos de que seas parte de este gran día.",
            "Te invitamos a vivir la magia de los XV años, un momento único que queremos compartir contigo."
        ]
    },
    bap: {
        titles: [
            "UNA BENDICIÓN QUE QUEREMOS COMPARTIR",
            "ACOMPAÑANOS EN ESTE DÍA TAN ESPECIAL",
            "TU PRESENCIA ES UN REGALO",
            "CELEBRA LA LLEGADA DE UNA NUEVA BENDICIÓN",
            "JUNTOS, CELEBREMOS ESTE MOMENTO DE FE",
            "ESPERAMOS CONTAR CON TU COMPAÑÍA",
            "UN DÍA DE FE Y ALEGRÍA NOS ESPERA",
            "SERÍA UN HONOR QUE NOS ACOMPAÑES",
            "COMPARTE CON NOSOTROS ESTA CELEBRACIÓN",
            "LA ALEGRÍA SERÁ MAYOR SI ESTÁS CON NOSOTROS"
        ],
        texts: [
            "Nos encantaría compartir contigo la alegría de este bautizo. Tu presencia será una bendición para nosotros.",
            "Esperamos que nos acompañes en este día tan especial y significativo, donde celebraremos un nuevo comienzo lleno de fe.",
            "Tenerte con nosotros en este día tan especial es un regalo invaluable. ¡Esperamos contar con tu presencia!",
            "Queremos celebrar la llegada de una nueva bendición y nos encantaría que nos acompañes en este momento tan especial.",
            "Acompáñanos a celebrar este día tan lleno de fe y amor. Tu presencia hará de este momento algo único.",
            "Nos encantaría que fueras parte de este día tan importante para nosotros. ¡Esperamos contar con tu compañía!",
            "Un día de fe, amor y alegría nos espera, y sería un honor que estuvieras presente para compartirlo con nosotros.",
            "Este día tan especial no sería lo mismo sin ti. Esperamos que puedas acompañarnos en esta ocasión única.",
            "Queremos compartir contigo la alegría de este día tan especial y celebrar juntos este momento de fe.",
            "La celebración será aún más especial si estás presente. Acompáñanos y forma parte de este día tan importante para nosotros."
        ]
    },
    kids: {
        titles: [
            "VEN A DIVERTIRTE CON NOSOTROS",
            "UNA FIESTA LLENA DE DIVERSIÓN TE ESPERA",
            "ACOMPAÑANOS EN ESTE DÍA DE JUEGOS Y RISAS",
            "CELEBREMOS JUNTOS UN DÍA MUY ESPECIAL",
            "TÚ ERES PARTE DE NUESTRA FIESTA",
            "ESPERAMOS QUE TE UNAS A LA DIVERSIÓN",
            "UN DÍA LLENO DE MAGIA Y SONRISAS",
            "¡NO TE PIERDAS LA DIVERSIÓN!",
            "VEN Y CELEBRA CON NOSOTROS",
            "TU PRESENCIA HARÁ QUE ESTE DÍA SEA INCREÍBLE"
        ],
        texts: [
            "Nos encantaría que nos acompañaras a un día lleno de diversión, juegos y muchas sorpresas. ¡No faltes!",
            "Prepárate para una fiesta llena de sonrisas, juegos y momentos inolvidables. ¡Te esperamos para celebrar juntos!",
            "Será un día de juegos, risas y muchas sorpresas. ¡Nos encantaría que vinieras a celebrar con nosotros!",
            "Queremos compartir contigo este día tan especial, lleno de alegría y diversión. ¡Será un día inolvidable!",
            "Tú eres parte importante de esta celebración, por eso nos encantaría que nos acompañaras en este día tan especial.",
            "Tu compañía hará que esta fiesta sea aún más divertida. ¡Esperamos verte y celebrar juntos!",
            "Un día lleno de magia, sonrisas y mucha diversión nos espera. ¡Acompáñanos a celebrarlo juntos!",
            "No te pierdas esta fiesta llena de juegos, dulces y muchas sorpresas. ¡Tu presencia es muy importante para nosotros!",
            "Queremos que estés con nosotros en este día tan especial, lleno de risas, diversión y mucha alegría.",
            "La fiesta no sería la misma sin ti. ¡Acompáñanos y hagamos de este día un momento inolvidable!"
        ]
    },
    event: {
        titles: [
            "NO TE PIERDAS ESTE GRAN EVENTO",
            "ESTARÍAMOS ENCANTADOS DE CONTAR CONTIGO",
            "VEN Y SÉ PARTE DE ESTE MOMENTO",
            "UNA OPORTUNIDAD ÚNICA TE ESPERA",
            "ESPERAMOS CONTAR CON TU PRESENCIA",
            "ACOMPAÑANOS Y HAZ DE ESTE EVENTO ALGO ESPECIAL",
            "TU PRESENCIA HARÁ LA DIFERENCIA",
            "ÚNETE A NOSOTROS PARA ESTE DÍA INOLVIDABLE",
            "VEN Y DISFRUTA DE ESTA EXPERIENCIA ÚNICA",
            "JUNTOS, HAGAMOS DE ESTE EVENTO ALGO MEMORABLE"
        ],
        texts: [
            "Nos encantaría que fueras parte de este evento especial. Tu presencia será clave para hacer de este día algo inolvidable.",
            "Queremos contar con tu presencia en este evento. Acompáñanos y vive una experiencia única y especial.",
            "Será un placer tenerte con nosotros en este evento. ¡No te lo pierdas y acompáñanos para disfrutar juntos!",
            "Este evento promete ser una oportunidad única y nos encantaría que estuvieras presente para compartirlo con nosotros.",
            "Esperamos poder contar con tu presencia en este evento. Ven y disfruta junto a nosotros de un día especial.",
            "Acompáñanos a celebrar este evento tan esperado. Tu presencia hará que este momento sea aún más significativo.",
            "Tu asistencia hará de este evento un verdadero éxito. ¡Esperamos verte y compartir juntos este día especial!",
            "Nos encantaría que te unieras a nosotros para un evento que será inolvidable. ¡Ven y vive la experiencia!",
            "Este evento está diseñado para ser una experiencia única y nos encantaría que lo compartieras con nosotros.",
            "Juntos, podemos hacer de este evento algo realmente memorable. ¡No faltes, tu presencia es importante para nosotros!"
        ]
    },
    party: {
        titles: [
            "ES HORA DE CELEBRAR",
            "UNA NOCHE INOLVIDABLE TE ESPERA",
            "VEN Y DISFRUTA DE LA FIESTA",
            "TÚ ERES EL INGREDIENTE ESPECIAL",
            "HAZ QUE ESTA NOCHE BRILLE CON TU PRESENCIA",
            "¡NO TE PIERDAS LA DIVERSIÓN!",
            "VEN A BAILAR Y DIVERTIRTE CON NOSOTROS",
            "LA FIESTA NO SERÁ IGUAL SIN TI",
            "JUNTOS HAREMOS DE ESTA NOCHE ALGO ESPECIAL",
            "ESPERAMOS QUE TE UNAS A LA CELEBRACIÓN"
        ],
        texts: [
            "Nos encantaría que te unieras a nosotros para una noche llena de diversión, música y buenos momentos. ¡No faltes!",
            "Prepárate para una noche que no olvidarás. Ven y disfruta de una fiesta llena de sorpresas y diversión.",
            "¡La mejor fiesta del año está por comenzar y queremos que seas parte de ella! Acompáñanos y celebremos juntos.",
            "Tu presencia hará que esta fiesta sea aún más especial. Ven y celebra con nosotros como solo tú sabes hacerlo.",
            "Nada nos haría más felices que contar contigo para esta noche de celebración. ¡Ven y haz que esta noche brille!",
            "La diversión está garantizada y no queremos que te la pierdas. ¡Únete a nosotros para una fiesta increíble!",
            "Ven a bailar, reír y disfrutar de una noche que promete ser inolvidable. ¡Te esperamos con muchas sorpresas!",
            "La fiesta no sería la misma sin ti. Esperamos verte y compartir juntos una noche llena de buenos momentos.",
            "Juntos haremos de esta noche algo verdaderamente especial. ¡Ven a celebrar con nosotros y disfruta al máximo!",
            "Nos encantaría que te unas a la celebración y que seas parte de esta fiesta llena de alegría y diversión."
        ]
    }

}

export const quotesAI = {
    wedding: [
        "El amor no consiste en mirarse el uno al otro, sino en mirar juntos en la misma dirección. — Antoine de Saint-Exupéry",
        "Tú eres mi hoy y todos mis mañanas. — Leo Christopher",
        "He encontrado a quien mi alma ama. — Cantar de los Cantares 3:4",
        "Eres la razón por la que sonrío, la razón por la que amo y la razón por la que creo en el amor.",
        "Contigo, cada momento es un sueño hecho realidad.",
        "El amor es esa condición en la que la felicidad de otra persona es esencial para la tuya. — Robert A. Heinlein",
        "El mejor tipo de amor es aquel que despierta el alma y nos hace aspirar a más. — Nicholas Sparks",
        "Caminar contigo por la vida es mi mayor aventura.",
        "En ti he encontrado mi hogar, mi refugio, mi amor eterno."
    ],
}

export const dresscodeAI = [
    "Nos encantaría que resaltes tu estilo personal, siguiendo nuestro código de vestimenta elegante. Encuentra inspiración en nuestras colecciones.",
    "Queremos verte deslumbrar con un atuendo formal, que refleje tu elegancia. Explora nuestras ideas de vestimenta en Instagram para más inspiración.",
    "Elige un look que hable de tu sofisticación y personalidad. Visita nuestras recomendaciones para seguir el código de vestimenta chic.",
    "Sigue el código de vestimenta formal con tu propio toque. Encuentra opciones que se ajusten a tu estilo en nuestra galería de Pinterest.",
    "Nos encantaría verte brillar con un atuendo semiformal que combine comodidad y estilo. Inspírate con nuestras ideas de moda.",
    "Tu presencia es importante para nosotros, y queremos que te sientas seguro y a la moda siguiendo el código de vestimenta. Descubre sugerencias en nuestros tableros.",
    "Para esta ocasión, sugerimos un atuendo elegante casual. Te invitamos a explorar nuestras recomendaciones para encontrar el look perfecto.",
    "Es tu oportunidad de destacar con un atuendo formal moderno. Consulta nuestras colecciones de moda para cumplir con el código de vestimenta con estilo.",
    "Sigue nuestro código de vestimenta con un outfit sofisticado y chic, ideal para esta ocasión especial. Encuentra ideas en nuestros tableros de Pinterest.",
    "Queremos que brilles con un atuendo que refleje tu esencia, dentro del código de vestimenta formal. Explora nuestras opciones para destacar con elegancia."
]

export const giftsAI = [
    "¡Tu presencia es el mejor regalo, pero tus buenos deseos se hacen aún más especiales con un toque personal!",
    "Lo más importante es que nos acompañes, pero si deseas obsequiarnos algo, hemos creado una mesa de regalos para ti.",
    "Tu cariño es el mayor regalo que podríamos pedir, pero si quieres añadir un toque especial, visita nuestra mesa de regalos.",
    "Tu presencia es invaluable, pero si quieres sorprendernos con un detalle, puedes encontrar ideas en nuestra mesa de regalos.",
    "Tenerte con nosotros es el mejor presente, pero si deseas compartir un detalle, hemos preparado una mesa de regalos.",
    "No necesitamos nada más que tu compañía, pero si deseas darnos un obsequio especial, nuestra mesa de regalos está disponible para ti.",
    "Lo que más valoramos es compartir este día contigo, pero si quieres agregar un toque extra, echa un vistazo a nuestra mesa de regalos.",
    "Tu presencia ya lo es todo para nosotros, pero si quieres contribuir con un detalle, nuestra mesa de regalos está lista.",
    "Nos alegra mucho que estés aquí, y si te gustaría ofrecernos un detalle adicional, hemos creado una mesa de regalos para facilitarlo.",
    "El mejor regalo es compartir este momento contigo, pero si deseas hacernos un presente, encuentra opciones en nuestra mesa de regalos."
]