import { Row } from 'antd'
import React from 'react'
import { darker, getDayOfMonth, getMonthInUppercase } from '../helpers/functions'

export const Logo = ({ height, width }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 579 159" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g filter="url(#filter0_d_919_1037)">
                <mask id="mask0_919_1037" maskUnits="userSpaceOnUse" x="7" y="7" width="142" height="142">
                    <rect x="7.60205" y="7.59375" width="141.322" height="140.597" rx="15" fill="#D9D9D9" />
                </mask>
                <g mask="url(#mask0_919_1037)">
                    <rect x="-4.71777" y="90.2129" width="168.137" height="57.9783" fill="white" />
                    <rect x="-4.71777" y="38.0317" width="168.137" height="52.1804" fill="#EEEEEE" />
                    <rect x="-4.71777" y="7.59277" width="168.137" height="30.4386" fill="url(#paint0_linear_919_1037)" />
                </g>
            </g>
            <rect x="30.793" y="0.347168" width="6.52255" height="15.2193" rx="3.26128" fill="#ECEFF6" />
            <rect x="120.659" y="0.347168" width="6.52255" height="15.2193" rx="3.26128" fill="#ECEFF6" />
            <path d="M52.5088 114.13V77.6885C52.5088 76.6159 52.5088 75.5164 52.5088 74.3902C52.5624 73.2104 52.616 71.9769 52.6697 70.6898C50.8462 72.4595 48.7547 73.988 46.395 75.2751C44.0889 76.5086 41.7023 77.3935 39.2354 77.9298L37.6265 70.4484C38.7527 70.2875 40.0666 69.9121 41.5683 69.3222C43.0699 68.7323 44.6252 67.9815 46.2341 67.0697C47.843 66.158 49.3178 65.1927 50.6585 64.1737C51.9993 63.1011 53.0451 62.0553 53.7959 61.0364H61.0359V114.13H52.5088Z" fill="#000000" />
            <path d="M76.5894 114.13V107.855C81.6843 104.208 85.9478 100.991 89.3802 98.2019C92.8661 95.4131 95.6549 92.8389 97.7464 90.4792C99.838 88.1195 101.34 85.8938 102.251 83.8023C103.217 81.6571 103.699 79.4851 103.699 77.2862C103.699 74.4439 102.814 72.1378 101.045 70.368C99.2749 68.5982 96.8079 67.7133 93.6437 67.7133C90.3187 67.7133 87.664 68.8395 85.6797 71.092C83.749 73.2908 82.6496 76.3209 82.3815 80.1823L74.9001 76.7231C75.2755 73.3444 76.2677 70.4216 77.8765 67.9546C79.4854 65.4877 81.6575 63.5838 84.3926 62.2431C87.1813 60.9023 90.3991 60.2319 94.046 60.2319C97.0492 60.2319 99.6771 60.661 101.93 61.519C104.236 62.3771 106.166 63.5838 107.722 65.1391C109.277 66.6407 110.457 68.4105 111.261 70.4484C112.119 72.4864 112.548 74.6584 112.548 76.9645C112.548 80.3431 111.717 83.6146 110.054 86.7787C108.392 89.8893 105.764 93.0802 102.171 96.3517C98.5777 99.6231 93.8583 103.136 88.0126 106.89V107.051C88.6562 106.944 89.541 106.89 90.6673 106.89C91.7935 106.836 92.9197 106.809 94.046 106.809C95.1722 106.756 96.0839 106.729 96.7811 106.729H113.514V114.13H76.5894Z" fill="#000000" />
            <path d="M62.9319 17.5898V23.8786C62.9319 25.075 62.5906 26.01 61.908 26.6836C61.2253 27.3573 60.2574 27.6941 59.0042 27.6941C58.1076 27.6941 57.3791 27.5332 56.8187 27.2115C56.2583 26.8897 55.8712 26.4373 55.6572 25.8542C55.4433 25.271 55.4025 24.6025 55.5349 23.8484L57.5829 23.4865C57.4606 24.3712 57.5166 25.0096 57.751 25.4017C57.9853 25.7939 58.4183 25.9899 59.05 25.9899C59.7123 25.9899 60.1708 25.7888 60.4255 25.3867C60.6904 24.9845 60.8229 24.3863 60.8229 23.592V17.5898H62.9319Z" fill="white" />
            <path d="M74.2816 17.5898V23.6222C74.2816 24.9795 73.9148 26 73.1812 26.6836C72.4476 27.3573 71.3778 27.6941 69.9718 27.6941C68.5658 27.6941 67.4959 27.3573 66.7624 26.6836C66.0288 26 65.662 24.9795 65.662 23.6222V17.5898H67.771V23.5317C67.771 24.3662 67.9493 24.9845 68.3059 25.3867C68.6727 25.7888 69.228 25.9899 69.9718 25.9899C70.7156 25.9899 71.2658 25.7888 71.6224 25.3867C71.979 24.9845 72.1573 24.3662 72.1573 23.5317V17.5898H74.2816Z" fill="white" />
            <path d="M85.835 17.5898V27.5433H83.4203L79.8899 21.6918L79.0187 20.0631H79.0035L79.0646 21.7823V27.5433H77.1695V17.5898H79.5689L83.0993 23.4261L83.9704 25.07H84.001L83.9399 23.3507V17.5898H85.835Z" fill="white" />
            <path d="M91.0351 17.5898V27.5433H88.9261V17.5898H91.0351Z" fill="white" />
            <path d="M98.4714 17.439C99.4699 17.439 100.331 17.6451 101.054 18.0573C101.778 18.4695 102.333 19.0577 102.72 19.8218C103.117 20.5859 103.316 21.5008 103.316 22.5665C103.316 23.6322 103.117 24.5472 102.72 25.3113C102.333 26.0754 101.778 26.6635 101.054 27.0757C100.331 27.488 99.4699 27.6941 98.4714 27.6941C97.4729 27.6941 96.6069 27.488 95.8733 27.0757C95.1499 26.6635 94.5895 26.0754 94.1922 25.3113C93.805 24.5472 93.6114 23.6322 93.6114 22.5665C93.6114 21.5008 93.805 20.5859 94.1922 19.8218C94.5895 19.0577 95.1499 18.4695 95.8733 18.0573C96.6069 17.6451 97.4729 17.439 98.4714 17.439ZM98.4714 19.1431C97.9008 19.1431 97.4169 19.2738 97.0195 19.5352C96.6222 19.7966 96.3165 20.1837 96.1025 20.6965C95.8988 21.1992 95.7969 21.8225 95.7969 22.5665C95.7969 23.3005 95.8988 23.9238 96.1025 24.4366C96.3165 24.9493 96.6222 25.3364 97.0195 25.5978C97.4169 25.8592 97.9008 25.9899 98.4714 25.9899C99.0318 25.9899 99.5107 25.8592 99.908 25.5978C100.305 25.3364 100.606 24.9493 100.81 24.4366C101.024 23.9238 101.131 23.3005 101.131 22.5665C101.131 21.8225 101.024 21.1992 100.81 20.6965C100.606 20.1837 100.305 19.7966 99.908 19.5352C99.5107 19.2738 99.0318 19.1431 98.4714 19.1431Z" fill="white" />
            <rect x="222.959" y="48.6226" width="355.51" height="68.3673" rx="2" fill="#FFD22C" />
            <path d="M186.191 107.453V64.6773H202.029V107.453H186.191ZM194.318 55.8721C191.818 55.8721 189.699 55.0038 187.962 53.2671C186.225 51.4956 185.357 49.3942 185.357 46.9628C185.357 44.5314 186.225 42.4299 187.962 40.6585C189.733 38.8523 191.852 37.9492 194.318 37.9492C195.951 37.9492 197.444 38.366 198.799 39.1997C200.154 39.9985 201.248 41.0753 202.082 42.43C202.915 43.7846 203.332 45.2955 203.332 46.9628C203.332 49.3942 202.446 51.4956 200.675 53.2671C198.903 55.0038 196.785 55.8721 194.318 55.8721Z" fill="#000000" />
            <path d="M249.842 105.027V97.8828C249.515 98.599 248.68 99.6375 247.337 100.998C246.03 102.359 244.287 103.595 242.109 104.705C239.931 105.779 237.39 106.316 234.486 106.316C230.129 106.316 226.318 105.278 223.05 103.201C219.783 101.088 217.242 98.2767 215.427 94.7672C213.612 91.2218 212.704 87.3005 212.704 83.0031C212.704 78.7057 213.612 74.8023 215.427 71.2928C217.242 67.7474 219.783 64.9183 223.05 62.8055C226.318 60.6926 230.129 59.6361 234.486 59.6361C237.281 59.6361 239.731 60.0838 241.837 60.9791C243.942 61.8385 245.649 62.8771 246.955 64.0947C248.262 65.2764 249.17 66.3687 249.678 67.3714V60.9253H266.123V105.027H249.842ZM228.931 83.0031C228.931 85.0086 229.403 86.817 230.347 88.4286C231.291 90.0043 232.543 91.2397 234.104 92.135C235.702 93.0303 237.462 93.478 239.386 93.478C241.383 93.478 243.144 93.0303 244.668 92.135C246.193 91.2397 247.391 90.0043 248.262 88.4286C249.17 86.817 249.624 85.0086 249.624 83.0031C249.624 80.9977 249.17 79.2071 248.262 77.6314C247.391 76.0199 246.193 74.7665 244.668 73.8712C243.144 72.9401 241.383 72.4745 239.386 72.4745C237.462 72.4745 235.702 72.9401 234.104 73.8712C232.543 74.7665 231.291 76.0199 230.347 77.6314C229.403 79.2071 228.931 80.9977 228.931 83.0031Z" fill="#000000" />
            <path d="M278.261 60.9253H285.885V42.8226H302.275V60.9253H312.295V74.3546H302.275V86.6559C302.275 88.4823 302.548 89.9684 303.092 91.1144C303.637 92.2246 304.635 92.7796 306.087 92.7796C307.067 92.7796 307.902 92.5648 308.592 92.135C309.282 91.7053 309.699 91.4188 309.844 91.2756L315.508 102.878C315.253 103.093 314.455 103.487 313.112 104.06C311.805 104.633 310.135 105.153 308.102 105.618C306.069 106.084 303.8 106.316 301.295 106.316C296.83 106.316 293.145 105.081 290.241 102.61C287.337 100.103 285.885 96.2534 285.885 91.0607V74.3546H278.261V60.9253Z" fill="#000000" />
            <path d="M322.739 60.9253H330.362V42.8226H346.753V60.9253H356.772V74.3546H346.753V86.6559C346.753 88.4823 347.025 89.9684 347.57 91.1144C348.114 92.2246 349.113 92.7796 350.565 92.7796C351.545 92.7796 352.38 92.5648 353.07 92.135C353.759 91.7053 354.177 91.4188 354.322 91.2756L359.985 102.878C359.731 103.093 358.932 103.487 357.589 104.06C356.282 104.633 354.612 105.153 352.579 105.618C350.547 106.084 348.278 106.316 345.773 106.316C341.308 106.316 337.623 105.081 334.719 102.61C331.814 100.103 330.362 96.2534 330.362 91.0607V74.3546H322.739V60.9253Z" fill="#000000" />
            <path d="M384.738 86.441C384.847 88.0167 385.355 89.4134 386.262 90.631C387.17 91.8485 388.423 92.8155 390.02 93.5317C391.653 94.2121 393.577 94.5523 395.792 94.5523C397.897 94.5523 399.785 94.3374 401.455 93.9077C403.161 93.478 404.632 92.9408 405.866 92.2962C407.136 91.6516 408.117 90.9891 408.806 90.3087L415.232 100.407C414.324 101.374 413.036 102.323 411.366 103.255C409.732 104.15 407.572 104.884 404.886 105.457C402.199 106.03 398.805 106.316 394.703 106.316C389.766 106.316 385.373 105.403 381.525 103.577C377.677 101.75 374.646 99.0646 372.431 95.5192C370.217 91.9739 369.11 87.6407 369.11 82.5197C369.11 78.2223 370.053 74.3546 371.941 70.9167C373.865 67.443 376.642 64.7035 380.273 62.698C383.903 60.6568 388.277 59.6361 393.396 59.6361C398.26 59.6361 402.472 60.5135 406.029 62.2683C409.623 64.023 412.382 66.6373 414.306 70.111C416.266 73.5489 417.247 77.8463 417.247 83.0031C417.247 83.2896 417.229 83.8626 417.192 84.7221C417.192 85.5815 417.156 86.1545 417.083 86.441H384.738ZM401.673 77.9C401.637 76.754 401.328 75.626 400.747 74.5158C400.166 73.3698 399.295 72.4387 398.133 71.7225C396.972 70.9705 395.483 70.5944 393.668 70.5944C391.853 70.5944 390.31 70.9526 389.04 71.6688C387.805 72.3492 386.861 73.2445 386.208 74.3546C385.555 75.4648 385.192 76.6466 385.119 77.9H401.673Z" fill="#000000" />
            <path d="M462.013 59.6361C465.135 59.6361 468.184 60.2628 471.161 61.5162C474.138 62.7696 476.588 64.7572 478.512 67.4788C480.436 70.1647 481.398 73.71 481.398 78.1148V105.027H464.79V80.8007C464.79 77.6493 464.027 75.2857 462.503 73.71C460.978 72.0985 458.981 71.2928 456.513 71.2928C454.879 71.2928 453.336 71.7225 451.884 72.582C450.469 73.4056 449.307 74.5874 448.399 76.1273C447.528 77.6314 447.092 79.3682 447.092 81.3379V105.027H430.538V60.9253H447.092V67.4251C447.528 66.2433 448.436 65.0616 449.815 63.8798C451.231 62.6622 452.992 61.6595 455.097 60.8716C457.239 60.048 459.544 59.6361 462.013 59.6361Z" fill="#000000" />
            <path d="M515.631 106.316C511.311 106.316 507.554 105.278 504.359 103.201C501.164 101.088 498.678 98.2767 496.899 94.7672C495.156 91.2218 494.285 87.3005 494.285 83.0031C494.285 78.7057 495.156 74.8023 496.899 71.2928C498.678 67.7474 501.164 64.9183 504.359 62.8055C507.554 60.6926 511.311 59.6361 515.631 59.6361C518.499 59.6361 521.04 60.0838 523.254 60.9791C525.469 61.8385 527.266 62.8771 528.645 64.0947C530.025 65.2764 530.86 66.3687 531.15 67.3714V31.1123H547.704V105.027H531.314V97.8828C530.624 99.2078 529.498 100.533 527.937 101.858C526.376 103.147 524.543 104.203 522.438 105.027C520.332 105.887 518.063 106.316 515.631 106.316ZM520.695 93.478C522.692 93.478 524.471 93.0303 526.032 92.135C527.629 91.2397 528.881 90.0043 529.789 88.4286C530.696 86.817 531.15 85.0086 531.15 83.0031C531.15 80.9977 530.696 79.2071 529.789 77.6314C528.881 76.0199 527.629 74.7665 526.032 73.8712C524.471 72.9401 522.692 72.4745 520.695 72.4745C518.771 72.4745 517.029 72.9401 515.467 73.8712C513.943 74.7665 512.727 76.0199 511.819 77.6314C510.948 79.2071 510.512 80.9977 510.512 83.0031C510.512 85.0086 510.948 86.817 511.819 88.4286C512.727 90.0043 513.943 91.2397 515.467 92.135C517.029 93.0303 518.771 93.478 520.695 93.478Z" fill="#000000" />
            <defs>
                <filter id="filter0_d_919_1037" x="0.802051" y="0.79375" width="158.922" height="158.197" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                    <feOffset dx="2" dy="2" />
                    <feGaussianBlur stdDeviation="4.4" />
                    <feComposite in2="hardAlpha" operator="out" />
                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.14 0" />
                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_919_1037" />
                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_919_1037" result="shape" />
                </filter>
                <linearGradient id="paint0_linear_919_1037" x1="70.044" y1="37.2433" x2="70.044" y2="8.86084" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FEC700" />
                    <stop offset="1" stop-color="#FFDE57" />
                </linearGradient>
            </defs>
        </svg>



    )
}


export const BuildLogo = ({ height, width }) => {
    return (
        <svg width={width} height={height} viewBox="0 0 575 148" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="218.775" y="48.2773" width="355.51" height="68.3673" rx="2" fill="#FFD22C" />
            <path d="M182.007 107.107V64.3316H197.846V107.107H182.007ZM190.135 55.5264C187.634 55.5264 185.515 54.6581 183.778 52.9214C182.042 51.1499 181.173 49.0485 181.173 46.6171C181.173 44.1857 182.042 42.0842 183.778 40.3128C185.55 38.5066 187.669 37.6035 190.135 37.6035C191.767 37.6035 193.261 38.0203 194.616 38.854C195.97 39.6528 197.064 40.7296 197.898 42.0842C198.732 43.4389 199.148 44.9498 199.148 46.6171C199.148 49.0485 198.263 51.1499 196.491 52.9214C194.72 54.6581 192.601 55.5264 190.135 55.5264Z" fill="#000000" />
            <path d="M245.658 104.682V97.5376C245.331 98.2538 244.496 99.2923 243.153 100.653C241.846 102.014 240.104 103.249 237.926 104.36C235.747 105.434 233.206 105.971 230.302 105.971C225.946 105.971 222.134 104.933 218.867 102.856C215.599 100.743 213.058 97.9315 211.243 94.422C209.428 90.8766 208.521 86.9553 208.521 82.6579C208.521 78.3605 209.428 74.4571 211.243 70.9476C213.058 67.4022 215.599 64.5731 218.867 62.4602C222.134 60.3474 225.946 59.2909 230.302 59.2909C233.097 59.2909 235.548 59.7386 237.653 60.6338C239.759 61.4933 241.465 62.5319 242.772 63.7494C244.079 64.9312 244.986 66.0235 245.495 67.0262V60.5801H261.94V104.682H245.658ZM224.748 82.6579C224.748 84.6633 225.22 86.4718 226.164 88.0833C227.107 89.659 228.36 90.8945 229.921 91.7898C231.518 92.6851 233.279 93.1328 235.203 93.1328C237.199 93.1328 238.96 92.6851 240.485 91.7898C242.01 90.8945 243.208 89.659 244.079 88.0833C244.986 86.4718 245.44 84.6633 245.44 82.6579C245.44 80.6525 244.986 78.8619 244.079 77.2862C243.208 75.6747 242.01 74.4213 240.485 73.526C238.96 72.5949 237.199 72.1293 235.203 72.1293C233.279 72.1293 231.518 72.5949 229.921 73.526C228.36 74.4213 227.107 75.6747 226.164 77.2862C225.22 78.8619 224.748 80.6525 224.748 82.6579Z" fill="#000000" />
            <path d="M274.078 60.5801H281.701V42.4774H298.092V60.5801H308.111V74.0094H298.092V86.3107C298.092 88.1371 298.364 89.6232 298.909 90.7692C299.453 91.8794 300.451 92.4344 301.903 92.4344C302.884 92.4344 303.719 92.2196 304.408 91.7898C305.098 91.3601 305.516 91.0736 305.661 90.9303L311.324 102.533C311.07 102.748 310.271 103.142 308.928 103.715C307.621 104.288 305.951 104.807 303.918 105.273C301.885 105.738 299.616 105.971 297.112 105.971C292.646 105.971 288.962 104.736 286.057 102.265C283.153 99.7579 281.701 95.9081 281.701 90.7155V74.0094H274.078V60.5801Z" fill="#000000" />
            <path d="M318.555 60.5801H326.179V42.4774H342.569V60.5801H352.589V74.0094H342.569V86.3107C342.569 88.1371 342.842 89.6232 343.386 90.7692C343.931 91.8794 344.929 92.4344 346.381 92.4344C347.361 92.4344 348.196 92.2196 348.886 91.7898C349.576 91.3601 349.993 91.0736 350.138 90.9303L355.802 102.533C355.547 102.748 354.749 103.142 353.406 103.715C352.099 104.288 350.429 104.807 348.396 105.273C346.363 105.738 344.094 105.971 341.589 105.971C337.124 105.971 333.439 104.736 330.535 102.265C327.631 99.7579 326.179 95.9081 326.179 90.7155V74.0094H318.555V60.5801Z" fill="#000000" />
            <path d="M380.554 86.0958C380.663 87.6715 381.171 89.0682 382.079 90.2857C382.986 91.5033 384.239 92.4702 385.836 93.1865C387.47 93.8669 389.394 94.2071 391.608 94.2071C393.714 94.2071 395.602 93.9922 397.271 93.5625C398.978 93.1328 400.448 92.5956 401.682 91.951C402.953 91.3064 403.933 90.6439 404.623 89.9634L411.048 100.062C410.141 101.029 408.852 101.978 407.182 102.909C405.548 103.805 403.388 104.539 400.702 105.112C398.016 105.685 394.621 105.971 390.519 105.971C385.582 105.971 381.189 105.058 377.341 103.232C373.493 101.405 370.462 98.7193 368.248 95.174C366.033 91.6287 364.926 87.2955 364.926 82.1744C364.926 77.8771 365.87 74.0094 367.758 70.5715C369.682 67.0978 372.459 64.3582 376.089 62.3528C379.719 60.3115 384.094 59.2909 389.212 59.2909C394.077 59.2909 398.288 60.1683 401.846 61.9231C405.44 63.6778 408.199 66.2921 410.123 69.7658C412.083 73.2037 413.063 77.501 413.063 82.6579C413.063 82.9444 413.045 83.5174 413.009 84.3769C413.009 85.2363 412.972 85.8093 412.9 86.0958H380.554ZM397.489 77.5548C397.453 76.4088 397.144 75.2807 396.564 74.1706C395.983 73.0246 395.111 72.0935 393.95 71.3773C392.788 70.6253 391.3 70.2492 389.485 70.2492C387.669 70.2492 386.127 70.6073 384.856 71.3236C383.622 72.004 382.678 72.8993 382.024 74.0094C381.371 75.1196 381.008 76.3014 380.935 77.5548H397.489Z" fill="#000000" />
            <path d="M457.829 59.2909C460.951 59.2909 464.001 59.9176 466.977 61.171C469.954 62.4244 472.405 64.412 474.329 67.1336C476.253 69.8195 477.215 73.3648 477.215 77.7696V104.682H460.606V80.4555C460.606 77.3041 459.844 74.9405 458.319 73.3648C456.794 71.7533 454.798 70.9476 452.329 70.9476C450.696 70.9476 449.153 71.3773 447.701 72.2368C446.285 73.0604 445.123 74.2422 444.216 75.7821C443.344 77.2862 442.909 79.023 442.909 80.9927V104.682H426.355V60.5801H442.909V67.0799C443.344 65.8981 444.252 64.7164 445.631 63.5346C447.047 62.317 448.808 61.3143 450.913 60.5264C453.055 59.7028 455.361 59.2909 457.829 59.2909Z" fill="#000000" />
            <path d="M511.447 105.971C507.127 105.971 503.37 104.933 500.175 102.856C496.981 100.743 494.494 97.9315 492.715 94.422C490.973 90.8766 490.101 86.9553 490.101 82.6579C490.101 78.3605 490.973 74.4571 492.715 70.9476C494.494 67.4022 496.981 64.5731 500.175 62.4602C503.37 60.3474 507.127 59.2909 511.447 59.2909C514.315 59.2909 516.856 59.7386 519.071 60.6338C521.285 61.4933 523.082 62.5319 524.462 63.7494C525.841 64.9312 526.676 66.0235 526.967 67.0262V30.7671H543.521V104.682H527.13V97.5376C526.44 98.8626 525.315 100.188 523.754 101.513C522.193 102.802 520.36 103.858 518.254 104.682C516.148 105.541 513.88 105.971 511.447 105.971ZM516.511 93.1328C518.508 93.1328 520.287 92.6851 521.848 91.7898C523.445 90.8945 524.698 89.659 525.605 88.0833C526.513 86.4718 526.967 84.6633 526.967 82.6579C526.967 80.6525 526.513 78.8619 525.605 77.2862C524.698 75.6747 523.445 74.4213 521.848 73.526C520.287 72.5949 518.508 72.1293 516.511 72.1293C514.587 72.1293 512.845 72.5949 511.284 73.526C509.759 74.4213 508.543 75.6747 507.636 77.2862C506.764 78.8619 506.329 80.6525 506.329 82.6579C506.329 84.6633 506.764 86.4718 507.636 88.0833C508.543 89.659 509.759 90.8945 511.284 91.7898C512.845 92.6851 514.587 93.1328 516.511 93.1328Z" fill="#000000" />
            <mask id="mask0_920_41" maskUnits="userSpaceOnUse" x="0" y="0" width="142" height="141">
                <rect width="141.326" height="140.602" rx="15" fill="#D9D9D9" />
            </mask>
            <g mask="url(#mask0_920_41)">
                <rect x="-13.6733" y="-3.8291" width="170.918" height="150.408" fill="url(#paint0_linear_920_41)" />
                <rect x="9.25488" y="9.25488" width="121.643" height="121.643" rx="10" stroke="white" stroke-width="2" />
            </g>
            <line x1="31.2651" y1="10.2549" x2="31.2651" y2="129.898" stroke="white" stroke-opacity="0.5" />
            <line x1="58.6123" y1="10.2549" x2="58.6123" y2="129.898" stroke="white" stroke-opacity="0.5" />
            <line x1="85.959" y1="10.2549" x2="85.959" y2="129.898" stroke="white" stroke-opacity="0.5" />
            <line x1="113.306" y1="10.2549" x2="113.306" y2="129.898" stroke="white" stroke-opacity="0.5" />
            <line x1="129.898" y1="27.8467" x2="10.2551" y2="27.8467" stroke="white" stroke-opacity="0.5" />
            <line x1="129.898" y1="55.1938" x2="10.2551" y2="55.1938" stroke="white" stroke-opacity="0.5" />
            <line x1="129.898" y1="82.5405" x2="10.2551" y2="82.5405" stroke="white" stroke-opacity="0.5" />
            <line x1="129.898" y1="109.888" x2="10.2551" y2="109.888" stroke="white" stroke-opacity="0.5" />
            <line x1="11.333" y1="11.248" x2="129.333" y2="129.248" stroke="white" stroke-opacity="0.5" />
            <line x1="129.333" y1="10.9551" x2="11.333" y2="128.955" stroke="white" stroke-opacity="0.5" />
            <path d="M48.9319 103.296V66.3163C48.9319 65.2279 48.9319 64.1123 48.9319 62.9694C48.9851 61.7721 49.0382 60.5204 49.0914 59.2143C47.2844 61.0102 45.2116 62.5612 42.8732 63.8674C40.5878 65.1191 38.2228 66.017 35.778 66.5612L34.1836 58.9694C35.2997 58.8061 36.6018 58.4252 38.0899 57.8265C39.578 57.2279 41.1193 56.466 42.7137 55.5408C44.3081 54.6157 45.7697 53.6361 47.0984 52.602C48.427 51.5136 49.4634 50.4524 50.2075 49.4184H57.3823V103.296H48.9319Z" fill="white" />
            <path d="M72.7958 103.296V96.9286C77.8448 93.2279 82.07 89.9626 85.4714 87.1327C88.926 84.3027 91.6897 81.6905 93.7624 79.2959C95.8351 76.9014 97.3233 74.6429 98.2268 72.5204C99.1834 70.3435 99.6617 68.1395 99.6617 65.9082C99.6617 63.0238 98.7848 60.6837 97.0309 58.8878C95.2771 57.0918 92.8323 56.1939 89.6966 56.1939C86.4015 56.1939 83.7707 57.3367 81.8043 59.6225C79.891 61.8538 78.8015 64.9286 78.5357 68.8469L71.1217 65.3367C71.4937 61.9082 72.477 58.9422 74.0714 56.4388C75.6658 53.9354 77.8182 52.0034 80.5288 50.6429C83.2924 49.2823 86.4812 48.6021 90.0952 48.6021C93.0715 48.6021 95.6757 49.0374 97.9079 49.9082C100.193 50.7789 102.107 52.0034 103.648 53.5816C105.189 55.1055 106.358 56.9014 107.155 58.9694C108.006 61.0374 108.431 63.2415 108.431 65.5816C108.431 69.0102 107.607 72.3299 105.96 75.5408C104.312 78.6973 101.708 81.9354 98.147 85.2551C94.5862 88.5748 89.9092 92.1395 84.1162 95.949V96.1123C84.754 96.0034 85.6309 95.949 86.747 95.949C87.8631 95.8946 88.9792 95.8674 90.0952 95.8674C91.2113 95.8129 92.1148 95.7857 92.8058 95.7857H109.388V103.296H72.7958Z" fill="white" />
            <path d="M52.4452 22.7529V29.0417C52.4452 30.2381 52.0795 31.1731 51.3481 31.8467C50.6167 32.5203 49.5796 32.8572 48.2369 32.8572C47.2763 32.8572 46.4957 32.6963 45.8953 32.3746C45.2949 32.0528 44.8801 31.6004 44.6509 31.0173C44.4216 30.4341 44.378 29.7655 44.5199 29.0115L46.7141 28.6495C46.5831 29.5343 46.6431 30.1727 46.8942 30.5648C47.1453 30.9569 47.6092 31.153 48.286 31.153C48.9956 31.153 49.4869 30.9519 49.7598 30.5498C50.0436 30.1476 50.1855 29.5494 50.1855 28.7551V22.7529H52.4452Z" fill="white" />
            <path d="M64.6056 22.7529V28.7853C64.6056 30.1426 64.2126 31.1631 63.4266 31.8467C62.6406 32.5203 61.4944 32.8572 59.9879 32.8572C58.4814 32.8572 57.3352 32.5203 56.5492 31.8467C55.7633 31.1631 55.3703 30.1426 55.3703 28.7853V22.7529H57.63V28.6948C57.63 29.5293 57.821 30.1476 58.2031 30.5498C58.5961 30.9519 59.191 31.153 59.9879 31.153C60.7848 31.153 61.3743 30.9519 61.7564 30.5498C62.1385 30.1476 62.3295 29.5293 62.3295 28.6948V22.7529H64.6056Z" fill="white" />
            <path d="M76.9842 22.7529V32.7063H74.397L70.6144 26.8549L69.6811 25.2262H69.6647L69.7302 26.9454V32.7063H67.6997V22.7529H70.2706L74.0531 28.5892L74.9865 30.2331H75.0192L74.9537 28.5138V22.7529H76.9842Z" fill="white" />
            <path d="M82.5558 22.7529V32.7063H80.2961V22.7529H82.5558Z" fill="white" />
            <path d="M90.5232 22.6021C91.593 22.6021 92.5155 22.8082 93.2905 23.2204C94.0656 23.6326 94.6605 24.2207 95.0754 24.9849C95.5011 25.749 95.714 26.6639 95.714 27.7296C95.714 28.7953 95.5011 29.7102 95.0754 30.4743C94.6605 31.2385 94.0656 31.8266 93.2905 32.2388C92.5155 32.651 91.593 32.8572 90.5232 32.8572C89.4534 32.8572 88.5255 32.651 87.7395 32.2388C86.9645 31.8266 86.364 31.2385 85.9383 30.4743C85.5235 29.7102 85.3161 28.7953 85.3161 27.7296C85.3161 26.6639 85.5235 25.749 85.9383 24.9849C86.364 24.2207 86.9645 23.6326 87.7395 23.2204C88.5255 22.8082 89.4534 22.6021 90.5232 22.6021ZM90.5232 24.3062C89.9119 24.3062 89.3934 24.4369 88.9676 24.6983C88.5419 24.9597 88.2144 25.3468 87.9851 25.8596C87.7668 26.3623 87.6576 26.9856 87.6576 27.7296C87.6576 28.4635 87.7668 29.0869 87.9851 29.5996C88.2144 30.1124 88.5419 30.4995 88.9676 30.7609C89.3934 31.0223 89.9119 31.153 90.5232 31.153C91.1236 31.153 91.6367 31.0223 92.0624 30.7609C92.4882 30.4995 92.8102 30.1124 93.0285 29.5996C93.2578 29.0869 93.3724 28.4635 93.3724 27.7296C93.3724 26.9856 93.2578 26.3623 93.0285 25.8596C92.8102 25.3468 92.4882 24.9597 92.0624 24.6983C91.6367 24.4369 91.1236 24.3062 90.5232 24.3062Z" fill="white" />
            <rect x="115.789" y="68.1943" width="22.3788" height="74.1159" rx="2" transform="rotate(24.6983 115.789 68.1943)" fill="#000000" />
            <path d="M132.524 76.7178L118.427 69.8919C118.427 69.8919 120.808 66.1519 121.939 63.5605C122.95 61.2426 124.016 57.427 124.016 57.427C124.016 57.427 123.229 54.6092 121.988 53.3215C120.573 51.8533 119.205 51.7432 117.289 51.0465C113.42 49.6397 110.958 49.2663 106.852 49.5623C102.864 49.8499 100.773 51.0329 97.0091 52.3818C94.8141 53.1684 91.4691 54.6077 91.4691 54.6077C91.4691 54.6077 89.4103 54.3325 88.4024 53.569C87.3945 52.8054 86.5722 50.8979 86.5722 50.8979C86.5722 50.8979 89.5627 47.6351 91.7164 45.8032C95.9587 42.1946 98.7154 40.4775 103.835 38.2846C110.094 35.6035 114.062 35.0086 120.85 34.4758C124.837 34.163 127.134 33.9407 131.089 34.5253C136.093 35.2647 139.687 35.1994 143.356 38.6802C145.191 40.4205 145.587 41.936 147.017 44.0222C148.446 46.1084 148.661 47.8372 150.677 49.3643C152.693 50.8914 156.811 51.4417 156.811 51.4417C156.811 51.4417 159.145 49.658 160.916 49.4137C162.688 49.1694 165.417 50.2546 165.417 50.2546L174.815 54.8052C174.815 54.8052 173.929 59.4943 172.936 62.3731C171.606 66.2282 170.604 68.3498 168.385 71.7713C167.08 73.7843 164.676 76.6682 164.676 76.6682C164.676 76.6682 160.771 75.7848 158.542 74.5907C156.427 73.458 155.211 72.6983 153.645 70.881C152.82 69.9232 152.578 69.2179 151.815 68.21C150.584 66.5847 148.352 64.3023 148.352 64.3023C148.352 64.3023 144.727 63.3407 142.417 63.6593C140.107 63.9779 136.877 65.8852 136.877 65.8852L132.524 76.7178Z" fill="#AAB8B9" />
            <rect x="130.292" y="41.2998" width="4.88825" height="12.2818" rx="2.44412" transform="rotate(-67.3175 130.292 41.2998)" fill="#D3E2E2" />
            <rect x="158.219" y="58.6064" width="5.00244" height="11.3845" rx="2.50122" transform="rotate(-67.3175 158.219 58.6064)" fill="#D3E2E2" />
            <rect x="149.033" y="50.8472" width="5.00244" height="10.0803" rx="2.50122" transform="rotate(26.4169 149.033 50.8472)" fill="#909999" />
            <defs>
                <linearGradient id="paint0_linear_920_41" x1="71.7858" y1="-3.8291" x2="71.7858" y2="146.579" gradientUnits="userSpaceOnUse">
                    <stop stop-color="#FFDC50" />
                    <stop offset="1" stop-color="#FEC908" />
                </linearGradient>
            </defs>
        </svg>





    )
}


export const ManualLogo = ({ date }) => {

    function getFirstThreeLettersUppercase(str) {
        // Asegúrate de que la cadena tenga al menos tres caracteres
        if (str.length < 3) {
            throw new Error('La cadena debe tener al menos tres caracteres');
        }

        // Obtén las primeras tres letras y conviértelas a mayúsculas
        return str.slice(0, 3).toUpperCase();
    }


    return (
        <Row style={{
            display: 'flex', alignItems: 'center', justifyContent: 'flex-start', flexDirection: 'row'
        }}>


            <div className='full-logo-container'>

                <div className={'logo-container'}>

                    <div className={'logo-yellow-section'}>
                        {/* <span className='logo-month'>{getMonthInUppercase(date)}</span> */}
                    </div>
                    <div className={'logo-gray-section'}></div>
                    <div className={'logo-white-section'}></div>

                    <span className={'logo-day'}>{date ? getDayOfMonth(date) : 'X'}</span>

                </div>

                <div className='logo-item-calendar' style={{
                    left: '9px'
                }} />
                <div className='logo-item-calendar' style={{
                    right: '24px'
                }} />

            </div>



            <span className='i-attend-text-logo'>I attend</span>




            {/* <svg width="150" height="55" viewBox="0 0 394 86" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transition: 'all 0.3s ease' }}>
                <rect x="37.959" y="17.6226" width="355.51" height="68.3673" rx="2" fill={"#FFB32D"} />
                <path d="M1.19056 76.4526V33.6773H17.0294V76.4526H1.19056ZM9.31839 24.8721C6.81752 24.8721 4.69872 24.0038 2.96201 22.2671C1.22529 20.4956 0.356934 18.3942 0.356934 15.9628C0.356934 13.5314 1.22529 11.4299 2.96201 9.6585C4.73346 7.85231 6.85225 6.94922 9.31839 6.94922C10.9509 6.94922 12.4445 7.36603 13.7991 8.19966C15.1538 8.99855 16.2479 10.0753 17.0815 11.43C17.9151 12.7846 18.3319 14.2955 18.3319 15.9628C18.3319 18.3942 17.4462 20.4956 15.6748 22.2671C13.9033 24.0038 11.7845 24.8721 9.31839 24.8721Z" fill={"#000"} />
                <path d="M64.8415 74.0272V66.8828C64.5148 67.599 63.6798 68.6375 62.3367 69.9984C61.0298 71.3592 59.2872 72.5947 57.1091 73.7049C54.931 74.7792 52.3898 75.3164 49.4856 75.3164C45.1293 75.3164 41.3175 74.2778 38.0503 72.2008C34.7831 70.0879 32.2419 67.2767 30.4268 63.7672C28.6117 60.2218 27.7041 56.3005 27.7041 52.0031C27.7041 47.7057 28.6117 43.8023 30.4268 40.2928C32.2419 36.7474 34.7831 33.9183 38.0503 31.8055C41.3175 29.6926 45.1293 28.6361 49.4856 28.6361C52.2809 28.6361 54.7313 29.0838 56.8368 29.9791C58.9424 30.8385 60.6486 31.8771 61.9555 33.0947C63.2624 34.2764 64.1699 35.3687 64.6782 36.3714V29.9253H81.1232V74.0272H64.8415ZM43.9313 52.0031C43.9313 54.0086 44.4032 55.817 45.3471 57.4286C46.291 59.0043 47.5434 60.2397 49.1044 61.135C50.7017 62.0303 52.4624 62.478 54.3864 62.478C56.3831 62.478 58.1437 62.0303 59.6684 61.135C61.1931 60.2397 62.3911 59.0043 63.2624 57.4286C64.1699 55.817 64.6237 54.0086 64.6237 52.0031C64.6237 49.9977 64.1699 48.2071 63.2624 46.6314C62.3911 45.0199 61.1931 43.7665 59.6684 42.8712C58.1437 41.9401 56.3831 41.4745 54.3864 41.4745C52.4624 41.4745 50.7017 41.9401 49.1044 42.8712C47.5434 43.7665 46.291 45.0199 45.3471 46.6314C44.4032 48.2071 43.9313 49.9977 43.9313 52.0031Z" fill={"#000"} />
                <path d="M93.2612 29.9253H100.885V11.8226H117.275V29.9253H127.295V43.3546H117.275V55.6559C117.275 57.4823 117.548 58.9684 118.092 60.1144C118.637 61.2246 119.635 61.7796 121.087 61.7796C122.067 61.7796 122.902 61.5648 123.592 61.135C124.282 60.7053 124.699 60.4188 124.844 60.2756L130.508 71.8785C130.253 72.0933 129.455 72.4873 128.112 73.0603C126.805 73.6332 125.135 74.1525 123.102 74.6181C121.069 75.0836 118.8 75.3164 116.295 75.3164C111.83 75.3164 108.145 74.0809 105.241 71.6099C102.337 69.1031 100.885 65.2534 100.885 60.0607V43.3546H93.2612V29.9253Z" fill={"#000"} />
                <path d="M137.739 29.9253H145.362V11.8226H161.753V29.9253H171.772V43.3546H161.753V55.6559C161.753 57.4823 162.025 58.9684 162.57 60.1144C163.114 61.2246 164.113 61.7796 165.565 61.7796C166.545 61.7796 167.38 61.5648 168.07 61.135C168.759 60.7053 169.177 60.4188 169.322 60.2756L174.985 71.8785C174.731 72.0933 173.932 72.4873 172.589 73.0603C171.282 73.6332 169.612 74.1525 167.579 74.6181C165.547 75.0836 163.278 75.3164 160.773 75.3164C156.308 75.3164 152.623 74.0809 149.719 71.6099C146.814 69.1031 145.362 65.2534 145.362 60.0607V43.3546H137.739V29.9253Z" fill={"#000"} />
                <path d="M199.738 55.441C199.847 57.0167 200.355 58.4134 201.262 59.631C202.17 60.8485 203.423 61.8155 205.02 62.5317C206.653 63.2121 208.577 63.5523 210.792 63.5523C212.897 63.5523 214.785 63.3374 216.455 62.9077C218.161 62.478 219.632 61.9408 220.866 61.2962C222.136 60.6516 223.117 59.9891 223.806 59.3087L230.232 69.4075C229.324 70.3744 228.036 71.3234 226.366 72.2545C224.732 73.1498 222.572 73.8839 219.886 74.4569C217.199 75.0299 213.805 75.3164 209.703 75.3164C204.766 75.3164 200.373 74.4032 196.525 72.5768C192.677 70.7504 189.646 68.0646 187.431 64.5192C185.217 60.9739 184.11 56.6407 184.11 51.5197C184.11 47.2223 185.053 43.3546 186.941 39.9167C188.865 36.443 191.642 33.7035 195.273 31.698C198.903 29.6568 203.277 28.6361 208.396 28.6361C213.26 28.6361 217.472 29.5135 221.029 31.2683C224.623 33.023 227.382 35.6373 229.306 39.111C231.266 42.5489 232.247 46.8463 232.247 52.0031C232.247 52.2896 232.229 52.8626 232.192 53.7221C232.192 54.5815 232.156 55.1545 232.083 55.441H199.738ZM216.673 46.9C216.637 45.754 216.328 44.626 215.747 43.5158C215.166 42.3698 214.295 41.4387 213.133 40.7225C211.972 39.9705 210.483 39.5944 208.668 39.5944C206.853 39.5944 205.31 39.9526 204.04 40.6688C202.805 41.3492 201.861 42.2445 201.208 43.3546C200.555 44.4648 200.192 45.6466 200.119 46.9H216.673Z" fill={"#000"} />
                <path d="M277.013 28.6361C280.135 28.6361 283.184 29.2628 286.161 30.5162C289.138 31.7696 291.588 33.7572 293.512 36.4788C295.436 39.1647 296.398 42.71 296.398 47.1148V74.0272H279.79V49.8007C279.79 46.6493 279.027 44.2857 277.503 42.71C275.978 41.0985 273.981 40.2928 271.513 40.2928C269.879 40.2928 268.336 40.7225 266.884 41.582C265.469 42.4056 264.307 43.5874 263.399 45.1273C262.528 46.6314 262.092 48.3682 262.092 50.3379V74.0272H245.538V29.9253H262.092V36.4251C262.528 35.2433 263.436 34.0616 264.815 32.8798C266.231 31.6622 267.992 30.6595 270.097 29.8716C272.239 29.048 274.544 28.6361 277.013 28.6361Z" fill={"#000"} />
                <path d="M330.631 75.3164C326.311 75.3164 322.554 74.2778 319.359 72.2008C316.164 70.0879 313.678 67.2767 311.899 63.7672C310.156 60.2218 309.285 56.3005 309.285 52.0031C309.285 47.7057 310.156 43.8023 311.899 40.2928C313.678 36.7474 316.164 33.9183 319.359 31.8055C322.554 29.6926 326.311 28.6361 330.631 28.6361C333.499 28.6361 336.04 29.0838 338.254 29.9791C340.469 30.8385 342.266 31.8771 343.645 33.0947C345.025 34.2764 345.86 35.3687 346.15 36.3714V0.112305H362.704V74.0272H346.314V66.8828C345.624 68.2078 344.498 69.5328 342.937 70.8578C341.376 72.1471 339.543 73.2035 337.438 74.0272C335.332 74.8866 333.063 75.3164 330.631 75.3164ZM335.695 62.478C337.692 62.478 339.471 62.0303 341.032 61.135C342.629 60.2397 343.881 59.0043 344.789 57.4286C345.696 55.817 346.15 54.0086 346.15 52.0031C346.15 49.9977 345.696 48.2071 344.789 46.6314C343.881 45.0199 342.629 43.7665 341.032 42.8712C339.471 41.9401 337.692 41.4745 335.695 41.4745C333.771 41.4745 332.029 41.9401 330.467 42.8712C328.943 43.7665 327.727 45.0199 326.819 46.6314C325.948 48.2071 325.512 49.9977 325.512 52.0031C325.512 54.0086 325.948 55.817 326.819 57.4286C327.727 59.0043 328.943 60.2397 330.467 61.135C332.029 62.0303 333.771 62.478 335.695 62.478Z" fill={"#000"} />
            </svg> */}


        </Row>
    )
}


export const ColorLogo = ({ date, dark, light }) => {


    return (
        <Row style={{
            display: 'flex', alignItems: 'center', justifyContent: 'center', flexDirection: 'row',
            width: '100%',
        }}>

            {
                date ?
                    <div className='full-logo-container' >
                        <div className={'logo-container'} style={{ height: '40px', borderRadius: '8px', boxShadow: '0px 0px 0px rgba(0,0,0,0)' }}>

                            <div className={'logo-yellow-section'} style={{
                                background: dark
                            }}>
                                <span className='logo-month' style={{
                                    color: light, fontSize: '6px'
                                }}>{getMonthInUppercase(date)}</span>
                            </div>
                            <div className={'logo-gray-section'} style={{ backgroundColor: light }}></div>
                            <div className={'logo-white-section'} style={{ backgroundColor: light }}></div>

                            <span className={'logo-day'} style={{ bottom: '-3px', color: dark, opacity: 1 }}>{getDayOfMonth(date)}</span>

                        </div>

                    </div>
                    : <></>
            }




            <svg width="170" height="auto" viewBox="0 0 394 86" fill="none" xmlns="http://www.w3.org/2000/svg" style={{ transition: 'all 0.3s ease' }}>
                <rect x="37.959" y="17.6226" width="355.51" height="68.3673" rx="2" fill={dark} />
                <path d="M1.19056 76.4526V33.6773H17.0294V76.4526H1.19056ZM9.31839 24.8721C6.81752 24.8721 4.69872 24.0038 2.96201 22.2671C1.22529 20.4956 0.356934 18.3942 0.356934 15.9628C0.356934 13.5314 1.22529 11.4299 2.96201 9.6585C4.73346 7.85231 6.85225 6.94922 9.31839 6.94922C10.9509 6.94922 12.4445 7.36603 13.7991 8.19966C15.1538 8.99855 16.2479 10.0753 17.0815 11.43C17.9151 12.7846 18.3319 14.2955 18.3319 15.9628C18.3319 18.3942 17.4462 20.4956 15.6748 22.2671C13.9033 24.0038 11.7845 24.8721 9.31839 24.8721Z" fill={light} />
                <path d="M64.8415 74.0272V66.8828C64.5148 67.599 63.6798 68.6375 62.3367 69.9984C61.0298 71.3592 59.2872 72.5947 57.1091 73.7049C54.931 74.7792 52.3898 75.3164 49.4856 75.3164C45.1293 75.3164 41.3175 74.2778 38.0503 72.2008C34.7831 70.0879 32.2419 67.2767 30.4268 63.7672C28.6117 60.2218 27.7041 56.3005 27.7041 52.0031C27.7041 47.7057 28.6117 43.8023 30.4268 40.2928C32.2419 36.7474 34.7831 33.9183 38.0503 31.8055C41.3175 29.6926 45.1293 28.6361 49.4856 28.6361C52.2809 28.6361 54.7313 29.0838 56.8368 29.9791C58.9424 30.8385 60.6486 31.8771 61.9555 33.0947C63.2624 34.2764 64.1699 35.3687 64.6782 36.3714V29.9253H81.1232V74.0272H64.8415ZM43.9313 52.0031C43.9313 54.0086 44.4032 55.817 45.3471 57.4286C46.291 59.0043 47.5434 60.2397 49.1044 61.135C50.7017 62.0303 52.4624 62.478 54.3864 62.478C56.3831 62.478 58.1437 62.0303 59.6684 61.135C61.1931 60.2397 62.3911 59.0043 63.2624 57.4286C64.1699 55.817 64.6237 54.0086 64.6237 52.0031C64.6237 49.9977 64.1699 48.2071 63.2624 46.6314C62.3911 45.0199 61.1931 43.7665 59.6684 42.8712C58.1437 41.9401 56.3831 41.4745 54.3864 41.4745C52.4624 41.4745 50.7017 41.9401 49.1044 42.8712C47.5434 43.7665 46.291 45.0199 45.3471 46.6314C44.4032 48.2071 43.9313 49.9977 43.9313 52.0031Z" fill={light} />
                <path d="M93.2612 29.9253H100.885V11.8226H117.275V29.9253H127.295V43.3546H117.275V55.6559C117.275 57.4823 117.548 58.9684 118.092 60.1144C118.637 61.2246 119.635 61.7796 121.087 61.7796C122.067 61.7796 122.902 61.5648 123.592 61.135C124.282 60.7053 124.699 60.4188 124.844 60.2756L130.508 71.8785C130.253 72.0933 129.455 72.4873 128.112 73.0603C126.805 73.6332 125.135 74.1525 123.102 74.6181C121.069 75.0836 118.8 75.3164 116.295 75.3164C111.83 75.3164 108.145 74.0809 105.241 71.6099C102.337 69.1031 100.885 65.2534 100.885 60.0607V43.3546H93.2612V29.9253Z" fill={light} />
                <path d="M137.739 29.9253H145.362V11.8226H161.753V29.9253H171.772V43.3546H161.753V55.6559C161.753 57.4823 162.025 58.9684 162.57 60.1144C163.114 61.2246 164.113 61.7796 165.565 61.7796C166.545 61.7796 167.38 61.5648 168.07 61.135C168.759 60.7053 169.177 60.4188 169.322 60.2756L174.985 71.8785C174.731 72.0933 173.932 72.4873 172.589 73.0603C171.282 73.6332 169.612 74.1525 167.579 74.6181C165.547 75.0836 163.278 75.3164 160.773 75.3164C156.308 75.3164 152.623 74.0809 149.719 71.6099C146.814 69.1031 145.362 65.2534 145.362 60.0607V43.3546H137.739V29.9253Z" fill={light} />
                <path d="M199.738 55.441C199.847 57.0167 200.355 58.4134 201.262 59.631C202.17 60.8485 203.423 61.8155 205.02 62.5317C206.653 63.2121 208.577 63.5523 210.792 63.5523C212.897 63.5523 214.785 63.3374 216.455 62.9077C218.161 62.478 219.632 61.9408 220.866 61.2962C222.136 60.6516 223.117 59.9891 223.806 59.3087L230.232 69.4075C229.324 70.3744 228.036 71.3234 226.366 72.2545C224.732 73.1498 222.572 73.8839 219.886 74.4569C217.199 75.0299 213.805 75.3164 209.703 75.3164C204.766 75.3164 200.373 74.4032 196.525 72.5768C192.677 70.7504 189.646 68.0646 187.431 64.5192C185.217 60.9739 184.11 56.6407 184.11 51.5197C184.11 47.2223 185.053 43.3546 186.941 39.9167C188.865 36.443 191.642 33.7035 195.273 31.698C198.903 29.6568 203.277 28.6361 208.396 28.6361C213.26 28.6361 217.472 29.5135 221.029 31.2683C224.623 33.023 227.382 35.6373 229.306 39.111C231.266 42.5489 232.247 46.8463 232.247 52.0031C232.247 52.2896 232.229 52.8626 232.192 53.7221C232.192 54.5815 232.156 55.1545 232.083 55.441H199.738ZM216.673 46.9C216.637 45.754 216.328 44.626 215.747 43.5158C215.166 42.3698 214.295 41.4387 213.133 40.7225C211.972 39.9705 210.483 39.5944 208.668 39.5944C206.853 39.5944 205.31 39.9526 204.04 40.6688C202.805 41.3492 201.861 42.2445 201.208 43.3546C200.555 44.4648 200.192 45.6466 200.119 46.9H216.673Z" fill={light} />
                <path d="M277.013 28.6361C280.135 28.6361 283.184 29.2628 286.161 30.5162C289.138 31.7696 291.588 33.7572 293.512 36.4788C295.436 39.1647 296.398 42.71 296.398 47.1148V74.0272H279.79V49.8007C279.79 46.6493 279.027 44.2857 277.503 42.71C275.978 41.0985 273.981 40.2928 271.513 40.2928C269.879 40.2928 268.336 40.7225 266.884 41.582C265.469 42.4056 264.307 43.5874 263.399 45.1273C262.528 46.6314 262.092 48.3682 262.092 50.3379V74.0272H245.538V29.9253H262.092V36.4251C262.528 35.2433 263.436 34.0616 264.815 32.8798C266.231 31.6622 267.992 30.6595 270.097 29.8716C272.239 29.048 274.544 28.6361 277.013 28.6361Z" fill={light} />
                <path d="M330.631 75.3164C326.311 75.3164 322.554 74.2778 319.359 72.2008C316.164 70.0879 313.678 67.2767 311.899 63.7672C310.156 60.2218 309.285 56.3005 309.285 52.0031C309.285 47.7057 310.156 43.8023 311.899 40.2928C313.678 36.7474 316.164 33.9183 319.359 31.8055C322.554 29.6926 326.311 28.6361 330.631 28.6361C333.499 28.6361 336.04 29.0838 338.254 29.9791C340.469 30.8385 342.266 31.8771 343.645 33.0947C345.025 34.2764 345.86 35.3687 346.15 36.3714V0.112305H362.704V74.0272H346.314V66.8828C345.624 68.2078 344.498 69.5328 342.937 70.8578C341.376 72.1471 339.543 73.2035 337.438 74.0272C335.332 74.8866 333.063 75.3164 330.631 75.3164ZM335.695 62.478C337.692 62.478 339.471 62.0303 341.032 61.135C342.629 60.2397 343.881 59.0043 344.789 57.4286C345.696 55.817 346.15 54.0086 346.15 52.0031C346.15 49.9977 345.696 48.2071 344.789 46.6314C343.881 45.0199 342.629 43.7665 341.032 42.8712C339.471 41.9401 337.692 41.4745 335.695 41.4745C333.771 41.4745 332.029 41.9401 330.467 42.8712C328.943 43.7665 327.727 45.0199 326.819 46.6314C325.948 48.2071 325.512 49.9977 325.512 52.0031C325.512 54.0086 325.948 55.817 326.819 57.4286C327.727 59.0043 328.943 60.2397 330.467 61.135C332.029 62.0303 333.771 62.478 335.695 62.478Z" fill={light} />
            </svg>


        </Row>
    )
}

// export const CalendarLogo = ({ date }) => {
//     return (

//         <div className='full-logo-container'>
//             <div className='logo-container'>

//                 <div className='logo-yellow-section'>
//                     <span className='logo-month'>{getMonthInUppercase(date)}</span>
//                 </div>
//                 <div className='logo-gray-section'></div>
//                 <div className='logo-white-section'></div>

//                 <span className='logo-day'>{getDayOfMonth(date)}</span>

//             </div>

//             <div className='resorte-container'>
//                 <div className='logo-res' />
//                 <div className='logo-res' />
//             </div>
//         </div>


//     )
// }


